import React, { useState, useEffect } from "react";
import {
  DeleteTwoTone,
  PlusCircleTwoTone,
  InboxOutlined,
} from "@ant-design/icons";
import { Card, Input, Modal, Button, message } from "antd";
import Dragger from "antd/lib/upload/Dragger";
import axios from "../../AxiosClient";
import { Download, DownloadFormProps } from "./types";

const DownloadForm: React.FC<DownloadFormProps> = (
  props: DownloadFormProps
) => {
  const { onChange } = props;
  const [rows, setRows] = useState<Download[]>([]);
  const [isShowCreateModal, setIsShowCreateModal] = useState(false);
  const [downloadName, setDownloadName] = useState("");
  const [downloadFile, setDownloadFile] = useState();
  const [isUploading, setIsUploading] = useState(false);

  const addButton = (
    <PlusCircleTwoTone
      style={{ fontSize: "1.5rem" }}
      onClick={() => setIsShowCreateModal(true)}
    />
  );

  const handleUpload = () => {
    setIsUploading(true);
    const formData = new FormData();
    formData.append("file", downloadFile);
    axios
      .post(`/download/upload/${downloadName}`, formData)
      .then((res) => res.data)
      .then((data) => {
        if (data.error) {
          message.error(data.error);
        } else {
          setRows((prev) => {
            return [...prev, data];
          });
        }
        setIsShowCreateModal(false);
      })
      .catch((error) => {
        message.error(`upload failed! ${error.message}`);
      })
      .finally(() => {
        setIsUploading(false);
      });
  };

  const handleDelete = (index) => {
    setRows((prev) => {
      const newValues = [...prev];
      newValues.splice(index, 1);
      return newValues;
    });
  };

  const beforeUpload = (file) => {
    setDownloadFile(file);
    return false;
  };

  useEffect(() => {
    onChange(rows);
  }, [rows]);

  const EditRow = (value: Download, index: number) => (
    <div
      key={index}
      style={{
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        padding: "0.5rem",
      }}
    >
      <div>{value.name}</div>
      <div>{value.file.name}</div>
      <DeleteTwoTone
        style={{
          fontSize: "1.5rem",
          verticalAlign: "middle",
          paddingLeft: "1rem",
        }}
        onClick={() => handleDelete(index)}
      />
    </div>
  );
  return (
    <div>
      <Card title="Downloads" extra={addButton} type="inner">
        {rows.map((row, index) => EditRow(row, index))}
      </Card>
      <Modal
        visible={isShowCreateModal}
        onCancel={() => setIsShowCreateModal(false)}
        footer={null}
      >
        <div>
          Display name:
          <Input
            type="text"
            size="middle"
            value={downloadName}
            onChange={(val) => setDownloadName(val.target.value)}
            placeholder="Please input a name of the download file!"
          />
          <Dragger
            beforeUpload={beforeUpload}
            multiple={false}
            showUploadList={false}
          >
            <p className="ant-upload-drag-icon">
              <InboxOutlined />
            </p>
            {downloadFile === undefined ? (
              <p className="ant-upload-text">Click or drag file to this area</p>
            ) : (
              <p>
                <span style={{ fontWeight: 700 }}>
                  Selected: {downloadFile.name}
                </span>
              </p>
            )}
          </Dragger>
          <Button
            type="primary"
            htmlType="submit"
            size="large"
            disabled={
              downloadName === "" ||
              downloadName.trim() === "" ||
              downloadFile === undefined
            }
            onClick={() => handleUpload()}
            loading={isUploading}
          >
            Upload
          </Button>
        </div>
      </Modal>
    </div>
  );
};

export default DownloadForm;

import React from 'react'
import { Layout, Row, Col } from 'antd'
import Sider from "./Sider"

interface Props {
  children?: React.ReactNode;
}

const Cover: React.FC<Props> = ({children}:Props) => {
  
  return (
    <>
      <Layout className="h-full flex-row">
        <Layout.Content className="flex flex-row">
          <Layout>
            <Row>
              <Sider/>
              <Col className="text-left pt-16 pb-8 flex-1">
                {children}
              </Col>
            </Row>
          </Layout>
        </Layout.Content>
      </Layout>
    </>
  )
}

export default Cover

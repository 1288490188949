import React from "react";
import { GetCourseClassLessonFieldsFragment } from "../../../graphql";
import { StudentList } from "../Student";

interface Props {
  lesson: GetCourseClassLessonFieldsFragment;
}

const LessonDetail = (props: Props) => {
  const { lesson } = props;
  // const [updateMLesson] = useMutation(UpdateLesson, {
  //   refetchQueries: [
  //     {
  //       query: GetALesson,
  //       variables: {
  //         id: lesson._id,
  //       },
  //     },
  //   ],
  // });

  // const onSubmit = (val: any) => {
  //   const { startDateTime: start, endDateTime: end, ...restData } = val;
  //   const startDateTime = moment(start).format();
  //   const endDateTime = moment(end).format();
  //   updateMLesson({
  //     variables: {
  //       input: {
  //         _id: lesson._id,
  //         startDateTime,
  //         endDateTime,
  //         ...restData,
  //       },
  //     },
  //   }).then(() => {
  //     message.success("update success");
  //   });
  // };

  return (
    <>
      {/* <Card>
        <LessonForm onSave={onSubmit} lesson={lesson} />
      </Card> */}
      <StudentList
        lessonId={lesson._id}
        students={lesson.studentsWithState}
        assignments={lesson.assignments}
        showTransfer
      />
    </>
  );
};

export default LessonDetail;

import React from "react";

import styles from "./EmptyDataBox.module.scss";

import noTestSubImg from "../../../assets/lesson_ongoing/testSub.svg";
import noMarkSubImg from "../../../assets/lesson_ongoing/markSub.svg";

interface EmptyDataBoxProps {
  boxTitle: string;
  boxContext: string[];
  boxPic: "tested" | "marked";
  children?: React.ReactNode;
}

const EmptyDataBox: React.FC<EmptyDataBoxProps> = (
  props: EmptyDataBoxProps
) => {
  const { boxContext, boxTitle, boxPic, children } = props;
  return (
    <div className={`${styles.boxContainer} w-full md: w-1/2`}>
      <div className={styles.boxTitleStyle}>{boxTitle}</div>
      <img
        src={boxPic === "tested" ? noTestSubImg : noMarkSubImg}
        alt=''
        className={styles.boxPicStyle}
      />
      <div style={{ marginTop: "20px" }}>
        {boxContext.map((text) => (
          <div key={text} className={styles.boxContextStyle}>
            {text}
          </div>
        ))}
        {children}
      </div>
    </div>
  );
};
export default EmptyDataBox;

import React, { useState, useEffect } from "react";
import { useQuery } from "@apollo/react-hooks";
import { Collapse, Layout } from "antd";
import Loading from "../components/common/Loading";
import Teacher from "../components/Teacher";
import {
  GetCourseOfTeacher,
  GetCourseOfTeacherQuery,
  GetCourseOfTeacherQueryVariables,
} from "../graphql";
import { MenuBar } from "../components/common/MenuBar";
import Announcement from "../components/announcement";

const TeacherPage: React.FC = () => {
  const [activeKey, setActiveKey] = useState<string | string[]>("");
  const { loading, data } = useQuery<
    GetCourseOfTeacherQuery,
    GetCourseOfTeacherQueryVariables
  >(GetCourseOfTeacher);
  useEffect(() => {
    if (data && data.coursesOfTeacher) {
      const all = data.coursesOfTeacher.flatMap((course) => {
        return course.classes;
      });
      if (all.length > 0) {
        setActiveKey(all[0]._id);
      }
    }
  }, [loading, data]);

  if (loading || !data) return <Loading />;

  return (
    <>
      <Layout>
        <Layout.Header className="app__header_padding">
          <MenuBar />
        </Layout.Header>
        <Layout.Content className="w-full mx-auto md:w-10/12">
          <Announcement />
          {data.coursesOfTeacher.map((course) => (
            <div key={course._id}>
              <h2>{course.name}</h2>
              {course.classes.map((mClass) => (
                <Collapse
                  accordion
                  key={mClass._id}
                  activeKey={activeKey}
                  onChange={(active) => setActiveKey(active)}
                >
                  <Collapse.Panel
                    header={`[${course.name}] ${mClass.code}`}
                    key={mClass._id}
                  >
                    <Teacher lessons={mClass.lessons} course={course} />
                  </Collapse.Panel>
                </Collapse>
              ))}
            </div>
          ))}
        </Layout.Content>
      </Layout>
    </>
  );
};

export default TeacherPage;

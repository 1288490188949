import React, { useState, useEffect, useRef } from "react";
import { Layout } from "antd";
import axios from "../../../AxiosClient";
import Loading from "../../common/Loading";
import Showcase from "./Showcase";

const getResources = async (): Promise<any> => {
  const response = await axios.get("/portal/home");
  const resources = response.data;
  return resources;
};

const renderResources = (resources) => {
  const { articles, videos, notes } = resources;
  return (
    <Layout>
      <Showcase items={articles} to="/portal/articles" header="Our top tips" />
      <Showcase items={videos} to="/portal/videos" header="Awesome Videos" />
      <Showcase items={notes} to="/portal/notes" header="Fun Freebies" />
    </Layout>
  );
};

const MainPage = () => {
  const [resources, setResources] = useState();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (loading) {
      getResources().then((data) => {
        setResources(data);
        setLoading(false);
        return data;
      });
    }
  }, [loading]);

  return <>{loading ? <Loading /> : renderResources(resources)}</>;
};

export default MainPage;

import React, { useState, useEffect } from "react";
import YouTube from "react-youtube";
import getVideoId from "get-video-id";
import { Typography } from "antd";
import VideoButton from "./VideoButton";
import QuestionTypeButton from "./QuestionTypeButton";
import { VideoRecord } from "./types";
import { Switch, Route, useHistory } from "react-router-dom";

interface Props {
  page?: VideoRecord;
}

const MultipleVideos = ({ page }: Props) => {
  const { title, videos } = page;
  const [currentVideo, setCurrentVideo] = useState(0);
  const [currentCategory, setCurrentCategory] = useState("MCQ");
  const history = useHistory();

  const handleSetVideo = (index) => {
    setCurrentVideo(index);
  };
  const handleSetCategory = (category) => {
    setCurrentCategory(category);
  };

  useEffect(() => {
    if (videos) {
      history.replace(
        `/portal/videos/${title.replace(/[{()}]/g, "")}/${
          videos.videos[0].title
        }`
      );
    }
  }, [videos]);

  return (
    <>
      <Switch>
        {videos.videos.map((video, index) => {
          return (
            <Route
              exact
              path={`/portal/videos/${title.replace(/[{()}]/g, "")}/${
                video.title
              }`}
              key={video.title}
            >
              <div className="h-40 sm:h-96 md:h-120 lg:h-144">
                <YouTube
                  videoId={getVideoId(video.url).id}
                  id={title}
                  className="my-4 w-full h-full"
                  containerClassName="h-full"
                />
              </div>
              <Typography.Title
                level={3}
                className="pt-4 semibold"
                style={{ marginBottom: 0 }}
              >
                {videos.title.replace(/(<([^>]+)>)/gi, "")}
              </Typography.Title>
              <Typography.Title level={4} className="py-0 text-base">
                QUESTION {index + 1}
              </Typography.Title>
              <p>{videos.description.replace(/(<([^>]+)>)/gi, "")}</p>
            </Route>
          );
        })}
      </Switch>
      <div className="py-4">
        <hr />
      </div>
      <QuestionTypeButton
        currentCategory={currentCategory}
        category="MCQ"
        onClick={() => {
          handleSetCategory("MCQ");
        }}
        title="Multiple Choice"
      />
      <QuestionTypeButton
        currentCategory={currentCategory}
        category="OEQ"
        onClick={() => {
          handleSetCategory("OEQ");
        }}
        title="Open Ended"
      />
      {currentCategory === "MCQ" && (
        <div>
          <VideoButton
            data={videos.videos.filter((video) => {
              return video.type === "MCQ";
            })}
            mainTitle={title}
            handleSetVideo={handleSetVideo}
          />
        </div>
      )}
      {currentCategory === "OEQ" && (
        <div>
          <VideoButton
            data={videos.videos.filter((video) => {
              return video.type === "OEQ";
            })}
            addToLength={
              videos.videos.filter((video) => {
                return video.type === "MCQ";
              }).length
            }
            mainTitle={title}
            handleSetVideo={handleSetVideo}
          />
        </div>
      )}
    </>
  );
};

export default MultipleVideos;

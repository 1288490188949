import React, { useState, useEffect } from "react";
import { Layout } from "antd";
import { useParams, Route, Switch, useHistory } from "react-router-dom";
import { useQuery } from "@apollo/react-hooks";
import moment from "moment";
import Loading from "../components/common/Loading";
import { StudentSider, StudentMenuHeader } from "../components/Student";
import StudentContent from "../components/Student/StudentContent";
import {
  GetLessonOfStudent,
  GetLessonOfStudentQuery,
  GetLessonOfStudentQueryVariables,
  StudentFieldFragment,
} from "../graphql";
import { LessonContext } from "./studentPageContext";

interface StudentPageProps {
  students: StudentFieldFragment[];
}

interface AcademicWeek {
  startDate: string;
  endDate: string;
  weekNumber: number;
}

interface ClassWithWeek {
  _id: string;
  code: string;
  lessons: any[];
  weeks: AcademicWeek[];
}

const StudentPage: React.FC<StudentPageProps> = (props: StudentPageProps) => {
  const { students } = props;
  const { studentId } = useParams();
  const { loading, data, refetch } = useQuery<
    GetLessonOfStudentQuery,
    GetLessonOfStudentQueryVariables
  >(GetLessonOfStudent, {
    variables: {
      studentId,
    },
  });
  const [collapsed, setCollapsed] = useState(false);
  const [classOfStudent, setClassOfStudent] = useState<ClassWithWeek[]>([]);
  const [selectedLesson, setSelectedLesson] = useState();
  const [isFirst, setIsFirst] = useState(true);
  const history = useHistory();

  useEffect(() => {
    if (window.innerWidth < 768) {
      setCollapsed(true);
    }
  }, []);

  const handleLessonChange = (lesson: any) => {
    history.push(`/parent/${studentId}/${lesson._id}`);
    setSelectedLesson(lesson);
  };

  useEffect(() => {
    if (data && data.lessonOfStudent) {
      if (data.lessonOfStudent.length > 0) {
        const classMap = new Map<string, any>();
        data.lessonOfStudent.forEach((lesson) => {
          classMap.set(lesson.class.code, lesson.class);
        });

        const classes: ClassWithWeek[] = [];
        classMap.forEach((c) => {
          classes.push({
            _id: c._id,
            code: c.code,
            weeks: c.course.academicWeeks,
            lessons: data.lessonOfStudent.filter(
              (l) => l.class.code === c.code
            ),
          });
        });
        setClassOfStudent(classes);

        if (classes.length > 0) {
          const c1 = classes[0];
          if (isFirst) {
            c1.lessons.some((l) => {
              if (
                moment(l.startDateTime).isBetween(
                  moment().day(0).hour(1),
                  moment().day(6).hour(23)
                )
              ) {
                handleLessonChange(l);
                return true;
              }
              return false;
            });
            setIsFirst(false);
          } else {
            setSelectedLesson((prev) => {
              if (prev !== undefined) {
                return c1.lessons.find((l) => l._id === prev._id);
              }
              return undefined;
            });
          }
        }
      }
    }
  }, [data]);

  if (loading) return <Loading />;

  return (
    <LessonContext.Provider value={{ refetch }}>
      <Layout className="h-full">
        <Layout.Sider
          style={{ backgroundColor: "#EFF1F8" }}
          trigger={null}
          width={240}
          theme="light"
          breakpoint="md"
          collapsedWidth={0}
          collapsed={collapsed}
        >
          <StudentSider
            classes={classOfStudent}
            onLessonChange={handleLessonChange}
          />
        </Layout.Sider>
        <Layout>
          <Layout.Header className="bg-white p-0 shadow-lg sticky top-0 z-40">
            <StudentMenuHeader
              weekNumber={
                selectedLesson !== undefined ? selectedLesson.weekNumber : 1
              }
              currentStudentId={studentId}
              students={students}
              onToggleCollapse={() => setCollapsed((prev) => !prev)}
            />
          </Layout.Header>

          <Layout.Content className="px-4 min-w-375">
            <Switch>
              <Route path="/parent/:studentId/:lessonId">
                <StudentContent lesson={selectedLesson} />
              </Route>
            </Switch>
          </Layout.Content>
        </Layout>
      </Layout>
    </LessonContext.Provider>
  );
};

export default StudentPage;

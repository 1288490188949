import React, { useState, useEffect } from "react";
import { Layout, Typography, Row, Col, Alert, Skeleton } from "antd";
import axios from "../../../../AxiosClient";
import { useAuth0 } from "../../../../Auth0Wrapper";
import Loading from "../../../common/Loading";
import ViewAccount from "./ViewAccount";
import EditAccount from "./EditAccount";
import boy from "../../../../assets/img/icons/boy.svg"
import { findAllByTestId } from "@testing-library/react";

interface ChildInfo {
  firstName: string;
  lastName: string;
  school: string;
}

const AccountSettings: React.FC = () => {
  const { dbUser, user } = useAuth0();
  const [editAccount, setEditAccount] = useState(false);
  const [loading, setLoading] = useState(true);
  const [childLevel, setChildLevel] = useState();
  const [finished, setFinished] = useState(false);
  const [failed, setFailed] = useState(false);
  const [avatarLoaded, setAvatarLoaded] = useState(false);
  const [hidden, setHidden] = useState(false);
  const [firstLoad,setFirstLoad] = useState(true)
  const [childInfo,setChildInfo] = useState<ChildInfo>();
  const getUserInfo = async () => {
    const response = await axios.get(`/user`);
    const info = await response.data;
    return info;
  };

  const loadUserInfo = () => {
    getUserInfo().then((data) => {
      const values = {
        ...data,
        childLevel: data.preferrenceCentre,
      };
      const {childfirstName,childlastName,childSchool} = values;
      setChildInfo({firstName:childfirstName,lastName:childlastName,school:childSchool});
      setChildLevel(data.preferrenceCentre);
      setEditAccount(false);
      setLoading(false);
    });
  };

  useEffect(() => {
    if(firstLoad) {
      setFirstLoad(false);
      loadUserInfo();
    }
  });

  const onFinish = async (data) => {
    setLoading(true);
    const status = await axios.post("/contact", {
      email: user.email,
      sub:dbUser.sub,
      ...data,
    });
    setChildLevel(data.childLevel);
    setEditAccount(false);
    setLoading(false);
  };

  const handleEditAccount = () => {
    setEditAccount(true);
  };



  return (
    <Layout>
      <Layout.Content className="mt-8 md:m-10 lg:m-40 lg:my-20 ">
        <Layout>
          <Row>
            <Col xs={24} sm={12} className="w-full">
              <div className="w-full text-center sm:text-right px-4">
                <Skeleton
                  avatar
                  paragraph={false}
                  title={false}
                  loading={!avatarLoaded}
                  className={`settings-avatar-skeleton ${
                    !hidden ? "hidden" : ""
                  }`}
                  active
                />
                <img
                  src={dbUser.avatarUrl}
                  className={`h-32 w-32 rounded-full border-4 bg-white shadow-xs inline-block ${
                    hidden ? "hidden" : ""
                  }`}
                  alt="user avatar"
                  onLoad={() => {
                    setAvatarLoaded(true);
                  }}
                  onError={() => {
                    setHidden(true);
                  }}
                />
              </div>
            </Col>
            <Col xs={24} sm={12} className="w-full">
              <div className="w-full align-middle text-center sm:text-left py-12 sm:w-1/2 md:py-4 md:w-full">
                <Typography.Title level={3} className="break-words">
                  {dbUser.name}
                </Typography.Title>
                <div>
                  { childInfo ? (<>
                  <div className="text-base" style={{color:"rgba(33, 33, 33, 0.7)"}}>
                    <img src={boy} className="inline"/> {childInfo.firstName} {childInfo.lastName}
                  </div>
                    </>
                  ): <></> }
                </div>
              </div>
            </Col>
          </Row>
        </Layout>
        <Layout className="px-4 w-full sm:w-4/6 sm:mx-auto sm:mt-16 md:w-5/6">
          <hr />
          {(!editAccount && !loading) && (
            <ViewAccount
              email={dbUser.email}
              childLevel={childLevel}
              handleEdit={handleEditAccount}
            />
          )}
          {(editAccount) && (
            <EditAccount
              email={dbUser.email}
              childLevel={childLevel}
              handleEdit={onFinish}
              handleExit={() => {
                setEditAccount(false)
              }}
              loading={loading}
            />
          )}
        </Layout>
      </Layout.Content>
    </Layout>
  );
};

export default AccountSettings;

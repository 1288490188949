import React from 'react'
import { NavLink, useLocation } from 'react-router-dom'
import { Menu } from 'antd'
import { Level } from './types'

interface Props {
  levels?: Level[]
}

const LevelLinks = ({ levels }: Props) => {
  let location = useLocation()
  if (levels) {
    return (
      <Menu mode="horizontal" style={{ borderBottom: 'none' }} className="pt-4">
        {levels.map((level) => {
          const to = `/portal/programmes/${level.name}`
          let active = false
          if (location.pathname.includes(to)) {
            active = true
          }
          return (
            <Menu.Item
              key={level.name}
              className={`menu-underlined`}
            >
              <NavLink
                className="pr-3 semibold"
                activeStyle={{ color: '#F36B7F' }}
                to={`/portal/programmes/${level.name}`}
                style={{ fontSize: '2em' }}
              >
                {level.name}
              </NavLink>
            </Menu.Item>
          )
        })}
      </Menu>
    )
  }
  return <></>
}

export default LevelLinks

import React from 'react'
import { Layout, Button } from 'antd'
import SignUpForm from './SignUpForm'
import { useAuth0 } from '../../../../Auth0Wrapper'
import Cover from "./Cover"

interface Props {
  authorized?:boolean;
}

const CompleteProfile: React.FC<Props> = ({authorized}:Props) => {
  const { auth0Client } = useAuth0()
  return (<Cover>
    <p className="text-right pr-16">{!authorized && <>Already a member? <Button type="link" onClick={()=>{auth0Client.loginWithRedirect()}}>Sign In</Button></>}</p>
    <Layout>
      <SignUpForm authorized={authorized}/>
    </Layout>
  </Cover>)
}

export default CompleteProfile

import gql from 'graphql-tag';
export type Maybe<T> = T | null;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  /** Mongo object id scalar type */
  ObjectId: any;
  /** The javascript `Date` as string. Type represents date and time as the ISO Date string. */
  DateTime: any;
  /** The `Upload` scalar type represents a file upload. */
  Upload: any;
};

export type AcademicWeek = {
   __typename?: 'AcademicWeek';
  startDate: Scalars['DateTime'];
  endDate: Scalars['DateTime'];
  weekNumber: Scalars['Float'];
};

export type AddAssignmentInput = {
  lesson: Scalars['ObjectId'];
  name: Scalars['String'];
};

export type Announcement = {
   __typename?: 'Announcement';
  _id: Scalars['ObjectId'];
  publishDate: Scalars['DateTime'];
  content: Scalars['String'];
};

export type Assignment = {
   __typename?: 'Assignment';
  _id: Scalars['ObjectId'];
  name: Scalars['String'];
  submits: Array<Submit>;
};


export type AssignmentSubmitsArgs = {
  studentId: Maybe<Scalars['ObjectId']>;
};

export type Class = {
   __typename?: 'Class';
  _id: Scalars['ObjectId'];
  course: Course;
  code: Scalars['String'];
  dayOfWeek: Scalars['Float'];
  /** format: 'HH:mm' */
  startTime: Scalars['String'];
  /** format: 'HH:mm' */
  endTime: Scalars['String'];
  teacher: User;
  students: Maybe<Array<Student>>;
  lessons: Array<Lesson>;
};

export type Course = {
   __typename?: 'Course';
  _id: Scalars['ObjectId'];
  name: Scalars['String'];
  level: Scalars['String'];
  startDate: Scalars['DateTime'];
  endDate: Scalars['DateTime'];
  classes: Maybe<Array<Class>>;
  academicWeeks: Array<AcademicWeek>;
  currentAcademicWeek: Scalars['Float'];
};

export type CreateAssignmentInput = {
  name: Scalars['String'];
};

export type CreateClassInput = {
  course: Scalars['ObjectId'];
  code: Scalars['String'];
  dayOfWeek: Scalars['Float'];
  /** format: 'HH:mm' */
  startTime: Scalars['String'];
  /** format: 'HH:mm' */
  endTime: Scalars['String'];
  zoomUrl: Scalars['String'];
  zoomId: Scalars['String'];
  zoomPassword: Scalars['String'];
  teacher: Scalars['ObjectId'];
};

export type CreateCourseInput = {
  name: Scalars['String'];
  level: Scalars['String'];
  startDate: Scalars['DateTime'];
  endDate: Scalars['DateTime'];
};

export type CreateDownloadInput = {
  name: Scalars['String'];
  upload: Scalars['Upload'];
};

export type CreateStudentInput = {
  name: Scalars['String'];
  parent: Scalars['ObjectId'];
};

export type CreateUserInput = {
  name: Scalars['String'];
  email: Scalars['String'];
};


export type Download = {
   __typename?: 'Download';
  _id: Scalars['ObjectId'];
  name: Scalars['String'];
  file: DropboxFile;
};

export type DropboxFile = {
   __typename?: 'DropboxFile';
  name: Scalars['String'];
  fileId: Scalars['String'];
};

export type Lesson = {
   __typename?: 'Lesson';
  _id: Scalars['ObjectId'];
  class: Class;
  teacher: User;
  description: Maybe<Scalars['String']>;
  startDateTime: Scalars['DateTime'];
  endDateTime: Scalars['DateTime'];
  zoomUrl: Maybe<Scalars['String']>;
  zoomId: Maybe<Scalars['String']>;
  zoomPassword: Maybe<Scalars['String']>;
  assignments: Maybe<Array<Assignment>>;
  downloads: Maybe<Array<Download>>;
  studentsWithState: Maybe<Array<StudentWithState>>;
  weekNumber: Scalars['Float'];
};

export type Mutation = {
   __typename?: 'Mutation';
  updateUser: User;
  createClass: Class;
  updateClass: Class;
  addStudentToClass: Class;
  setTeacherOfClass: Class;
  deleteClass: Scalars['Boolean'];
  addAssignmentToLesson: Assignment;
  deleteAssignment: Scalars['Boolean'];
  updateLesson: Lesson;
  deleteLesson: Scalars['Boolean'];
  transferStudent: Scalars['Boolean'];
  withdrawStudent: Scalars['Boolean'];
  createCourse: Course;
  deleteCourse: Scalars['Boolean'];
  deleteDownload: Scalars['Boolean'];
  uploadDownload: Maybe<Download>;
  addStudent: Student;
  setAnnouncement: Announcement;
};


export type MutationUpdateUserArgs = {
  input: UpdateUserInput;
};


export type MutationCreateClassArgs = {
  input: CreateClassInput;
};


export type MutationUpdateClassArgs = {
  input: UpdateClassInput;
};


export type MutationAddStudentToClassArgs = {
  classId: Scalars['ObjectId'];
  studentId: Scalars['ObjectId'];
};


export type MutationSetTeacherOfClassArgs = {
  classId: Scalars['ObjectId'];
  teacherId: Scalars['ObjectId'];
};


export type MutationDeleteClassArgs = {
  id: Scalars['ObjectId'];
};


export type MutationAddAssignmentToLessonArgs = {
  input: AddAssignmentInput;
};


export type MutationDeleteAssignmentArgs = {
  id: Scalars['ObjectId'];
};


export type MutationUpdateLessonArgs = {
  input: UpdateLessonInput;
};


export type MutationDeleteLessonArgs = {
  id: Scalars['ObjectId'];
};


export type MutationTransferStudentArgs = {
  toLessonId: Scalars['ObjectId'];
  fromLessonId: Scalars['ObjectId'];
  studentId: Scalars['ObjectId'];
};


export type MutationWithdrawStudentArgs = {
  fromLessonId: Scalars['ObjectId'];
  studentId: Scalars['ObjectId'];
};


export type MutationCreateCourseArgs = {
  input: CreateCourseInput;
};


export type MutationDeleteCourseArgs = {
  id: Scalars['ObjectId'];
};


export type MutationDeleteDownloadArgs = {
  id: Scalars['ObjectId'];
};


export type MutationUploadDownloadArgs = {
  input: CreateDownloadInput;
};


export type MutationAddStudentArgs = {
  input: CreateStudentInput;
};


export type MutationSetAnnouncementArgs = {
  content: Scalars['String'];
};


export type Query = {
   __typename?: 'Query';
  teachers: Array<User>;
  parents: Array<User>;
  children: Array<Student>;
  students: Array<Student>;
  class: Class;
  classes: Array<Class>;
  assignment: Assignment;
  lessons: Array<Lesson>;
  lessonOfStudent: Array<Lesson>;
  lesson: Lesson;
  submit: Submit;
  courses: Array<Course>;
  coursesOfStudent: Array<Course>;
  coursesOfTeacher: Array<Course>;
  course: Course;
  annnouncement: Maybe<Announcement>;
};


export type QueryClassArgs = {
  id: Scalars['ObjectId'];
};


export type QueryClassesArgs = {
  courseId: Maybe<Scalars['ObjectId']>;
};


export type QueryAssignmentArgs = {
  id: Scalars['ObjectId'];
};


export type QueryLessonsArgs = {
  classId: Maybe<Scalars['ObjectId']>;
};


export type QueryLessonOfStudentArgs = {
  studentId: Scalars['ObjectId'];
};


export type QueryLessonArgs = {
  id: Scalars['ObjectId'];
};


export type QuerySubmitArgs = {
  id: Scalars['ObjectId'];
};


export type QueryCoursesOfStudentArgs = {
  studentId: Scalars['ObjectId'];
};


export type QueryCoursesOfTeacherArgs = {
  teacherId: Maybe<Scalars['ObjectId']>;
};


export type QueryCourseArgs = {
  id: Scalars['ObjectId'];
};

export type Student = {
   __typename?: 'Student';
  _id: Scalars['ObjectId'];
  studentId: Scalars['String'];
  name: Scalars['String'];
  gender: Scalars['String'];
  parents: Array<User>;
};

export type StudentWithState = {
   __typename?: 'StudentWithState';
  student: Student;
  state: Scalars['String'];
  fromLesson: Maybe<Lesson>;
};

export type Submit = {
   __typename?: 'Submit';
  _id: Scalars['ObjectId'];
  submitFiles: Maybe<Array<DropboxFile>>;
  submitBy: Student;
  markedAnnotations: Maybe<Scalars['String']>;
  markedBy: Maybe<User>;
  markedFiles: Maybe<Array<DropboxFile>>;
};

export type UpdateClassInput = {
  _id: Scalars['ObjectId'];
  code: Maybe<Scalars['String']>;
  teacher: Maybe<Scalars['ObjectId']>;
  students: Maybe<Array<Scalars['ObjectId']>>;
};

export type UpdateLessonInput = {
  _id: Scalars['ObjectId'];
  startDateTime: Maybe<Scalars['DateTime']>;
  endDateTime: Maybe<Scalars['DateTime']>;
  description: Maybe<Scalars['String']>;
  zoomUrl: Maybe<Scalars['String']>;
  zoomId: Maybe<Scalars['String']>;
  zoomPassword: Maybe<Scalars['String']>;
};

export type UpdateUserInput = {
  _id: Scalars['ObjectId'];
  name: Maybe<Scalars['String']>;
};


export type User = {
   __typename?: 'User';
  _id: Scalars['ObjectId'];
  name: Scalars['String'];
  sub: Scalars['String'];
  email: Scalars['String'];
  avatarUrl: Scalars['String'];
  role: Scalars['String'];
};

export type GetAnnoucementQueryVariables = {};


export type GetAnnoucementQuery = (
  { __typename?: 'Query' }
  & { annnouncement: Maybe<(
    { __typename?: 'Announcement' }
    & Pick<Announcement, 'content' | 'publishDate'>
  )> }
);

export type SetAnnouncementMutationVariables = {
  content: Scalars['String'];
};


export type SetAnnouncementMutation = (
  { __typename?: 'Mutation' }
  & { setAnnouncement: (
    { __typename?: 'Announcement' }
    & Pick<Announcement, 'content' | 'publishDate'>
  ) }
);

export type ClassFieldsFragment = (
  { __typename?: 'Class' }
  & Pick<Class, '_id' | 'code' | 'dayOfWeek' | 'startTime' | 'endTime'>
  & { teacher: (
    { __typename?: 'User' }
    & Pick<User, '_id' | 'name' | 'avatarUrl' | 'email' | 'role'>
  ), students: Maybe<Array<(
    { __typename?: 'Student' }
    & StudentFieldFragment
  )>>, lessons: Array<(
    { __typename?: 'Lesson' }
    & Pick<Lesson, '_id' | 'description' | 'startDateTime' | 'endDateTime' | 'zoomUrl' | 'zoomId' | 'zoomPassword' | 'weekNumber'>
    & { teacher: (
      { __typename?: 'User' }
      & Pick<User, '_id' | 'name' | 'avatarUrl' | 'email' | 'role'>
    ), assignments: Maybe<Array<(
      { __typename?: 'Assignment' }
      & Pick<Assignment, '_id' | 'name'>
      & { submits: Array<(
        { __typename?: 'Submit' }
        & Pick<Submit, '_id' | 'markedAnnotations'>
        & { submitFiles: Maybe<Array<(
          { __typename?: 'DropboxFile' }
          & Pick<DropboxFile, 'name' | 'fileId'>
        )>>, submitBy: (
          { __typename?: 'Student' }
          & StudentFieldFragment
        ), markedFiles: Maybe<Array<(
          { __typename?: 'DropboxFile' }
          & Pick<DropboxFile, 'name' | 'fileId'>
        )>>, markedBy: Maybe<(
          { __typename?: 'User' }
          & Pick<User, '_id' | 'name' | 'avatarUrl' | 'email' | 'role'>
        )> }
      )> }
    )>>, downloads: Maybe<Array<(
      { __typename?: 'Download' }
      & Pick<Download, '_id' | 'name'>
      & { file: (
        { __typename?: 'DropboxFile' }
        & Pick<DropboxFile, 'name' | 'fileId'>
      ) }
    )>>, studentsWithState: Maybe<Array<(
      { __typename?: 'StudentWithState' }
      & Pick<StudentWithState, 'state'>
      & { student: (
        { __typename?: 'Student' }
        & StudentFieldFragment
      ) }
    )>> }
  )>, course: (
    { __typename?: 'Course' }
    & Pick<Course, '_id' | 'name' | 'level' | 'startDate' | 'endDate'>
  ) }
);

export type QueryClassesQueryVariables = {};


export type QueryClassesQuery = (
  { __typename?: 'Query' }
  & { classes: Array<(
    { __typename?: 'Class' }
    & ClassFieldsFragment
  )> }
);

export type GetAClassQueryVariables = {
  id: Scalars['ObjectId'];
};


export type GetAClassQuery = (
  { __typename?: 'Query' }
  & { class: (
    { __typename?: 'Class' }
    & ClassFieldsFragment
  ) }
);

export type SetAteacherToClassMutationVariables = {
  classId: Scalars['ObjectId'];
  teacherId: Scalars['ObjectId'];
};


export type SetAteacherToClassMutation = (
  { __typename?: 'Mutation' }
  & { setTeacherOfClass: (
    { __typename?: 'Class' }
    & Pick<Class, '_id' | 'code'>
  ) }
);

export type SetStudentToClassMutationVariables = {
  classId: Scalars['ObjectId'];
  studentId: Scalars['ObjectId'];
};


export type SetStudentToClassMutation = (
  { __typename?: 'Mutation' }
  & { addStudentToClass: (
    { __typename?: 'Class' }
    & Pick<Class, '_id' | 'code'>
  ) }
);

export type AddClassMutationVariables = {
  input: CreateClassInput;
};


export type AddClassMutation = (
  { __typename?: 'Mutation' }
  & { createClass: (
    { __typename?: 'Class' }
    & ClassFieldsFragment
  ) }
);

export type GetClassQueryVariables = {
  id: Scalars['ObjectId'];
};


export type GetClassQuery = (
  { __typename?: 'Query' }
  & { class: (
    { __typename?: 'Class' }
    & Pick<Class, '_id'>
    & { lessons: Array<(
      { __typename?: 'Lesson' }
      & Pick<Lesson, '_id' | 'weekNumber'>
      & { assignments: Maybe<Array<(
        { __typename?: 'Assignment' }
        & Pick<Assignment, '_id'>
      )>> }
    )> }
  ) }
);

export type AssignmentFieldFragment = (
  { __typename?: 'Assignment' }
  & Pick<Assignment, '_id' | 'name'>
  & { submits: Array<(
    { __typename?: 'Submit' }
    & Pick<Submit, '_id'>
    & { submitFiles: Maybe<Array<(
      { __typename?: 'DropboxFile' }
      & Pick<DropboxFile, 'fileId' | 'name'>
    )>>, markedFiles: Maybe<Array<(
      { __typename?: 'DropboxFile' }
      & Pick<DropboxFile, 'fileId' | 'name'>
    )>>, markedBy: Maybe<(
      { __typename?: 'User' }
      & Pick<User, '_id' | 'name' | 'email' | 'avatarUrl'>
    )>, submitBy: (
      { __typename?: 'Student' }
      & Pick<Student, '_id' | 'name' | 'gender'>
    ) }
  )> }
);

export type CourseFielsFragment = (
  { __typename?: 'Course' }
  & Pick<Course, '_id' | 'name' | 'level' | 'startDate' | 'endDate'>
  & { classes: Maybe<Array<(
    { __typename?: 'Class' }
    & ClassFieldsFragment
  )>> }
);

export type GetCoursesFieldsFragment = (
  { __typename?: 'Course' }
  & Pick<Course, '_id' | 'level' | 'name' | 'startDate' | 'endDate' | 'currentAcademicWeek'>
  & { classes: Maybe<Array<(
    { __typename?: 'Class' }
    & Pick<Class, '_id' | 'code'>
  )>>, academicWeeks: Array<(
    { __typename?: 'AcademicWeek' }
    & Pick<AcademicWeek, 'weekNumber' | 'startDate' | 'endDate'>
  )> }
);

export type StudentWithStateNoFromLessonFragment = (
  { __typename?: 'StudentWithState' }
  & Pick<StudentWithState, 'state'>
  & { student: (
    { __typename?: 'Student' }
    & Pick<Student, '_id' | 'gender' | 'name'>
  ) }
);

export type GetCourseClassLessonFieldsFragment = (
  { __typename?: 'Lesson' }
  & Pick<Lesson, '_id' | 'startDateTime' | 'endDateTime' | 'zoomId' | 'zoomPassword' | 'zoomUrl' | 'weekNumber' | 'description'>
  & { assignments: Maybe<Array<(
    { __typename?: 'Assignment' }
    & AssignmentFieldFragment
  )>>, studentsWithState: Maybe<Array<(
    { __typename?: 'StudentWithState' }
    & StudentWithStateNoFromLessonFragment
  )>> }
);

export type GetCourseClassFieldsFragment = (
  { __typename?: 'Class' }
  & Pick<Class, '_id' | 'code' | 'dayOfWeek'>
  & { students: Maybe<Array<(
    { __typename?: 'Student' }
    & Pick<Student, '_id' | 'gender' | 'name' | 'studentId'>
  )>>, teacher: (
    { __typename?: 'User' }
    & Pick<User, '_id' | 'avatarUrl' | 'name' | 'email'>
  ), lessons: Array<(
    { __typename?: 'Lesson' }
    & GetCourseClassLessonFieldsFragment
  )> }
);

export type GetCourseFieldsFragment = (
  { __typename?: 'Course' }
  & Pick<Course, '_id' | 'currentAcademicWeek' | 'name' | 'level' | 'startDate' | 'endDate'>
  & { academicWeeks: Array<(
    { __typename?: 'AcademicWeek' }
    & Pick<AcademicWeek, 'startDate' | 'endDate' | 'weekNumber'>
  )>, classes: Maybe<Array<(
    { __typename?: 'Class' }
    & GetCourseClassFieldsFragment
  )>> }
);

export type GetCourseQueryVariables = {
  id: Scalars['ObjectId'];
};


export type GetCourseQuery = (
  { __typename?: 'Query' }
  & { course: (
    { __typename?: 'Course' }
    & GetCourseFieldsFragment
  ) }
);

export type GetCourseOfStudentQueryVariables = {
  studentId: Scalars['ObjectId'];
};


export type GetCourseOfStudentQuery = (
  { __typename?: 'Query' }
  & { coursesOfStudent: Array<(
    { __typename?: 'Course' }
    & { classes: Maybe<Array<(
      { __typename?: 'Class' }
      & Pick<Class, '_id' | 'code'>
      & { lessons: Array<(
        { __typename?: 'Lesson' }
        & Pick<Lesson, '_id' | 'weekNumber' | 'startDateTime' | 'endDateTime'>
      )> }
    )>> }
  )> }
);

export type GetCoursesQueryVariables = {};


export type GetCoursesQuery = (
  { __typename?: 'Query' }
  & { courses: Array<(
    { __typename?: 'Course' }
    & GetCoursesFieldsFragment
  )> }
);

export type GetACourseQueryVariables = {
  id: Scalars['ObjectId'];
};


export type GetACourseQuery = (
  { __typename?: 'Query' }
  & { course: (
    { __typename?: 'Course' }
    & CourseFielsFragment
  ) }
);

export type AddCourseMutationVariables = {
  input: CreateCourseInput;
};


export type AddCourseMutation = (
  { __typename?: 'Mutation' }
  & { createCourse: (
    { __typename?: 'Course' }
    & Pick<Course, '_id' | 'name'>
  ) }
);

export type GetCourseOfTeacherLessonFieldsFragment = (
  { __typename?: 'Lesson' }
  & Pick<Lesson, '_id' | 'weekNumber' | 'endDateTime' | 'startDateTime'>
  & { assignments: Maybe<Array<(
    { __typename?: 'Assignment' }
    & Pick<Assignment, '_id' | 'name'>
    & { submits: Array<(
      { __typename?: 'Submit' }
      & Pick<Submit, '_id'>
      & { markedBy: Maybe<(
        { __typename?: 'User' }
        & Pick<User, '_id' | 'avatarUrl' | 'email' | 'name'>
      )>, markedFiles: Maybe<Array<(
        { __typename?: 'DropboxFile' }
        & Pick<DropboxFile, 'fileId' | 'name'>
      )>>, submitBy: (
        { __typename?: 'Student' }
        & Pick<Student, '_id' | 'gender' | 'name'>
      ), submitFiles: Maybe<Array<(
        { __typename?: 'DropboxFile' }
        & Pick<DropboxFile, 'fileId' | 'name'>
      )>> }
    )> }
  )>>, studentsWithState: Maybe<Array<(
    { __typename?: 'StudentWithState' }
    & Pick<StudentWithState, 'state'>
    & { student: (
      { __typename?: 'Student' }
      & Pick<Student, '_id' | 'name' | 'gender'>
    ) }
  )>> }
);

export type GetCourseOfTeacherClassFieldsFragment = (
  { __typename?: 'Class' }
  & Pick<Class, '_id' | 'code' | 'startTime' | 'endTime' | 'dayOfWeek'>
  & { teacher: (
    { __typename?: 'User' }
    & Pick<User, '_id' | 'avatarUrl' | 'email' | 'name'>
  ), students: Maybe<Array<(
    { __typename?: 'Student' }
    & Pick<Student, '_id' | 'gender' | 'name'>
  )>>, lessons: Array<(
    { __typename?: 'Lesson' }
    & GetCourseOfTeacherLessonFieldsFragment
  )> }
);

export type GetCourseOfTeacherQueryVariables = {};


export type GetCourseOfTeacherQuery = (
  { __typename?: 'Query' }
  & { coursesOfTeacher: Array<(
    { __typename?: 'Course' }
    & Pick<Course, '_id' | 'currentAcademicWeek' | 'level' | 'name' | 'startDate' | 'endDate'>
    & { classes: Maybe<Array<(
      { __typename?: 'Class' }
      & GetCourseOfTeacherClassFieldsFragment
    )>> }
  )> }
);

export type LessonFieldsFragment = (
  { __typename?: 'Lesson' }
  & Pick<Lesson, '_id' | 'description' | 'startDateTime' | 'endDateTime' | 'zoomUrl' | 'zoomId' | 'zoomPassword'>
  & { class: (
    { __typename?: 'Class' }
    & Pick<Class, '_id' | 'code' | 'dayOfWeek' | 'startTime' | 'endTime'>
  ), teacher: (
    { __typename?: 'User' }
    & Pick<User, '_id' | 'name' | 'avatarUrl' | 'email' | 'role'>
  ), assignments: Maybe<Array<(
    { __typename?: 'Assignment' }
    & Pick<Assignment, '_id' | 'name'>
    & { submits: Array<(
      { __typename?: 'Submit' }
      & Pick<Submit, '_id' | 'markedAnnotations'>
      & { submitFiles: Maybe<Array<(
        { __typename?: 'DropboxFile' }
        & Pick<DropboxFile, 'name' | 'fileId'>
      )>>, submitBy: (
        { __typename?: 'Student' }
        & StudentFieldFragment
      ), markedFiles: Maybe<Array<(
        { __typename?: 'DropboxFile' }
        & Pick<DropboxFile, 'name' | 'fileId'>
      )>>, markedBy: Maybe<(
        { __typename?: 'User' }
        & Pick<User, '_id' | 'name' | 'avatarUrl' | 'email' | 'role'>
      )> }
    )> }
  )>>, downloads: Maybe<Array<(
    { __typename?: 'Download' }
    & Pick<Download, '_id' | 'name'>
    & { file: (
      { __typename?: 'DropboxFile' }
      & Pick<DropboxFile, 'name' | 'fileId'>
    ) }
  )>>, studentsWithState: Maybe<Array<(
    { __typename?: 'StudentWithState' }
    & Pick<StudentWithState, 'state'>
    & { student: (
      { __typename?: 'Student' }
      & StudentFieldFragment
    ) }
  )>> }
);

export type GetLessonsQueryVariables = {};


export type GetLessonsQuery = (
  { __typename?: 'Query' }
  & { lessons: Array<(
    { __typename?: 'Lesson' }
    & LessonFieldsFragment
  )> }
);

export type GetALessonQueryVariables = {
  id: Scalars['ObjectId'];
  studentId: Maybe<Scalars['ObjectId']>;
};


export type GetALessonQuery = (
  { __typename?: 'Query' }
  & { lesson: (
    { __typename?: 'Lesson' }
    & Pick<Lesson, '_id' | 'description' | 'startDateTime' | 'endDateTime' | 'zoomUrl' | 'zoomId' | 'zoomPassword'>
    & { class: (
      { __typename?: 'Class' }
      & Pick<Class, '_id' | 'code' | 'dayOfWeek' | 'startTime' | 'endTime'>
      & { course: (
        { __typename?: 'Course' }
        & Pick<Course, '_id' | 'name' | 'level' | 'startDate' | 'endDate'>
      ) }
    ), teacher: (
      { __typename?: 'User' }
      & Pick<User, '_id' | 'name' | 'avatarUrl' | 'email' | 'role'>
    ), assignments: Maybe<Array<(
      { __typename?: 'Assignment' }
      & Pick<Assignment, '_id' | 'name'>
      & { submits: Array<(
        { __typename?: 'Submit' }
        & Pick<Submit, '_id' | 'markedAnnotations'>
        & { submitFiles: Maybe<Array<(
          { __typename?: 'DropboxFile' }
          & Pick<DropboxFile, 'name' | 'fileId'>
        )>>, submitBy: (
          { __typename?: 'Student' }
          & StudentFieldFragment
        ), markedFiles: Maybe<Array<(
          { __typename?: 'DropboxFile' }
          & Pick<DropboxFile, 'name' | 'fileId'>
        )>>, markedBy: Maybe<(
          { __typename?: 'User' }
          & Pick<User, '_id' | 'name' | 'avatarUrl' | 'email' | 'role'>
        )> }
      )> }
    )>>, downloads: Maybe<Array<(
      { __typename?: 'Download' }
      & Pick<Download, '_id' | 'name'>
      & { file: (
        { __typename?: 'DropboxFile' }
        & Pick<DropboxFile, 'name' | 'fileId'>
      ) }
    )>>, studentsWithState: Maybe<Array<(
      { __typename?: 'StudentWithState' }
      & Pick<StudentWithState, 'state'>
      & { student: (
        { __typename?: 'Student' }
        & StudentFieldFragment
      ) }
    )>> }
  ) }
);

export type GetLessonOfStudentQueryVariables = {
  studentId: Scalars['ObjectId'];
};


export type GetLessonOfStudentQuery = (
  { __typename?: 'Query' }
  & { lessonOfStudent: Array<(
    { __typename?: 'Lesson' }
    & Pick<Lesson, '_id' | 'description' | 'startDateTime' | 'endDateTime' | 'weekNumber' | 'zoomUrl' | 'zoomId' | 'zoomPassword'>
    & { class: (
      { __typename?: 'Class' }
      & Pick<Class, '_id' | 'code' | 'dayOfWeek' | 'startTime' | 'endTime'>
      & { course: (
        { __typename?: 'Course' }
        & Pick<Course, '_id' | 'name' | 'level' | 'startDate' | 'endDate'>
        & { academicWeeks: Array<(
          { __typename?: 'AcademicWeek' }
          & Pick<AcademicWeek, 'startDate' | 'endDate' | 'weekNumber'>
        )> }
      ) }
    ), teacher: (
      { __typename?: 'User' }
      & Pick<User, '_id' | 'name' | 'avatarUrl' | 'email' | 'role'>
    ), assignments: Maybe<Array<(
      { __typename?: 'Assignment' }
      & Pick<Assignment, '_id' | 'name'>
      & { submits: Array<(
        { __typename?: 'Submit' }
        & Pick<Submit, '_id' | 'markedAnnotations'>
        & { submitFiles: Maybe<Array<(
          { __typename?: 'DropboxFile' }
          & Pick<DropboxFile, 'name' | 'fileId'>
        )>>, submitBy: (
          { __typename?: 'Student' }
          & StudentFieldFragment
        ), markedFiles: Maybe<Array<(
          { __typename?: 'DropboxFile' }
          & Pick<DropboxFile, 'name' | 'fileId'>
        )>>, markedBy: Maybe<(
          { __typename?: 'User' }
          & Pick<User, '_id' | 'name' | 'avatarUrl' | 'email' | 'role'>
        )> }
      )> }
    )>>, downloads: Maybe<Array<(
      { __typename?: 'Download' }
      & Pick<Download, '_id' | 'name'>
      & { file: (
        { __typename?: 'DropboxFile' }
        & Pick<DropboxFile, 'name' | 'fileId'>
      ) }
    )>> }
  )> }
);

export type UpdateLessonMutationVariables = {
  input: UpdateLessonInput;
};


export type UpdateLessonMutation = (
  { __typename?: 'Mutation' }
  & { updateLesson: (
    { __typename?: 'Lesson' }
    & LessonFieldsFragment
  ) }
);

export type SetAssignmentMutationVariables = {
  input: AddAssignmentInput;
};


export type SetAssignmentMutation = (
  { __typename?: 'Mutation' }
  & { addAssignmentToLesson: (
    { __typename?: 'Assignment' }
    & Pick<Assignment, '_id' | 'name'>
  ) }
);

export type StudentFieldFragment = (
  { __typename?: 'Student' }
  & Pick<Student, '_id' | 'name' | 'studentId' | 'gender'>
);

export type GetChildrenQueryVariables = {};


export type GetChildrenQuery = (
  { __typename?: 'Query' }
  & { children: Array<(
    { __typename?: 'Student' }
    & StudentFieldFragment
  )> }
);

export type GetParentsQueryVariables = {};


export type GetParentsQuery = (
  { __typename?: 'Query' }
  & { parents: Array<(
    { __typename?: 'User' }
    & Pick<User, '_id' | 'avatarUrl' | 'email' | 'name' | 'role'>
  )> }
);

export type TransferMutationVariables = {
  toLessonId: Scalars['ObjectId'];
  fromLessonId: Scalars['ObjectId'];
  studentId: Scalars['ObjectId'];
};


export type TransferMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'transferStudent'>
);

export type WithdrawMutationVariables = {
  fromLessonId: Scalars['ObjectId'];
  studentId: Scalars['ObjectId'];
};


export type WithdrawMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'withdrawStudent'>
);

export type AddChildMutationVariables = {
  input: CreateStudentInput;
};


export type AddChildMutation = (
  { __typename?: 'Mutation' }
  & { addStudent: (
    { __typename?: 'Student' }
    & Pick<Student, '_id' | 'name'>
  ) }
);

export type GetTeachersQueryVariables = {};


export type GetTeachersQuery = (
  { __typename?: 'Query' }
  & { teachers: Array<(
    { __typename?: 'User' }
    & Pick<User, '_id' | 'email' | 'name' | 'avatarUrl' | 'role' | 'sub'>
  )> }
);

export type GetStudentsQueryVariables = {};


export type GetStudentsQuery = (
  { __typename?: 'Query' }
  & { students: Array<(
    { __typename?: 'Student' }
    & Pick<Student, '_id' | 'name' | 'gender' | 'studentId'>
    & { parents: Array<(
      { __typename?: 'User' }
      & Pick<User, '_id' | 'sub' | 'avatarUrl' | 'email' | 'name' | 'role'>
    )> }
  )> }
);

export const StudentField = gql`
    fragment StudentField on Student {
  _id
  name
  studentId
  gender
}
    `;
export const ClassFields = gql`
    fragment ClassFields on Class {
  _id
  code
  dayOfWeek
  startTime
  endTime
  teacher {
    _id
    name
    avatarUrl
    email
    role
  }
  students {
    ...StudentField
  }
  lessons {
    _id
    description
    startDateTime
    endDateTime
    zoomUrl
    zoomId
    zoomPassword
    weekNumber
    teacher {
      _id
      name
      avatarUrl
      email
      role
    }
    assignments {
      _id
      name
      submits {
        _id
        markedAnnotations
        submitFiles {
          name
          fileId
        }
        submitBy {
          ...StudentField
        }
        markedFiles {
          name
          fileId
        }
        markedBy {
          _id
          name
          avatarUrl
          email
          role
        }
      }
    }
    downloads {
      _id
      name
      file {
        name
        fileId
      }
    }
    studentsWithState {
      student {
        ...StudentField
      }
      state
    }
  }
  course {
    _id
    name
    level
    startDate
    endDate
  }
}
    ${StudentField}`;
export const CourseFiels = gql`
    fragment CourseFiels on Course {
  _id
  name
  level
  startDate
  endDate
  classes {
    ...ClassFields
  }
}
    ${ClassFields}`;
export const GetCoursesFields = gql`
    fragment getCoursesFields on Course {
  _id
  level
  name
  startDate
  endDate
  currentAcademicWeek
  classes {
    _id
    code
  }
  academicWeeks {
    weekNumber
    startDate
    endDate
  }
}
    `;
export const AssignmentField = gql`
    fragment AssignmentField on Assignment {
  _id
  name
  submits {
    _id
    submitFiles {
      fileId
      name
    }
    markedFiles {
      fileId
      name
    }
    markedBy {
      _id
      name
      email
      avatarUrl
    }
    submitBy {
      _id
      name
      gender
    }
  }
}
    `;
export const StudentWithStateNoFromLesson = gql`
    fragment studentWithStateNoFromLesson on StudentWithState {
  state
  student {
    _id
    gender
    name
  }
}
    `;
export const GetCourseClassLessonFields = gql`
    fragment getCourseClassLessonFields on Lesson {
  _id
  startDateTime
  endDateTime
  zoomId
  zoomPassword
  zoomUrl
  weekNumber
  description
  assignments {
    ...AssignmentField
  }
  studentsWithState {
    ...studentWithStateNoFromLesson
  }
}
    ${AssignmentField}
${StudentWithStateNoFromLesson}`;
export const GetCourseClassFields = gql`
    fragment getCourseClassFields on Class {
  _id
  code
  dayOfWeek
  students {
    _id
    gender
    name
    studentId
  }
  teacher {
    _id
    avatarUrl
    name
    email
  }
  lessons {
    ...getCourseClassLessonFields
  }
}
    ${GetCourseClassLessonFields}`;
export const GetCourseFields = gql`
    fragment getCourseFields on Course {
  _id
  currentAcademicWeek
  academicWeeks {
    startDate
    endDate
    weekNumber
  }
  name
  level
  startDate
  endDate
  classes {
    ...getCourseClassFields
  }
}
    ${GetCourseClassFields}`;
export const GetCourseOfTeacherLessonFields = gql`
    fragment getCourseOfTeacherLessonFields on Lesson {
  _id
  assignments {
    _id
    name
    submits {
      _id
      markedBy {
        _id
        avatarUrl
        email
        name
      }
      markedFiles {
        fileId
        name
      }
      submitBy {
        _id
        gender
        name
      }
      submitFiles {
        fileId
        name
      }
    }
  }
  weekNumber
  endDateTime
  startDateTime
  studentsWithState {
    state
    student {
      _id
      name
      gender
    }
  }
}
    `;
export const GetCourseOfTeacherClassFields = gql`
    fragment getCourseOfTeacherClassFields on Class {
  _id
  code
  startTime
  endTime
  dayOfWeek
  teacher {
    _id
    avatarUrl
    email
    name
  }
  students {
    _id
    gender
    name
  }
  lessons {
    ...getCourseOfTeacherLessonFields
  }
}
    ${GetCourseOfTeacherLessonFields}`;
export const LessonFields = gql`
    fragment LessonFields on Lesson {
  _id
  description
  startDateTime
  endDateTime
  zoomUrl
  zoomId
  zoomPassword
  class {
    _id
    code
    dayOfWeek
    startTime
    endTime
  }
  teacher {
    _id
    name
    avatarUrl
    email
    role
  }
  assignments {
    _id
    name
    submits {
      _id
      markedAnnotations
      submitFiles {
        name
        fileId
      }
      submitBy {
        ...StudentField
      }
      markedFiles {
        name
        fileId
      }
      markedBy {
        _id
        name
        avatarUrl
        email
        role
      }
    }
  }
  downloads {
    _id
    name
    file {
      name
      fileId
    }
  }
  studentsWithState {
    student {
      ...StudentField
    }
    state
  }
}
    ${StudentField}`;
export const GetAnnoucement = gql`
    query getAnnoucement {
  annnouncement {
    content
    publishDate
  }
}
    `;
export const SetAnnouncement = gql`
    mutation setAnnouncement($content: String!) {
  setAnnouncement(content: $content) {
    content
    publishDate
  }
}
    `;
export const QueryClasses = gql`
    query queryClasses {
  classes {
    ...ClassFields
  }
}
    ${ClassFields}`;
export const GetAClass = gql`
    query getAClass($id: ObjectId!) {
  class(id: $id) {
    ...ClassFields
  }
}
    ${ClassFields}`;
export const SetAteacherToClass = gql`
    mutation setAteacherToClass($classId: ObjectId!, $teacherId: ObjectId!) {
  setTeacherOfClass(classId: $classId, teacherId: $teacherId) {
    _id
    code
  }
}
    `;
export const SetStudentToClass = gql`
    mutation setStudentToClass($classId: ObjectId!, $studentId: ObjectId!) {
  addStudentToClass(classId: $classId, studentId: $studentId) {
    _id
    code
  }
}
    `;
export const AddClass = gql`
    mutation addClass($input: CreateClassInput!) {
  createClass(input: $input) {
    ...ClassFields
  }
}
    ${ClassFields}`;
export const GetClass = gql`
    query getClass($id: ObjectId!) {
  class(id: $id) {
    _id
    lessons {
      _id
      weekNumber
      assignments {
        _id
      }
    }
  }
}
    `;
export const GetCourse = gql`
    query getCourse($id: ObjectId!) {
  course(id: $id) {
    ...getCourseFields
  }
}
    ${GetCourseFields}`;
export const GetCourseOfStudent = gql`
    query getCourseOfStudent($studentId: ObjectId!) {
  coursesOfStudent(studentId: $studentId) {
    classes {
      _id
      code
      lessons {
        _id
        weekNumber
        startDateTime
        endDateTime
      }
    }
  }
}
    `;
export const GetCourses = gql`
    query getCourses {
  courses {
    ...getCoursesFields
  }
}
    ${GetCoursesFields}`;
export const GetACourse = gql`
    query getACourse($id: ObjectId!) {
  course(id: $id) {
    ...CourseFiels
  }
}
    ${CourseFiels}`;
export const AddCourse = gql`
    mutation addCourse($input: CreateCourseInput!) {
  createCourse(input: $input) {
    _id
    name
  }
}
    `;
export const GetCourseOfTeacher = gql`
    query getCourseOfTeacher {
  coursesOfTeacher {
    _id
    currentAcademicWeek
    level
    name
    startDate
    endDate
    classes {
      ...getCourseOfTeacherClassFields
    }
  }
}
    ${GetCourseOfTeacherClassFields}`;
export const GetLessons = gql`
    query getLessons {
  lessons {
    ...LessonFields
  }
}
    ${LessonFields}`;
export const GetALesson = gql`
    query getALesson($id: ObjectId!, $studentId: ObjectId) {
  lesson(id: $id) {
    _id
    description
    startDateTime
    endDateTime
    zoomUrl
    zoomId
    zoomPassword
    class {
      _id
      code
      dayOfWeek
      startTime
      endTime
      course {
        _id
        name
        level
        startDate
        endDate
      }
    }
    teacher {
      _id
      name
      avatarUrl
      email
      role
    }
    assignments {
      _id
      name
      submits(studentId: $studentId) {
        _id
        markedAnnotations
        submitFiles {
          name
          fileId
        }
        submitBy {
          ...StudentField
        }
        markedFiles {
          name
          fileId
        }
        markedBy {
          _id
          name
          avatarUrl
          email
          role
        }
      }
    }
    downloads {
      _id
      name
      file {
        name
        fileId
      }
    }
    studentsWithState {
      student {
        ...StudentField
      }
      state
    }
  }
}
    ${StudentField}`;
export const GetLessonOfStudent = gql`
    query getLessonOfStudent($studentId: ObjectId!) {
  lessonOfStudent(studentId: $studentId) {
    _id
    description
    startDateTime
    endDateTime
    weekNumber
    zoomUrl
    zoomId
    zoomPassword
    class {
      _id
      code
      dayOfWeek
      startTime
      endTime
      course {
        _id
        name
        level
        startDate
        endDate
        academicWeeks {
          startDate
          endDate
          weekNumber
        }
      }
    }
    teacher {
      _id
      name
      avatarUrl
      email
      role
    }
    assignments {
      _id
      name
      submits(studentId: $studentId) {
        _id
        markedAnnotations
        submitFiles {
          name
          fileId
        }
        submitBy {
          ...StudentField
        }
        markedFiles {
          name
          fileId
        }
        markedBy {
          _id
          name
          avatarUrl
          email
          role
        }
      }
    }
    downloads {
      _id
      name
      file {
        name
        fileId
      }
    }
  }
}
    ${StudentField}`;
export const UpdateLesson = gql`
    mutation updateLesson($input: UpdateLessonInput!) {
  updateLesson(input: $input) {
    ...LessonFields
  }
}
    ${LessonFields}`;
export const SetAssignment = gql`
    mutation setAssignment($input: AddAssignmentInput!) {
  addAssignmentToLesson(input: $input) {
    _id
    name
  }
}
    `;
export const GetChildren = gql`
    query getChildren {
  children {
    ...StudentField
  }
}
    ${StudentField}`;
export const GetParents = gql`
    query getParents {
  parents {
    _id
    avatarUrl
    email
    name
    role
  }
}
    `;
export const Transfer = gql`
    mutation transfer($toLessonId: ObjectId!, $fromLessonId: ObjectId!, $studentId: ObjectId!) {
  transferStudent(toLessonId: $toLessonId, fromLessonId: $fromLessonId, studentId: $studentId)
}
    `;
export const Withdraw = gql`
    mutation withdraw($fromLessonId: ObjectId!, $studentId: ObjectId!) {
  withdrawStudent(fromLessonId: $fromLessonId, studentId: $studentId)
}
    `;
export const AddChild = gql`
    mutation addChild($input: CreateStudentInput!) {
  addStudent(input: $input) {
    _id
    name
  }
}
    `;
export const GetTeachers = gql`
    query getTeachers {
  teachers {
    _id
    email
    name
    avatarUrl
    role
    sub
  }
}
    `;
export const GetStudents = gql`
    query getStudents {
  students {
    _id
    name
    gender
    studentId
    parents {
      _id
      sub
      avatarUrl
      email
      name
      role
    }
  }
}
    `;
import React from "react";
import { Typography, Col } from "antd";

const Sider: React.FC = () => {
  return (
    <Col className="w-full h-full bg-primary hidden lg:block lg:max-w-lg sider">
      <div style={{ position: "fixed", maxWidth: "inherit" }}>
        <div className="px-12 py-12">
          <img
            src={`${window.location.origin}/update-account-logo.png`}
            alt="tpl-logo"
            width="119px"
          />
          <Typography.Title level={1} className="pt-20">
            <span className="text-white text-4xl mont-bold">
              Discover more from our PSLE Science Specialists!
            </span>
          </Typography.Title>
          <Typography.Title level={3}>
            <span className="text-white text-lg">
              Enjoy access to our library of science modules, activities and
              lessons when you signup now!
            </span>
          </Typography.Title>
        </div>
        <img
          src={`${window.location.origin}/update-account-image.png`}
          alt="science"
          className="w-full"
        />
      </div>
    </Col>
  );
};

export default Sider;

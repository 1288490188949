import React, { useRef, useEffect,createRef } from "react";
import { Layout, Menu, Row, Col, Button, BackTop, Anchor } from "antd";
import {useHistory} from 'react-router-dom';
import welcome01 from "./images/welcome-bg-01.png";
import about from "./images/abt-bg-01-1.png";
import aboutImg from "./images/abt-img.png";
import bg1 from "./images/no-bg-1.png";
import notes from "./images/notes-bg-01.png";

import asset1 from "./images/Asset-1.png";
import asset2 from "./images/Asset-2.png";
import asset3 from "./images/Asset-3.png";
import asset4 from "./images/Asset-4.png";
import asset5 from "./images/Asset-5.png";

import Styles from "./landing.module.scss";
import { useAuth0 } from "../../../Auth0Wrapper";

const Landing = () => {
  const history = useHistory()
  const {auth0Client} = useAuth0()
  const goToSignup = () => {
    history.push("/signup")
    window.scrollTo(0, 0);
  };
  const goToLogin = () => {
    auth0Client.loginWithRedirect();
    window.scrollTo(0, 0);
  }

  return <Layout className={Styles.landing}>
    <BackTop/>
    <Layout.Header>
      <Menu className={Styles.landing__menu} mode="horizontal" style={{ position: 'fixed', zIndex: 1, width: '100%', borderBottom:'none',color:"#00000099",paddingLeft:"20px" }}>
        <Menu.Item>
          <Anchor affix={false} className={Styles.landing__anchor} offsetTop={40}>
            <Anchor.Link href="#about" title="About" className={Styles.landing__anchor_text}/>
          </Anchor>
        </Menu.Item>
        <Menu.Item>
          <Anchor affix={false} className={Styles.landing__anchor} offsetTop={40}>
          <Anchor.Link href="#features" title="Features" className={Styles.landing__anchor_text}/>
          </Anchor>
        </Menu.Item>
        <Menu.Item onClick={goToSignup}>
          Sign Up
        </Menu.Item>
        <Menu.Item onClick={goToLogin}>
          Log In
        </Menu.Item>
      </Menu>
    </Layout.Header>
    <Layout.Content className={Styles.landing__content}>
      <Row className={Styles.landing__row} style={{backgroundImage:`url(${welcome01})`}}>
        <div className={Styles.landing__row_content}>
          <Col md={24} lg={12}>
            <img src={bg1} sizes="(max-width: 836px) 100vw, 836px" className={Styles.landing__welcome_image}/>
          </Col>
          <Col md={24} lg={12} className={Styles.landing__row_content_text}>
            <div>
              <h1>The Pique Lab</h1>
              <h1 style={{color:"#f44a4a"}}>Web Portal</h1>
            </div>
            <div className={`${Styles.landing__text1} ${Styles.landing__text1_right}`}>
              Enjoy access to our library of science modules, activities and lessons when you sign up now!
            </div>
            <div>
              <Button className={Styles.landing__button_primary}  onClick={goToSignup}>
                <span className={Styles.landing__button_text}>SIGN UP FOR AN ACCOUNT</span>
              </Button>
            </div>
            <div>
              <Button className={Styles.landing__button} onClick={goToLogin}>
                <span className={Styles.landing__button_text}>LOG IN TO YOUR ACCOUNT</span>
                </Button>
            </div>
          </Col>
        </div>
      </Row>
      <Row id="about" className={Styles.landing__row_about} style={{backgroundImage: `url(${about})`}}>
          <Col md={24} lg={12} className="text-left">
            <div style={{paddingLeft:"10%",marginBottom:"80px"}}>
              <div>
                <h4>WHO WE ARE</h4>
                <h2>The Pique Lab!</h2>
              </div>
              <div className={Styles.landing__text3}>
                When it comes to ensuring your child’s love for science and excellent academic performance, we are the people to trust. With our CCI™ methodology and a team of competitive PSLE Science specialists, our goal is to guide thousands of primary school students reach their potential.
              </div>
              <div>
                <Button className={Styles.landing__button_primary} onClick={()=>{window.open("https://thepiquelab.com",'_blank');}}>
                  <span className={Styles.landing__button_text}>KNOW MORE</span>
                </Button>
              </div>
            </div>
          </Col>
          <Col md={24} lg={12} style={{marginTop:"auto"}}>
            <img src={aboutImg} sizes="(max-width: 836px) 100vw, 836px"/>
          </Col>
      </Row>
      <Row id="features" className={Styles.landing__features}>
        <div className={Styles.landing__row_content}>
          <Col xs={24} className={Styles.landing__features_header}>
            <h4>WHAT'S IN IT FOR YOU?</h4>
            <h2>An Awesome Library of Science Materials!</h2>
          </Col>
          <Col sm={24} className={Styles.landing__features_collection}>
            <Row className={Styles.landing__features_collection_row}>
              <Col md={24} lg={8}>
                <div className={Styles.landing__features_card}>
                    <img src={asset5} className={Styles.landing__features_card_image}/>
                    <h4 className={Styles.landing__features_card_title}>Science Notes</h4>
                    <p>Get exclusive access to our most in-demand notes, helping your child master the science topics that will surely come out in the examinations.</p>
                </div>
              </Col>
              <Col md={24} lg={8}>
                <div className={Styles.landing__features_card}>
                    <img src={asset4} className={Styles.landing__features_card_image}/>
                    <h4 className={Styles.landing__features_card_title}>Programmes</h4>
                    <p>With specific goals, our programmes provide a step-by-step guide for P3 to P6 students in their academic adventure.</p>
                </div>
              </Col>
              <Col md={24} lg={8}>
                <div className={Styles.landing__features_card}>
                    <img src={asset3} className={Styles.landing__features_card_image}/>
                    <h4 className={Styles.landing__features_card_title}>Videos</h4>
                    <p>Watch videos to learn about interesting science topics and amazing techniques that help students achieve high examination scores.</p>
                </div>
              </Col>
            </Row>
            <Row className={Styles.landing__features_collection_row}>
              <Col md={24} lg={8}>
                <div className={Styles.landing__features_card}>
                    <img src={asset1} className={Styles.landing__features_card_image}/>
                    <h4 className={Styles.landing__features_card_title}>Awesome Deals</h4>
                    <p>Want more? There are deals you can avail to provide more learning opportunities for your child.</p>
                </div>
              </Col>
              <Col md={24} lg={8}>
                <div className={Styles.landing__features_card}>
                    <img src={asset2} className={Styles.landing__features_card_image}/>
                    <h4 className={Styles.landing__features_card_title}>Support Community</h4>
                    <p>You’re not alone in this journey. Join the community of parents and teachers who work towards a common goal.</p>
                </div>
              </Col>
              <Col md={24} lg={8}/>
            </Row>
          </Col>
        </div>
      </Row>
      <Row className={Styles.landing__row_discover_container} style={{backgroundColor:"#f2f6f9",backgroundImage:`url(${notes})`}}>
        <div className={Styles.landing__row_content}>
          <Col xs={24}>
            <div className={Styles.landing__row_discover}>
              <div style={{margin:'0 10%'}}>
                <div className={Styles.landing__discover_divider}>
                  <h4>DISCOVER THE PIQUE LAB PORTAL</h4>
                  <h2>Sign up today!</h2>
                </div>
                <div className={Styles.landing__discover_divider}>
                  <Button className={Styles.landing__button_primary} onClick={goToSignup}>
                    <span className={Styles.landing__button_text}>GET ACCESS</span>
                  </Button>
                </div>
                <p style={{fontSize:"19px"}}>Have A Question? We're Here. <a href="https://thepiquelab.com/talk-to-us/" target="_blank" rel="noopener noreferrer" style={{fontFamily:"geomanist-medium",color:"#f44a4a", fontSize:"19px"}}>Ask Us Anything!</a></p>
              </div>
            </div>
          </Col>
        </div>
      </Row>
    </Layout.Content>
    <Layout.Footer style={{backgroundColor:"#3b4b5e"}}>
      <p style={{textAlign:"center",color:"#869ba8",fontSize:"14px"}}>© Copyright 2013 – 2020: The Pique Lab Pte. Ltd. – All rights reserved</p>
    </Layout.Footer>
  </Layout>;
};

export default Landing;

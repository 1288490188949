import React from "react";
import styles from "./ZoomInfo.module.scss";

import Illust from "../../../assets/lesson_ongoing/IllustUpcoming.svg";

const Upcoming = () => {
  return (
    <div className={styles.wrapper}>
      <img src={Illust} alt='illust' className={styles.IllustBox} />
      <div>
        <div className={styles.comingTips}>This week&apos;s lesson!</div>
        <div className={styles.remindTips}>
          Don&apos;t forget to prepare your materials before joining the class!
        </div>
      </div>
    </div>
  );
};

export default Upcoming;

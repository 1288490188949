import React, { useState, useEffect, useRef } from 'react'
import { Layout } from 'antd'
import { Switch, useHistory } from 'react-router-dom'
import axiosClient from '../../../AxiosClient'
import source from '../../../AxiosCancel'
import LevelLinks from './LevelLinks'
import LevelRoutes from './LevelRoutes'
import Loading from '../../common/Loading'
import EmptyState from '../../../assets/img/emptyState.png'

const getProgrammes = async (): Promise<any> => {
  const response = await axiosClient.post('/portal/programmes', {
    cancelToken: source.token,
  })
  const levels = response.data
  return levels
}

const renderProgrammes = (levels) => {
  if (levels && levels.length) {
    return (
      <Layout className="h-full sm:px-5 md:px-10 ">
        <Layout.Header className="bg-white p0">
          <LevelLinks levels={levels} />
        </Layout.Header>
        <Layout.Content className="pt-16">
          <Switch>
            <LevelRoutes levels={levels} />
          </Switch>
        </Layout.Content>
      </Layout>
    )
  }
  return (
    <Layout className="h-full">
      <img src={EmptyState} style={{ margin: 'auto' }} />
    </Layout>
  )
}

const Articles: React.FC = () => {
  const [levels, setLevels] = useState()
  const [loading, setLoading] = useState(true)
  const history = useHistory()
  const isMounted = useRef(true)

  useEffect(() => {
    if (loading) {
      getProgrammes().then((data) => {
        setLevels(data)
        setLoading(false)
        if (data.length && (isMounted.current || history.location.pathname=="/portal/programmes")) {
          const level = data[0].name
          history.push(`/portal/programmes/${level}`)
        }
        return data
      })
    }
  }, [loading])
  useEffect(() => {
    return () => {
      isMounted.current = false
      source.cancel()
    }
  }, [])
  return <>{loading && isMounted.current ? <Loading /> : renderProgrammes(levels)}</>
}

export default Articles

import React, { useState } from "react";
import { Collapse, Row, Typography, Checkbox } from "antd";
// import { useParams } from "react-router-dom";
// import AddStudentToClass from "../Classes/AddStudentToClass";
import { useParams } from "react-router-dom";
import { GetCourseClassFieldsFragment } from "../../../graphql";
import LessonPanelHeader from "./LessonPanelHeader";
import LessonDetail from "./LessonDetail";

interface Props {
  class: GetCourseClassFieldsFragment;
}

const Lessons = ({ class: { lessons = [] } }: Props) => {
  const { weekNumber: currentWeek } = useParams();
  const [activeKey, setActiveKey] = useState<string | string[]>("");
  const [isShowAllLesson, setIsShowAllLesson] = useState(true);

  const handleLessons = () => {
    if (isShowAllLesson) {
      const recentLessons = lessons.filter(
        (l) =>
          l.weekNumber >= parseInt(currentWeek, 10) - 2 &&
          l.weekNumber <= parseInt(currentWeek, 10) + 2
      );
      return recentLessons;
    }
    return lessons;
  };

  return (
    <>
      <Row justify="space-between" align="middle" style={{ height: "5rem" }}>
        <Typography.Title level={3}>Lessons</Typography.Title>
        {/* <AddStudentToClass classId={classId} /> */}
      </Row>
      <Checkbox
        checked={isShowAllLesson}
        onChange={(e) => {
          setIsShowAllLesson(e.target.checked);
        }}
      >
        Show recent lessons only
      </Checkbox>
      <Collapse
        accordion
        activeKey={activeKey}
        expandIconPosition="right"
        onChange={(active) => setActiveKey(active)}
      >
        {handleLessons().map((lesson) => (
          <Collapse.Panel
            key={lesson._id}
            header={<LessonPanelHeader lesson={lesson} />}
          >
            <LessonDetail lesson={lesson} />
          </Collapse.Panel>
        ))}
      </Collapse>
    </>
  );
};

export default Lessons;

import React from 'react'
import ReactDOM from 'react-dom'
import './index.less'
import './assets/css/tailwind.css'
import App from './App'
import * as serviceWorker from './serviceWorker'
import history from './utils/history'
import { Auth0Provider } from './Auth0Wrapper'

const onRedirectCallback = (appState?: any): void => {
  history.push(
    appState && appState.targetUrl
      ? appState.targetUrl
      : window.location.pathname,
  )
}

ReactDOM.render(
  <Auth0Provider
    clientOptions={{
      domain: process.env.REACT_APP_AUTH0_DOMAIN,
      issuer: process.env.REACT_APP_AUTH0_ISSUER || undefined,
      client_id: process.env.REACT_APP_AUTH0_CLIENT_ID,
      redirect_uri: window.location.origin,
      audience: process.env.REACT_APP_AUTH0_API_AUDIENCE,
      scope:
        'admin teacher parent student update:users update:users_app_metadata',
    }}
    onRedirectCallback={onRedirectCallback}
  >
    <App />
  </Auth0Provider>,
  document.getElementById('root'),
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()

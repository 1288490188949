/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import studentMale from "../../../assets/img/studentMale.svg";
import studentFaMale from "../../../assets/img/studentFemale.svg";

interface AvatorProps {
  name: string;
  gender: "Male" | "Female" | string;
  height: number;
  width: number;
  showName: boolean;
  onClick?: () => void;
}

const Avator = (props: AvatorProps) => {
  const { name, gender, onClick, height, width, showName } = props;

  const handleClick = () => {
    if (onClick) onClick();
  };

  return (
    <div onClick={() => handleClick()}>
      <div
        style={{
          backgroundColor: "#F9EAE1",
          height: `${height}px`,
          width: `${width}px`,
        }}
        className={`rounded-full mx-auto ${height > 100 ? "p-4" : "p-1"}`}
      >
        {gender === "Male" ? (
          <img className="object-fill w-full" src={studentMale} alt="male" />
        ) : (
          <img
            className="object-fill w-full"
            src={studentFaMale}
            alt="female"
          />
        )}
      </div>
      <div
        style={{ width: `${width}px` }}
        className={`text-white text-center font-bold pt-2 ${
          showName ? "" : "hidden"
        }`}
      >
        {name}
      </div>
    </div>
  );
};

export default Avator;

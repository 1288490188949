import React from "react";
import { Route } from "react-router-dom";
import { Level } from "./types";
import ProgrammeCards from "./ProgrammeCards";

interface Props {
  levels?: Level[];
}

const LevelRoutes = ({ levels }: Props) => {
  if (levels) {
    return (
      <>
        {levels.map((level) => {
          return (
            <Route
              key={level.name}
              className="md:text-4xl mr-12 pb-2"
              activeStyle={{ borderBottom: "5px solid #F36B7F" }}
              path={`/portal/programmes/${level.name}`}
            >
              <ProgrammeCards programmes={level.programmes} />
            </Route>
          );
        })}
      </>
    );
  }
  return <></>;
};

export default LevelRoutes;

import React from 'react'
import { Form, Typography, Row, Col, Checkbox, Button } from 'antd'
import LevelOptions from '../SignUp/LevelOptions'

interface EditAccountProps {
  email: string
  childLevel: string[]
  handleEdit?: any
  handleExit?: any
  loading?:boolean;
}

const EditAccount: React.FC<EditAccountProps> = (props: EditAccountProps) => {
  const [form] = Form.useForm()
  const { email, childLevel, handleEdit, handleExit,loading } = props

  const onFinish = (data) => {
    handleEdit(data)
  }

  return (
    <>
      <Row className="mt-10">
        <Col>
          <Typography.Title level={4} className="text-2xl">Account</Typography.Title>
        </Col>
      </Row>
      <Row>
        <Typography.Text style={{color:"#717171"}}>EMAIL</Typography.Text>
      </Row>
      <Row className="mb-4">
        <div style={{color:"#212121"}}>{email}</div>
      </Row>
      <Form
        form={form}
        layout="vertical"
        onFinish={onFinish}
        initialValues={{ childLevel }}
      >
        <Form.Item name="childLevel" rules={[{required: true, message: "Select at least one Level"}]}>
          <Checkbox.Group className="w-full">
            <LevelOptions/>
          </Checkbox.Group>
        </Form.Item>
        <Row className="my-4">
          <Col className="text-right w-full" md={24}>
            <Button
              onClick={handleExit}
              className="text-primary border-primary rounded mx-4"
            >
              Cancel
            </Button>
            <Button loading={loading} htmlType="submit" className="bg-primary rounded text-white">
              Save
            </Button>
          </Col>
        </Row>
      </Form>
    </>
  )
}

export default EditAccount

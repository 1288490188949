import React, { useState } from "react";
import { Row, Col, Skeleton } from "antd";
import { VideoRecord } from "./types";
import CustomCard from "../../common/CustomCard";

interface Props {
  data?: VideoRecord[];
}

const VideoCards = ({ data }: Props) => {
  const [avatarLoaded, setAvatarLoaded] = useState(false);
  if (data) {
    return (
      <Row>
        {data.map((item) => {
          const { title, videos, video } = item;
          const image = videos ? videos.videos[0].thumbnail : video.thumbnail;
          return (
            <Col xs={24} md={12} xl={8} key={title} className="flex">
              <CustomCard
                localUrl={`/portal/videos/${title.replace(/[{()}]/g, "")}`}
                img={image}
                className="flex-1"
              >
                <div>
                  <b className="semibold text-lg">{title}</b>
                </div>
                {videos !== undefined && (
                  <div
                    className="pt-4"
                  >
                    <span>
                      <Skeleton
                        avatar
                        paragraph={false}
                        title={false}
                        loading={!avatarLoaded}
                        className="absolute"
                        active
                      />
                      <img
                        src={videos.author.image}
                        className="h-6 w-6 mr-4 overflow-hidden rounded-full inline"
                        alt="avatar"
                        onLoad={() => {
                          setAvatarLoaded(true);
                        }}
                      />
                      <div className="inline align-middle">
                        {videos.author.name}
                      </div>
                    </span>
                  </div>
                )}
              </CustomCard>
            </Col>
          );
        })}
      </Row>
    );
  }
  return <></>;
};

export default VideoCards;

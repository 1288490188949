import React from "react";
import { Switch, Route, useHistory, useRouteMatch } from "react-router-dom";
import { useQuery } from "@apollo/react-hooks";
import Loading from "../components/common/Loading";
import StudentPage from "./StudentPage";
import AccountSelect from "../components/Student/AccountSelect";
import { GetChildrenQuery, GetChildren } from "../graphql";

const Parent = () => {
  const { path } = useRouteMatch();
  const history = useHistory();
  const { loading, data } = useQuery<GetChildrenQuery>(GetChildren);

  const handleChange = (studentId: string) => {
    history.push(`${path}/${studentId}`);
  };

  if (loading || !data) {
    return <Loading />;
  }

  return (
    <Switch>
      <Route exact path={path}>
        <div>
          <AccountSelect
            students={data.children}
            onSelect={(val) => handleChange(val)}
          />
        </div>
      </Route>
      <Route path={[`${path}/:studentId`]}>
        <StudentPage students={data.children} />
      </Route>
    </Switch>
  );
};

export default Parent;

import React from "react";
import { Route, Switch } from "react-router-dom";
import { Layout } from "antd";
import CategoryLinks from "./CategoryLinks";
import CategoryRoutes from "./CategoryRoutes";
import { Level, Category } from "./types";

interface Props {
  levels?: Level[];
}

const LevelRoutes = ({ levels }: Props) => {
  if (levels) {
    return (
      <>
        <Switch>
          {levels.map((level) => {
            const categories = level.categories.map((category: Category) => {
              const { name, articles } = category;
              return { name, articles };
            });
            return (
              <Route key={level.name} path={`/portal/articles/${level.name}`}>
                <Layout className="h-full">
                  <Layout.Header
                    className="bg-white p-0 pt-4"
                    style={{ overflowX: "scroll", overflowY: "hidden" }}
                  >
                    <CategoryLinks categories={categories} level={level} />
                  </Layout.Header>
                  <Layout.Content>
                    <Switch>
                      <CategoryRoutes categories={categories} level={level} />
                    </Switch>
                  </Layout.Content>
                </Layout>
              </Route>
            );
          })}
        </Switch>
      </>
    );
  }
  return <></>;
};

export default LevelRoutes;

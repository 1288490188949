import React, { Fragment } from "react";
import moment from "moment";
import { NavLink, useRouteMatch } from "react-router-dom";
import LogoColor from "../../assets/img/logoColor.svg";

interface AcademicWeek {
  startDate: string;
  endDate: string;
  weekNumber: number;
}

interface ClassWithWeek {
  _id: string;
  code: string;
  lessons: any[];
  weeks: AcademicWeek[];
}

interface Props {
  classes: ClassWithWeek[];
  onLessonChange?(weekNumber: any): void;
}

const StudentSider = (props: Props) => {
  const { classes, onLessonChange } = props;
  const { url } = useRouteMatch();

  const handleNavClick = (lesson: any) => {
    if (onLessonChange) {
      onLessonChange(lesson);
    }
  };

  return (
    <div>
      <div>
        <img className="pl-8 pt-6 pb-16" src={LogoColor} alt="" />
      </div>
      {classes.map((mClass) => (
        <Fragment key={mClass._id}>
          <p style={{ color: "#969696" }} className="pl-8 font-bold pb-6">
            {mClass.code}
          </p>
          <div className="h-full flex flex-col-reverse">
            {mClass.lessons.map((lesson) => (
              <NavLink
                style={{ color: "#4F537B" }}
                className="pl-8 pr-2 block border-none text-lg py-4 min-w-240"
                key={lesson._id}
                to={`${url}/${lesson._id}`}
                activeClassName="bg-white"
                onClick={() => handleNavClick(lesson)}
              >
                <div className="font-semibold">Week {lesson.weekNumber}</div>
                <div>
                  {`${moment(
                    mClass.weeks[lesson.weekNumber - 1].startDate
                  ).format("Do MMMM")} - ${moment(
                    mClass.weeks[lesson.weekNumber - 1].endDate
                  ).format("Do MMMM")}`}
                </div>
              </NavLink>
            ))}
          </div>
        </Fragment>
      ))}
    </div>
  );
};

export default StudentSider;

import React from "react";
import CourseByWeek from "../courseByWeek/CourseByWeek";
import { Course } from "../../../../graphql";
import ClassList from "../classList/ClassList";

interface Props {
  course: Course;
}

const CourseListItem = ({ course }: Props) => {
  return (
    <>
      <CourseByWeek course={course} />
      <ClassList
        classes={course.classes}
        courseId={course._id}
        weekNumber={course.currentAcademicWeek}
      />
    </>
  );
};

export default CourseListItem;

import React, { Component } from "react";
import { PlusCircleOutlined, CloudUploadOutlined } from "@ant-design/icons";
import { Upload, Modal, Button, message } from "antd";
import style from "./Student.module.scss";
import axiosClient from "../../AxiosClient";

interface Props {
  studentId: string;
  assignmentId: string;
  onUploaded?(): void;
}

interface State {
  fileList: any[];
  uploading: boolean;
  previewVisible: boolean;
  previewImage: string;
}

function getBase64(file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });
}

class PictureUpload extends Component<Props, State> {
  constructor(props) {
    super(props);
    this.state = {
      fileList: [],
      uploading: false,
      previewVisible: false,
      previewImage: "",
    };
  }

  handlePreview = async (file) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj);
    }

    this.setState({
      previewVisible: true,
      previewImage: file.url || file.preview,
    });
  };

  handleChange = ({ fileList }) => this.setState({ fileList });

  handleBeforeUpload = (afile) => {
    this.setState((state) => ({
      fileList: [...state.fileList, afile],
    }));
    return false;
  };

  handleCancel = () => {
    this.setState({
      previewVisible: false,
    });
  };

  handleUpload = () => {
    const { studentId, assignmentId, onUploaded } = this.props;
    const { fileList, uploading } = this.state;

    const formData = new FormData();
    fileList.forEach((file, index) => {
      formData.append(`files${index}`, file.originFileObj);
    });

    this.setState({
      uploading: true,
    });

    axiosClient
      .post(`/assignment/submit/${assignmentId}/${studentId}`, formData)
      .then((res) => res.data)
      .then((data) => {
        if (data.error) {
          message.error(
            "Upload unsuccessful. Please try again, or contact support."
          );
        } else {
          this.setState({
            fileList: [],
            uploading: false,
          });
          if (onUploaded) {
            onUploaded();
          }
        }
      })
      .catch((err) => {
        console.error(err);
        message.error("The selected file is too large, limit 50M");
      })
      .finally(() => {
        this.setState({
          uploading: false,
        });
      });
  };

  render() {
    const { previewVisible, previewImage, fileList, uploading } = this.state;
    return (
      <div>
        <Upload
          accept="image/*,.pdf"
          listType="picture-card"
          fileList={fileList}
          onPreview={this.handlePreview}
          onChange={(val) => this.handleChange(val)}
          beforeUpload={(file) => this.handleBeforeUpload(file)}
        >
          <div className={style.assignment__addMoreButton}>
            <PlusCircleOutlined />
          </div>
        </Upload>

        <div className={style.assignment__uploadButton_margin}>
          <div />
          <Button
            type="primary"
            onClick={this.handleUpload}
            disabled={fileList.length === 0}
            loading={uploading}
            className="flex flex-row items-center justify-center"
          >
            <CloudUploadOutlined />
            Submit
          </Button>
        </div>
        <Modal
          visible={previewVisible}
          footer={null}
          onCancel={this.handleCancel}
        >
          <img alt="example" style={{ width: "100%" }} src={previewImage} />
        </Modal>
      </div>
    );
  }
}

export default PictureUpload;

import React from "react";
import { Row, Typography } from "antd";
import { LogoutOutlined } from "@ant-design/icons";
import { useAuth0 } from "../../../Auth0Wrapper";

const MenuBar = () => {
  const { logout, dbUser } = useAuth0();

  return (
    <Row justify="end" align="middle">
      <Typography.Text style={{ color: "white" }}>
        Hello, {dbUser.name}
      </Typography.Text>
      <LogoutOutlined
        style={{ color: "white", padding: 10 }}
        onClick={() =>
          logout({
            returnTo: window.location.origin
          })
        }
      />
    </Row>
  );
};

export default MenuBar;

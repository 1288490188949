import React, { useEffect, useState } from "react";
import { message, Button, List } from "antd";
import { DownloadOutlined } from "@ant-design/icons";
import axiosClient from "../../../AxiosClient";
import { AssignmentFieldFragment } from "../../../graphql";

interface Props {
  assignments: AssignmentFieldFragment[];
  studentId: string;
  onSave(): void;
}

const DownloadSubmits = (props: Props) => {
  const { assignments, studentId, onSave } = props;
  const [studentSubmit, setStudentSubmit] = useState<AssignmentFieldFragment[]>(
    []
  );
  const [isUploading, setIsUploading] = useState(false);

  useEffect(() => {
    const mAssignments = Array.from(assignments, (assignment) => {
      const mSubmits = assignment.submits.find(
        (submit) => submit.submitBy._id === studentId
      );
      const result = {
        _id: assignment._id,
        name: assignment.name,
        submits: [{ ...mSubmits }],
      };
      return result;
    });
    setStudentSubmit(mAssignments);
  }, [assignments, studentId]);

  const handleDownload = (fileId) => {
    setIsUploading(true);
    axiosClient
      .get(`assignment/downloadlink/${fileId}`)
      .then((res) => {
        message.success("download success.");
        window.open(`${res.data}`, "_blank");
        setIsUploading(false);
        onSave();
      })
      .finally(() => {
        setIsUploading(false);
        onSave();
      });
  };

  return (
    <>
      <List
        bordered
        dataSource={studentSubmit}
        renderItem={(assignment) =>
          assignment.submits.map((submit) => (
            <List.Item
              key={submit._id}
              actions={[
                <Button
                  key="download"
                  type="primary"
                  onClick={() => {
                    handleDownload(submit.submitFiles[0].fileId);
                  }}
                  disabled={!submit.submitFiles}
                  loading={isUploading}
                >
                  Download Submit
                </Button>,
              ]}
            >
              <h3>{assignment.name}</h3>
            </List.Item>
          ))
        }
      />
    </>
  );
};

export default DownloadSubmits;

import React from "react";

interface Props {
  currentCategory?: string;
  onClick?: any;
  title?: string;
  category?: string;
}

const QuestionTypeButton = ({
  currentCategory,
  onClick,
  title,
  category,
}: Props) => {
  return (
    <button
      type="button"
      className={`py-2 px-4 sm:mr-8 ${
        currentCategory === category ? "bg-primary sm:text-white rounded" : ""
      }`}
      onClick={onClick}
    >
      {title}
    </button>
  );
};

export default QuestionTypeButton;

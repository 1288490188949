import React from "react";
import { Row, Col, Card } from "antd";
import { ArrowRightOutlined } from "@ant-design/icons";
import { Programme } from "./types";
import CustomCard from "../../common/CustomCard";
import arrowForward from "../../../assets/img/icons/arrow_forward_24px.svg"

interface Props {
  programmes?: Programme[];
}

const ProgrammeCards = ({ programmes }: Props) => {
  if (programmes) {
    return (
      <Row>
        {programmes.map((programme) => {
          const { image, title, description, url } = programme;
          return (
            <Col xs={24} md={12} xl={8} key={title} className="flex">
              <CustomCard url={url} img={image} className="card-body-fill flex flex-col flex-1">
                <div className="flex-1">
                  <b className="semibold mb-2 text-lg">{title}</b>
                </div>
                <Card.Meta
                  className="pt-4"
                  description={
                    <>
                      <div className="inline align-middle text-primary text-base mont-bold">
                        <p className="inline align-bottom">LEARN MORE</p><img src={arrowForward} alt="arrow forward" className="inline"/>
                      </div>
                      
                    </>
                  }
                />
              </CustomCard>
            </Col>
          );
        })}
      </Row>
    );
  }
  return <></>;
};

export default ProgrammeCards;

import React, { useState, useContext } from "react";
import { Modal } from "antd";
import { Assignment } from "../../graphql";
import style from "./Student.module.scss";
import PictureUpload from "./PictureUpload";
import { LessonContext } from "../../pages/studentPageContext";
import TestSubmission from "./SubmissionBox/TestSubmission";
import MarkedSubmission from "./SubmissionBox/MarkedSubmission";

interface Props {
  assignments: Assignment[];
  lessonStart: string;
  studentId: string;
  onUpload?(): void;
}

const Assignments = (props: Props) => {
  const { assignments, studentId } = props;
  const [isShowUpload, setIsShowUpload] = useState(false);
  const { refetch } = useContext(LessonContext);

  const openUploadModal = () => {
    setIsShowUpload(true);
  };

  return (
    <div>
      {assignments.map((assignment) => (
        <div
          key={assignment._id}
          className="flex flex-col md:flex-row justify-between"
        >
          <div className="w-full md:pr-4 md:w-1/2">
            <TestSubmission
              TEST_DATA={assignment.submits[0]}
              onClickUpload={() => openUploadModal()}
            />
          </div>
          <div className="w-full  md:pl-4 md:w-1/2">
            <MarkedSubmission MARKED_DATA={assignment.submits[0]} />
          </div>
          <Modal
            visible={isShowUpload}
            footer={null}
            destroyOnClose
            style={{ backgroundColor: "transparent" }}
            onCancel={() => setIsShowUpload(false)}
          >
            <div>
              <div className={style.modalFilesName}>Test Submissions</div>
              <PictureUpload
                studentId={studentId}
                assignmentId={assignment._id}
                onUploaded={() => {
                  refetch();
                  setIsShowUpload(false);
                }}
              />
            </div>
          </Modal>
        </div>
      ))}
    </div>
  );
};

export default Assignments;

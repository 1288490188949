import React from "react";
import { Button, Modal, message, Row, Col, Typography } from "antd";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import { useMutation } from "@apollo/react-hooks";
import { Withdraw, GetALesson } from "../../../graphql";

interface Props {
  studentId: string;
  lessonId: string;
}

const WithDraw = (props: Props) => {
  const { lessonId, studentId } = props;
  const [withdrawStudent] = useMutation(Withdraw, {
    refetchQueries: [
      {
        query: GetALesson,
        variables: {
          id: lessonId,
        },
      },
    ],
  });
  const handleWithDraw = () => {
    Modal.confirm({
      icon: <ExclamationCircleOutlined />,
      title: "Are you sure to delete this student? ",
      cancelText: "Cancel",
      onCancel() {},
      onOk: () => {
        withdrawStudent({
          variables: {
            fromLessonId: lessonId,
            studentId,
          },
        }).then(({ data }) => {
          if (data.withdrawStudent) {
            message.success("Withdraw Success");
          } else {
            message.error("Withdraw Failed");
          }
        });
      },
    });
  };
  return (
    <>
      <Typography.Title level={4}>WithDraw</Typography.Title>

      <Row justify="space-between">
        <Col>
          <Button type="danger" onClick={handleWithDraw}>
            WithDraw this student
          </Button>
        </Col>
      </Row>
    </>
  );
};

export default WithDraw;

import React, { useState } from "react";
import { NavLink, useLocation } from "react-router-dom";
import { Menu } from "antd";
import { Level } from "./types";

interface Props {
  levels?: Level[];
}

const LevelLinks = ({ levels }: Props) => {
  let location = useLocation();
  if (levels) {
    return (
      <Menu mode="horizontal" style={{ width: "fit-content" }}>
        {levels.map((level) => {
          const base = `/portal/articles/${level.name}`;
          const to = `/portal/articles/${level.name}/all`;
          let active = false;
          if (location.pathname.includes(base)) {
            active = true;
          }
          return (
            <Menu.Item
              key={level.name}
              className={`menu-underlined borderless`}
            >
              <NavLink
                className={`pr-3 semibold ${active && "active"}`}
                activeStyle={{ color: "#212121" }}
                style={{
                  fontSize: "2em",
                  color: "#717171",
                  lineHeight: "3rem",
                }}
                to={to}
              >
                {level.name}
              </NavLink>
            </Menu.Item>
          );
        })}
      </Menu>
    );
  }
  return <></>;
};

export default LevelLinks;

import React from "react";
import { Button } from "antd";
import { PlusOutlined, CloudUploadOutlined } from "@ant-design/icons";
import { Submit } from "../../../graphql";

import SubmissionBox from "./SubmissionBox";
import Download from "../DownloadItem";

import styles from "./SubmissionBox.module.scss";
import testPic from "../../../assets/lesson_ongoing/testSub.svg";

interface TestSubmissionProps {
  TEST_DATA: Submit;
  onClickUpload(): void;
}

const TestSubmission = (props: TestSubmissionProps) => {
  const { TEST_DATA, onClickUpload } = props;
  return (
    <SubmissionBox boxTitle="Test Submissions">
      {TEST_DATA ? (
        <div className={styles.relativeBox}>
          <div className="mb-16 w-full flex flex-wrap items-start justify-evenly">
            {TEST_DATA.submitFiles.map((testSubItem) => {
              return (
                <Download
                  key={testSubItem.fileId}
                  name={testSubItem.name}
                  fileId={testSubItem.fileId}
                />
              );
            })}
          </div>
          <Button onClick={() => onClickUpload()} className={styles.UploadBtn}>
            <PlusOutlined style={{ fontSize: "16px" }} />
            Add Files
          </Button>
        </div>
      ) : (
        <div className={styles.markedBox}>
          <img
            src={testPic}
            alt="test submissions"
            className={styles.markedPicBox}
          />
          <div className="mt-6">
            <div className={styles.markedTips}>Done with your assignment?</div>
            <div className={styles.markedTips}>Upload it here!</div>
          </div>
          <Button
            onClick={() => onClickUpload()}
            className={styles.UploadTestSub}
          >
            <CloudUploadOutlined />
            Upload file
          </Button>
        </div>
      )}
    </SubmissionBox>
  );
};

export default TestSubmission;

import React from 'react'
import { Typography, Row, Col, Button } from 'antd'

interface ViewAccountProps {
  email: string
  childLevel: string[]
  handleEdit?: any
}

const ViewAccount: React.FC<ViewAccountProps> = (props: ViewAccountProps) => {
  const { email, childLevel, handleEdit } = props
  return (
    <>
      <Row className="mt-10">
        <Col className="flex w-full">
          <div className="flex-1">
            <Typography.Title level={4} className="text-2xl">Account</Typography.Title>
          </div>
          <div>
            <Button
              onClick={handleEdit}
              className="bg-primary rounded text-white"
            >
              Edit
            </Button>
          </div>
        </Col>
      </Row>
      <Row>
        <Typography.Text className="pb-4" style={{color:"#717171"}}>EMAIL</Typography.Text>
      </Row>
      <Row className="mb-4">
        <div style={{color:" #212121"}}>{email}</div>
      </Row>
      <Row>
        <Typography.Text className="pb-4" style={{color:"#717171"}}>LEVELS</Typography.Text>
      </Row>
      <Row className="mb-4">
        <div style={{color:" #212121"}}>{childLevel.join(', ')}</div>
      </Row>
    </>
  )
}

export default ViewAccount

import React from "react";
import { Collapse, Button, Row, Typography, Spin } from "antd";
import { useParams, Link } from "react-router-dom";
import { useQuery } from "@apollo/react-hooks";
import {
  GetCourse,
  GetCourseQuery,
  GetCourseQueryVariables,
} from "../../../graphql";
import { Lessons } from "../Lessons";
import Loading from '../../common/Loading';

const Classes = () => {
  const { classId, courseId } = useParams();

  const { data, loading } = useQuery<GetCourseQuery, GetCourseQueryVariables>(
    GetCourse,
    {
      variables: {
        id: courseId,
      },
    }
  );

  if (loading || !data) return <Loading />;

  return (
    <>
      <Row justify="space-between" align="middle" style={{ height: "5rem" }}>
        <Typography.Title level={3}>{data.course.name}</Typography.Title>
        <Link to={`/admin/course/${courseId}`}>
          <Button>Go Back To Course</Button>
        </Link>
      </Row>
      <Collapse accordion defaultActiveKey={classId}>
        {data.course.classes.map((c) => (
          <Collapse.Panel header={c.code} key={c._id}>
            <Lessons class={c} />
          </Collapse.Panel>
        ))}
      </Collapse>
    </>
  );
};

export default Classes;

import React from 'react'
import { Route } from 'react-router-dom'
import { Layout } from 'antd'
import ArticleCards from './ArticleCards'
import { Level, Category } from './types'

interface Props {
  level?: Level
  categories?: Category[]
}

const CategoryRoutes = ({ categories, level }: Props) => {
  if (categories && level) {
    const allArticles = []
    categories.forEach((category) => {
      allArticles.push(...category.articles)
    })
    return (
      <Layout className="pt-6">
        <Route path={`/portal/articles/${level.name}/all`}>
          <ArticleCards articles={allArticles} />
        </Route>
        {categories.map((category) => {
          const { articles } = category
          return (
            <Route
              key={category.name}
              path={`/portal/articles/${level.name}/${category.name}`}
            >
              <ArticleCards articles={articles} />
            </Route>
          )
        })}
      </Layout>
    )
  }
  return <></>
}

export default CategoryRoutes

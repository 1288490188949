import React, { useState, useEffect } from "react";
import { useHistory, Link } from "react-router-dom";
import MenuIcon from "../../assets/img/menu.svg";
import { StudentFieldFragment } from "../../graphql";
import Clock from "./Clock";
import Avator from "./AccountSelect/Avatar";

interface StudentMenuHeaderProps {
  onToggleCollapse(): void;
  students: StudentFieldFragment[];
  currentStudentId: string;
  weekNumber?: number;
}

const StudentMenuHeader = (props: StudentMenuHeaderProps) => {
  const { onToggleCollapse, students, currentStudentId, weekNumber } = props;
  const history = useHistory();

  const [currentStudent, setCurrentStudent] = useState<StudentFieldFragment>();

  useEffect(() => {
    setCurrentStudent(students.find((s) => s._id === currentStudentId));
  }, []);

  const redirectToAccountSelect = () => {
    history.push("/parent");
  };

  return (
    <div
      className="flex flex-row justify-between items-center"
      style={{ minWidth: "185px" }}
    >
      <div className="block pl-4 md:hidden">
        <button type="button" onClick={() => onToggleCollapse()}>
          <img src={MenuIcon} alt="menu" />
        </button>
      </div>
      <div className="block md:hidden font-bold ">Week {weekNumber}</div>
      <div className="block md:hidden pr-4">
        <Avator
          onClick={() => redirectToAccountSelect()}
          name=""
          gender={currentStudent !== undefined ? currentStudent.gender : "Male"}
          showName={false}
          height={50}
          width={50}
        />
      </div>
      <div
        className="hidden md:inline-block md:pl-4"
        onClick={() => onToggleCollapse()}
      >
        <Clock />
      </div>
      <div className="hidden md:block md:pr-4">
        {currentStudent && (
          <Link
            to="/parent"
            className="md:flex md:flex-row md:items-center md:hover:text-red-600"
          >
            <Avator
              name={currentStudent.name}
              gender={currentStudent.gender}
              showName={false}
              height={30}
              width={30}
            />
            <span className="pl-2 font-semibold">{currentStudent.name}</span>
          </Link>
        )}
      </div>
    </div>
  );
};

export default StudentMenuHeader;

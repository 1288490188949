import React, { useState, useEffect } from "react";
import {
  Layout,
  Form,
  Button,
  Checkbox,
  Typography,
  Col,
  Row,
  Input,
  Select,
  Modal,
  Radio
} from "antd";
import { useHistory } from "react-router-dom";
import axios from "../../../../AxiosClient";
import { useAuth0 } from "../../../../Auth0Wrapper";
import LevelOptions from "./LevelOptions";
import schools from "./schools.json";
import PrivacyNotice from "./PrivacyNotice";
import TermsAndConditions from "./TernsAndConditions";
interface Values {
  username: string;
  password: string;
  email: string;
  firstName: string;
  lastName: string;
  childfirstName: string;
  childlastName: string;
  childLevel: string;
  childSchool: string;
  ["tncppAgree"]: boolean;
}

interface Props {
  authorized?: boolean;
}

const SignupForm: React.FC<Props> = ({ authorized }: Props) => {
  const { user, dbUser, updateToken } = useAuth0();
  const [loading, setLoading] = useState(false);
  const [userExists, setUserExists] = useState(false);
  const [weakPassword, setWeakPassword] = useState(false);
  const [shortUsername, setShortUsername] = useState(false);
  const [finished, setFinished] = useState(false);
  const [showPP, setShowPP] = useState(false);
  const [showTNC, setShowTNC] = useState(false);
  const history = useHistory();

  useEffect(() => {
    if (finished) {
      window.scroll(0, 0);
      history.push("/portal");
    }
  }, [finished]);

  const onFinish = async (values: Values) => {
    setLoading(true);
    const payload = authorized
      ? {
          ...values,
          email: user.email,
          userId: dbUser.sub
        }
      : values;
    const req = await axios.post("/contact", payload);
    const result = req.data;
    if (result.error) {
      window.scroll(0, 0);
      if (result.error === "Conflict") {
        setUserExists(true);
      } else if (
        result.message === "PasswordStrengthError: Password is too weak"
      ) {
        setWeakPassword(true);
      } else if (
        result.message === "Username should have between 1 and 15 characters."
      ) {
        setShortUsername(true);
      }
      setLoading(false);
    } else {
      await updateToken(result.access_token, result.id_token);
      setShortUsername(false);
      setWeakPassword(false);
      setUserExists(false);
      setFinished(true);
    }
  };

  return (
    <>
      <Layout>
        <Layout.Content className="m-10 sm:m-10 md:mx-20 lg:mx-24 xl:mx-48">
          <Form
            scrollToFirstError
            name="Complete your Profile"
            layout="vertical"
            onFinish={onFinish}
          >
            <Typography.Title className="text-3xl mont-bold">
              {authorized ? "Complete your Profile" : "Create an Account"}
            </Typography.Title>
            <Typography.Title level={3} className="text-2xl pt-4 semibold">
              Parent Information
            </Typography.Title>
            <Row>
              <Col xs={24} md={24} lg={12}>
                <p className="py-3">FIRST NAME</p>
                <Form.Item
                  name="firstName"
                  className="lg:pr-4"
                  rules={[
                    {
                      required: true,
                      message: "First Name is required!"
                    }
                  ]}
                >
                  <Input
                    className="rounded whitespace-no-wrap"
                    style={{ height: "3.5rem" }}
                  />
                </Form.Item>
              </Col>
              <Col xs={24} lg={12}>
                <p className="py-3">LAST NAME</p>
                <Form.Item
                  name="lastName"
                  rules={[
                    {
                      required: true,
                      message: "Last Name is required!"
                    }
                  ]}
                >
                  <Input
                    className="rounded whitespace-no-wrap"
                    style={{ height: "3.5rem" }}
                  />
                </Form.Item>
              </Col>
            </Row>
            {!authorized && (
              <>
                <Row className={authorized && "hidden"}>
                  <Col xs={24}>
                    <p className="py-3">EMAIL</p>
                    <Form.Item
                      name="email"
                      validateStatus={userExists ? "error" : undefined}
                      help={userExists ? "The user already exists." : undefined}
                      rules={[
                        {
                          type: "email",
                          required: true,
                          message: "Please enter a valid e-mail"
                        }
                      ]}
                    >
                      <Input
                        className="rounded whitespace-no-wrap"
                        style={{ height: "3.5rem" }}
                      />
                    </Form.Item>
                  </Col>
                </Row>
                <Row className={authorized && "hidden"}>
                  <Col xs={24}>
                    <p className="py-3">USERNAME</p>
                    <Form.Item
                      name="username"
                      validateStatus={
                        userExists || shortUsername ? "error" : undefined
                      }
                      help={
                        (userExists && "The user already exists.") ||
                        (shortUsername &&
                          "Usernames should have between 1 and 15 characters") ||
                        undefined
                      }
                      rules={[
                        {
                          required: true,
                          message: "Username is required!"
                        }
                      ]}
                    >
                      <Input
                        className="rounded whitespace-no-wrap"
                        style={{ height: "3.5rem" }}
                      />
                    </Form.Item>
                  </Col>
                </Row>
                <Row className={authorized && "hidden"}>
                  <Col xs={24}>
                    <p className="py-3">PASSWORD</p>
                    <Form.Item
                      name="password"
                      validateStatus={weakPassword ? "error" : undefined}
                      help={weakPassword ? "Password is too weak" : undefined}
                      rules={[
                        {
                          required: true,
                          message: "Password is required!"
                        }
                      ]}
                    >
                      <Input
                        type="password"
                        className="rounded whitespace-no-wrap"
                        style={{ height: "3.5rem" }}
                      />
                    </Form.Item>
                  </Col>
                </Row>
              </>
            )}
            <Typography.Title level={3} className="text-2xl pt-8 semibold">
              Child Information
            </Typography.Title>
            <Row>
              <Col xs={24} lg={12}>
                <p className="py-3">FIRST NAME</p>
                <Form.Item
                  name="childfirstName"
                  className="pr-4"
                  rules={[
                    {
                      required: true,
                      message: "First Name is required!"
                    }
                  ]}
                >
                  <Input
                    className="rounded whitespace-no-wrap"
                    style={{ height: "3.5rem" }}
                  />
                </Form.Item>
              </Col>
              <Col xs={24} lg={12}>
                <p className="py-3">LAST NAME</p>
                <Form.Item
                  name="childlastName"
                  rules={[
                    {
                      required: true,
                      message: "Last Name is required!"
                    }
                  ]}
                >
                  <Input
                    className="rounded whitespace-no-wrap"
                    style={{ height: "3.5rem" }}
                  />
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col xs={24}>
                <Form.Item
                  name="childLevel"
                  rules={[
                    {
                      required: true,
                      message: "Select at least one Level"
                    }
                  ]}
                >
                  <Checkbox.Group className="w-full">
                    <LevelOptions />
                  </Checkbox.Group>
                </Form.Item>
              </Col>
            </Row>

            <Row>
              <Col xs={24}>
                <p className="py-3">SCHOOL (OPTIONAL)</p>
                <Form.Item name="childSchool" className="pr-4">
                  <Select
                    className="signup-select text-base"
                    placeholder="Choose From Dropdown"
                  >
                    {schools.map((school) => {
                      return (
                        <Select.Option value={school}>{school}</Select.Option>
                      );
                    })}
                  </Select>
                </Form.Item>
              </Col>
            </Row>

            <Row>
              <Col xs={24}>
                <p className="py-3">RECENT EXAM SCORE (OPTIONAL)</p>
                <Form.Item name="examScore" className="pr-4 ">
                  <Radio.Group className="signup-select text-base">
                    <Radio value="80 to 100">80 to 100</Radio>
                    <Radio value="71 to 85">71 to 85</Radio>
                    <Radio value="61 to 70">61 to 70</Radio>
                    <Radio value="50 to 60">50 to 60</Radio>
                    <Radio value="0 to 49">0 to 49</Radio>
                  </Radio.Group>
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col xs={24}>
                <Form.Item
                  name="tncppAgree"
                  valuePropName="checked"
                  rules={[
                    {
                      validator: (_, value) =>
                        value
                          ? Promise.resolve()
                          : Promise.reject(
                              "Required to agree to Terms and Conditions and Privacy Policy"
                            )
                    }
                  ]}
                >
                  <Checkbox>
                    Creating an Account means you agree with our{" "}
                    <a
                      href="#"
                      className="text-primary"
                      onClick={() => {
                        setShowTNC(true);
                      }}
                    >
                      Terms and Conditions
                    </a>{" "}
                    and{" "}
                    <a
                      href="#"
                      className="text-primary"
                      onClick={() => {
                        setShowPP(true);
                      }}
                    >
                      Privacy Policy
                    </a>
                  </Checkbox>
                </Form.Item>
                <Form.Item>
                  <Button
                    type="primary"
                    className="w-full bg-primary border-primary h-12 text-base mont-bold"
                    htmlType="submit"
                    loading={loading}
                  >
                    {authorized ? "Update" : "Create Account"}
                  </Button>
                </Form.Item>
              </Col>
            </Row>
          </Form>
        </Layout.Content>
      </Layout>
      <Modal
        title="Privacy Notice"
        onOk={() => {
          setShowPP(false);
        }}
        onCancel={() => {
          setShowPP(false);
        }}
        className="w-10/12"
        footer={null}
        visible={showPP}
        style={{ height: "85vh" }}
      >
        <PrivacyNotice />
      </Modal>
      <Modal
        title="Terms & Conditions"
        onOk={() => {
          setShowTNC(false);
        }}
        onCancel={() => {
          setShowTNC(false);
        }}
        className="w-10/12"
        footer={null}
        visible={showTNC}
      >
        <TermsAndConditions />
      </Modal>
    </>
  );
};

export default SignupForm;

import React, { useState, useEffect } from "react";
import { message, Input } from "antd";

import { useQuery, useMutation } from "@apollo/react-hooks";
import {
  GetAnnoucement,
  SetAnnouncement,
  SetAnnouncementMutation,
  SetAnnouncementMutationVariables,
  GetAnnoucementQuery,
} from "../../../graphql";

const { Search } = Input;

const Annoucement = () => {
  const { data, loading } = useQuery<GetAnnoucementQuery>(GetAnnoucement);
  const [SetAnnounce] = useMutation<
    SetAnnouncementMutation,
    SetAnnouncementMutationVariables
  >(SetAnnouncement);
  const [inputVal, setInputVal] = useState("");
  useEffect(() => {
    if (data && data.annnouncement) {
      setInputVal(data.annnouncement.content);
    }
  }, [data]);

  const handleInputChange = (e) => {
    setInputVal(e.target.value);
  };
  const handleSubmit = () => {
    SetAnnounce({ variables: { content: inputVal } })
      .then((res) => {
        console.log(res.data);
        message.success("Set announcement success!");
      })
      .catch((err) => {
        message.error(`Set announcement success! ${err.message}`);
      });
  };

  if (loading) return null;

  return (
    <div className="w-full mt-4">
      <Search
        type="text"
        allowClear
        placeholder="Input an announcement"
        value={inputVal}
        enterButton="Set announcement"
        onSearch={() => handleSubmit()}
        onChange={(e) => handleInputChange(e)}
      />
    </div>
  );
};

export default Annoucement;

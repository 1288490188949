import React from "react";
import YouTube from "react-youtube";
import getVideoId from "get-video-id";
import { Typography } from "antd";
import { VideoRecord } from "./types";

interface Props {
  page?: VideoRecord;
}

const Video = ({ page }: Props) => {
  const { title, video } = page;
  return (
    <>
      <div className="h-40 sm:h-96 md:h-120 lg:h-144">
        <YouTube
          videoId={getVideoId(video.url).id}
          id={title}
          className="my-4 w-full h-full"
          containerClassName="h-full"
        />
      </div>
      <Typography.Title level={3} className="py-4 semibold">
        {video.title.replace(/(<([^>]+)>)/gi, "")}
      </Typography.Title>
      <p>{video.description.replace(/(<([^>]+)>)/gi, "")}</p>
    </>
  );
};

export default Video;

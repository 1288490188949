import React, { useEffect } from 'react'
import { useHistory, Switch, Route } from 'react-router-dom'
import './App.css'
import { useAuth0 } from './Auth0Wrapper'
import TeacherPage from './pages/TeacherPage'
import AdminPage from './pages/AdminPage'
import Parent from './pages/ParentPage'
import Portal from './pages/PortalPage'
import SignUp from './components/Portal/Account/SignUp'
import TermsAndConditions from './components/Portal/Account/SignUp/TernsAndConditions'
import PrivacyNotice from './components/Portal/Account/SignUp/PrivacyNotice'
import Landing from './components/Portal/Landing'

const PermissionWrapper: React.FC = () => {
  const { dbUser, user, isAuthenticated } = useAuth0()
  const history = useHistory()

  useEffect(() => {
    if (isAuthenticated && dbUser) {
      const metadata = user['https://chatbot.piquehosted.com/user_metadata']
      if (metadata && metadata.infoComplete === 'true') {
        history.replace('/portal')
      } else {
        history.replace("/complete-profile");
      }
    } else {
      const allowed = ["/terms-conditions",'/privacy-policy','/signup',"/"]
      if(!allowed.includes(history.location.pathname)) {
        history.replace("/");
      }
    }
}, [isAuthenticated,dbUser, history])

  return (
    <Switch>
      <Route exact path="/">
        {!isAuthenticated && <Landing />}
        {isAuthenticated && <Portal />}
      </Route>
      <Route path="/signup">
        <SignUp />
      </Route>
      <Route path="/terms-conditions">
        <TermsAndConditions />
      </Route>
      <Route path="/privacy-policy">
        <PrivacyNotice />
      </Route>
      <Route path="/complete-profile">
        <SignUp authorized/>
      </Route>
      <Route path="/portal">
        <Portal />
      </Route>
      <Route path="/parent">
        <Parent />
      </Route>
      <Route path="/teacher">
        <TeacherPage />
      </Route>
      <Route path="/admin">
        <AdminPage />
      </Route>
    </Switch>
  )
}

export default PermissionWrapper

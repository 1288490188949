import React from 'react'
import { useHistory } from 'react-router-dom'
import { Layout, Card, Row } from 'antd'
import { DownloadOutlined } from '@ant-design/icons'
import download from "../../../assets/img/icons/Download.svg"

interface Props {
  items: any
}

const Showcase = ({ items }: Props) => {
  const history = useHistory()
  return (
    <Layout className="p-4">
      {items.map((item,index) => {
        const {
          ID,
          name,
          img,
          text,
          url,
          author,
          readtime,
          title,
          video,
          excerpt,
          thumbNail,
        } = item
        const portalPath = `https://thepiquelab.com/portal/wp-content/uploads/`
        const cover = video ? video.thumbnail : img || portalPath + thumbNail
        const cardTitle = name || title
        const cardDescription = video ? video.description : text || excerpt
        const style=(index)=>{
          if(index!==2) {
            return {borderBottom:"1px solid rgba(181, 181, 181, 0.21)"}
          }
          return undefined;
        }
        return (
          <div
            key={cardTitle}
            className="flex flex-col mb-5 cursor-pointer"
            style={style(index)}
            onClick={() => {
              if (video) {
                history.push(`portal/videos/${cardTitle}`)
              } else {
                window.open(ID ? JSON.parse(url)[0] : url, '_blank')
              }
            }}
          >
            <Row>
            <div>
              <img src={cover} className={`${!video && `h-24 w-24`} rounded object-cover`} alt={cardTitle} 
              style = {video && {width: "9rem",height: "7rem"}}/>
            </div>
            <div className="flex-1 pl-4">
                <div className="mb-2">
                  <b className="semibold text-base" style={{fontSize:"1.125rem"}}>{cardTitle}</b>
                </div>
                <div>
                  {cardDescription
                    .replace('<p>', '')
                    .replace('</p>', '')
                    .replace('<span>', '')
                    .replace('</span>', '')}
                </div>
              </div>
            </Row>
            <Row>
            <div className="flex-1 px-4 pl-24 pb-4">
              {author && (
                <Card.Meta
                  className="pt-8 pl-4"
                  title={author.name}
                  avatar={
                    <img
                      src={author.image}
                      className="h-8 w-8 overflow-hidden rounded-full inline object-cover"
                      alt="avatar"
                    />
                  }
                  description={readtime && <p>{readtime} min read</p>}
                />
              )}
              {video && <div className="pt-8 h-6"/>}
              {!author && !video && (
                <Card.Meta
                  className="pt-8 pl-4"
                  description={
                    <>
                      <img src={download} alt="download" className="inline"/>{' '}
                      <div className="inline align-middle text-primary text-lg">
                        Download File
                      </div>
                    </>
                  }
                />
              )}
            </div>
            </Row>
          </div>
        )
      })}
    </Layout>
  )
}

export default Showcase

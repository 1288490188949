import React, { useState, useEffect } from "react";
import { Layout, Menu, Row, Col } from "antd";
import { NavLink, Switch, Route } from "react-router-dom";
import axios from "../../../AxiosClient";
import source from "../../../AxiosCancel";
import { Note } from "./types";
import NoteCard from "./NoteCard";
import Loading from "../../common/Loading";

const getNotes = async (): Promise<any> => {
  const response = await axios.post("/portal/notes", {
    cancelToken: source.token,
  });
  const notes = await response.data;
  return notes;
};

const renderNotes = (notes: Note[]) => {
  if (notes) {
    return (
      <>
        <Layout className="h-full sm:px-5 md:px-10">
          <Layout.Content className="pt-2">
            <Col>
              <Row>
                {notes.map((note: Note) => {
                  return <NoteCard note={note} key={note.ID} />;
                })}
              </Row>
            </Col>
          </Layout.Content>
        </Layout>
      </>
    );
  }
  return <></>;
};

const Notes: React.FC = () => {
  const [notes, setNotes] = useState();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (loading) {
      getNotes().then((data) => {
        setNotes(data);
        setLoading(false);
        return data;
      });
    }
  }, [loading]);
  useEffect(() => {
    return () => {
      source.cancel();
    };
  }, []);
  return <>{loading ? <Loading /> : renderNotes(notes)}</>;
};

export default Notes;

import React, { useState, useEffect, useRef } from "react";
import { useHistory } from "react-router-dom";
import { Layout } from "antd";
import axios from "../../../AxiosClient";
import source from "../../../AxiosCancel";
import LevelLinks from "./LevelLinks";
import LevelRoutes from "./LevelRoutes";
import Loading from "../../common/Loading";

const getArticles = async (): Promise<any> => {
  const response = await axios.post("/portal/articles", {
    cancelToken: source.token,
  });
  const articles = response.data;
  return articles;
};

const renderArticles = (levels) => {
  if (levels) {
    return (
      <Layout className="h-full sm:px-5 md:px-10">
        <Layout.Header
          className="bg-white p-0"
          style={{ overflowX: "scroll", overflowY: "hidden" }}
        >
          <LevelLinks levels={levels} />
        </Layout.Header>
        <Layout.Content>
          <LevelRoutes levels={levels} />
        </Layout.Content>
      </Layout>
    );
  }
  return "";
};

const Articles: React.FC = () => {
  const [articles, setArticles] = useState();
  const [loading, setLoading] = useState(true);
  const history = useHistory();
  const isMounted = useRef(true);

  useEffect(() => {
    if (loading) {
      getArticles().then((data) => {
        setArticles(data);
        setLoading(false);
        if (
          isMounted.current ||
          history.location.pathname == "/portal/articles"
        ) {
          const level = data[0].name;
          history.push(`/portal/articles/${level}/all`);
        }
        return data;
      });
    }
  }, [loading]);

  useEffect(() => {
    return () => {
      isMounted.current = false;
      source.cancel();
    };
  });
  return (
    <>{loading && isMounted.current ? <Loading /> : renderArticles(articles)}</>
  );
};

export default Articles;

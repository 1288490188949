import React from "react";
import { Collapse } from "antd";
import { useParams } from "react-router-dom";
import CourseListItem from "./CourseListItem";
import { Course } from "../../../../graphql";

interface Props {
  courses: any[];
}

const CourseList = ({ courses }: Props) => {
  const { courseId } = useParams();

  return (
    <Collapse accordion defaultActiveKey={courseId} style={{ width: "100%" }}>
      {courses.map((course) => (
        <Collapse.Panel header={course.name} key={course._id}>
          <CourseListItem course={course} />
        </Collapse.Panel>
      ))}
    </Collapse>
  );
};

export default CourseList;

import React from 'react'
import { useHistory } from 'react-router-dom';

const TermsAndConditions: React.FC = () => {
  const history = useHistory();
  const isCurrent = history.location.pathname==="/terms-conditions"
  return (
    <div style={{backgroundColor:"#f2f6f9",height:isCurrent?undefined:"60vh",overflowY:"scroll"}} className="pb-32 text-base terms-and-conditions">
      <div>
        <img style={{margin:"auto"}} src="https://thepiquelab.com/portal/wp-content/uploads/2018/09/privacy-01.png"/>
      </div>
      <div className="bg-white mx-32 pt-4 px-4 pb-4">
        <h1 className="text-center text-4xl">Terms & Conditions</h1>
        <div className="text-center py-4">
          <p className="py-4">The Pique Lab Learning Portal, a website at <u>https://digital.thepiquelab.com</u>, is a digital learning platform wholly owned and managed by <b>The Pique Lab Pte. Ltd</b> (“The Pique Lab”, “We”, “Our”), a private limited company incorporated in the Republic of Singapore (Unique Entity Number: 201700819R).</p>
          <p className="py-4">By becoming a <b>registered user</b> (“User”, “You”, “Yourself”, “Your”) of The Pique Lab Learning Portal, You agree to be bound by these Terms within this Agreement, which You acknowledge that You have read and understood.</p>
          <p className="py-4">We reserve the right, at Our sole discretion, to change, modify or otherwise alter these Terms at any time. You must review these Terms at <u>https://digital.thepiquelab.com/terms-conditions/</u> on a regular basis to keep Yourself informed of any changes.</p>
        </div>
        <div className="list-decimal-container">
          <ol className="list-decimal px-4 py-4">
            <li>
              <div>
                <div>
                  <b>Your Account</b>
                </div>
                <p className="py-4">You agree to provide accurate and complete information when you register with, and as you use, The Pique Lab Learning Portal, and you agree to update your account information to keep it accurate and complete.</p>
              </div>
            </li>
            <li>
              <div>
                <b>Access To Content</b>
              </div>
              <ol className="py-4">
                <li>
                  <p className="py-4 pr-4">2.1</p>
                  <p className="py-4">As an accommodation to you, you may download any learning material (in the form of “Notes”, “Videos” or “Articles”) from The Pique Lab Learning Portal onto your device. Some learning that you previously acquired may not be available for subsequent download or access at any given time, and The Pique Lab shall have no liability to you in such event. As you may not be able to subsequently download certain previously acquired content, once you download an item, it is your responsibility not to lose, destroy, or damage it, and you may want to back it up.</p>
                </li>
                <li>
                  <p className="py-4 pr-4">2.2</p>
                  <p className="py-4">The Pique Lab reserves the right to change content options (including eligibility for particular features) or remove access without notice to any previously purchased training or content that is no longer valid or deemed out of date.</p>
                </li>
              </ol>
            </li>
            <li>
              <div>
                <b>Intellectual Property</b>
              </div>
              <ol className="list-decimal-secondary py-4">
                <li>
                  <p className="py-4 pr-4">3.1</p>
                  <p className="py-4">Unless otherwise stated, all intellectual property rights in notes, worksheets, tests and course/lesson materials (whether in written, electronic, audio, visual or other form) created, assembled, published and/or distributed by The Pique Lab  (“Learning Materials”) are, and remain, the property of The Pique Lab or its licensors (“The Pique Lab’s IP”). Nothing in these Terms and Conditions grants You, including enrolled students, any ownership of or rights in respect of any of The Pique Lab’s IP.</p>
                </li>
                <li>
                  <p className="py-4 pr-4">3.2</p>
                  <p className="py-4">You agree NOT to infringe on The Pique Lab’s IP and/or Learning Materials and shall use them strictly for personal, non-commercial purposes. You are NOT authorised to copy, reproduce, modify, upload, share, disseminate, sell and/or commercially exploit any of the Learning Materials without The Pique Lab’s prior written permission.</p>
                </li>
                <li>
                  <p className="py-4 pr-4">3.3</p>
                  <p className="py-4">You agree NOT to modify, rent, lease, loan, sell, distribute, or create derivative works based on The Pique Lab’s Learning Materials in any manner, and you shall not exploit the The Pique Lab’s brand in any unauthorised way whatsoever, including, but not limited to, by trespass or burdening network capacity.</p>
                </li>
                <li>
                  <p className="py-4 pr-4">3.4</p>
                  <p className="py-4">Notwithstanding any other provision of this Agreement, The Pique Lab and its licensors reserve the right to change, suspend, remove, or disable access to any The Pique Lab’s products, content, or other materials comprising a part of the The Pique Lab’s brand at any time without notice. In no event will The Pique Lab be liable for making these changes. The Pique Lab may also impose limits on the use of or access to certain features or portions of The Pique Lab’s services, in any case and without notice or liability.</p>
                </li>
                <li>
                  <p className="py-4 pr-4">3.5</p>
                  <p className="py-4">Any form of infringement on The Pique Lab’s IP may subject You to civil and criminal penalties, including possible monetary damages for copyright infringement.</p>
                </li>
              </ol>
            </li>
            <li>
              <div>
                <b>Data Management</b>
              </div>
              <ol className="py-4">
                <li>
                  <p className="py-4 pr-4">4.1</p>
                  <p className="py-4">We use Auth0 (https://auth0.com/security) to secure the storage of your account’s access credentials on The Pique Lab Learning Portal. Auth0 has achieved a series of security compliance certificates, including ISO27001, ISO27018, SOC 2 Type II, HIPAA BAA, EU-US Privacy Shield Framework, Gold CSA Star, PCI DSS Certification and is wholly compliant with GDPR.</p>
                </li>
                <li>
                  <p className="py-4 pr-4">4.2</p>
                  <p className="py-4">By being a registered user of The Pique Lab Learning Portal, You understand and agree that this involves the transfer of your personal data outside of Singapore to Auth0’s servers located in Australia.</p>
                </li>
              </ol>
            </li>
            <li>
              <div>
                <b>Customer Support</b>
              </div>
              <p className="py-4">If you have any questions around the use of the account, please contact the Customer Care team at <b>hello@thepiquelab.com</b></p>
              <p className="py-4">With the exception of Public Holidays, We are contactable during the following business hours:</p>
              <div>
                <ul>
                  <li>Monday: 12PM to 7PM</li>
                  <li>Tuesday to Friday: 12PM to 9PM</li>
                  <li>Saturday & Sunday: 9AM to 7PM</li>
                </ul>
              </div>
            </li>
          </ol>
        </div>
      </div>
    </div>
    
  )
}

export default TermsAndConditions

import React from "react";

import styles from "./SubmissionBox.module.scss";

interface SubmissionBoxProps {
  boxTitle: string;
  children?: React.ReactNode;
}

const SubmissionBox: React.FC<SubmissionBoxProps> = (
  props: SubmissionBoxProps
) => {
  const { boxTitle, children } = props;
  return (
    <div className={`${styles.boxContainer} w-full md: w-1/2 `}>
      <div className={styles.boxTitleStyle}>{boxTitle}</div>
      {children}
    </div>
  );
};

export default SubmissionBox;

import React, { useEffect, useState } from "react";
import { Layout, Row, Col, Button, Menu, Skeleton, Modal } from "antd";
import { Route, Switch, NavLink } from "react-router-dom";
import {
  LinkOutlined,
  InstagramOutlined,
  FacebookFilled,
  YoutubeFilled,
  WhatsAppOutlined,
  EditOutlined,
  LogoutOutlined,
} from "@ant-design/icons";
import Articles from "./Articles";
import Videos from "./Videos";
import Programmes from "./Programmes";
import Notes from "./Notes";
import LogoColor from "../../assets/img/logoColor.svg";
import { useAuth0 } from "../../Auth0Wrapper";
import AccountSettings from "./Account/AccountSettings";
import MainPage from "./MainPage";
import { useQuery } from "@apollo/react-hooks";
import { GetChildrenQuery, GetChildren } from "../../graphql";
import edit from "../../assets/img/icons/edit.svg";
import signout from "../../assets/img/icons/signout.svg";
import lock from "../../assets/img/icons/lock.svg";
import axios from "../../AxiosClient";

const PortalPage: React.FC = () => {
  const { dbUser, logout } = useAuth0();
  const [lmsDisabled, setLmsDisabled] = useState(true);
  const [lmsPath, setLmsPath] = useState("/parent");
  const [avatarLoaded, setAvatarLoaded] = useState(false);
  const [authType, setAuthType] = useState<string>();
  const { data } = useQuery<GetChildrenQuery>(GetChildren);
  useEffect(() => {
    if (dbUser) {
      setAuthType(dbUser.sub.split("|")[0]);
      if (dbUser.role === "admin") {
        setLmsPath("/admin");
      } else if (dbUser.role === "teacher") {
        setLmsPath("/teacher");
      }
    }
    if (data) {
      if (data.children.length) {
        setLmsDisabled(false);
      }
    }
  }, [dbUser, data]);
  const headers = [
    {
      title: "ARTICLES",
      to: "/portal/articles/Primary%203/all",
    },
    {
      title: "NOTES",
      to: "/portal/notes",
    },
    {
      title: "VIDEOS",
      to: "/portal/videos",
    },
    {
      title: "PROGRAMMES",
      to: "/portal/programmes",
    },
    {
      title: "SHOP",
      to: "https://thepiquelab.com/shop",
    },
    {
      title: "LMS",
      to: lmsPath,
      disabled: lmsDisabled,
    },
  ];

  const changePassword = async (email) => {
    const res = await axios.post(`portal/change-password`, { email });
    let message = "";
    if (res.data) {
      message = "We've just sent you an email to reset your password.";
    } else {
      message = "An Error Occured. Please try again later.";
    }
    Modal.info({
      content: (
        <div>
          <p>{message}</p>
        </div>
      ),
      onOk() {},
    });
  };

  return (
    <Layout className="h-full">
      <Layout>
        <Layout.Header
          className="p-0 top-0 z-40 w-full shadow-none"
          style={{
            borderBottom: "1px solid #EBEBEB",
            height: "5.7rem",
            backgroundColor: "#FFF",
          }}
        >
          <Row className="flex h-full">
            <Col style={{ margin: "auto 0" }} className="w-full flex">
              <Menu
                mode="horizontal"
                className="border-none flex flex-1"
                overflowedIndicator={
                  <div style={{ height: "0" }}>
                    <img
                      src={`${window.location.origin}/Union.png`}
                      style={{ position: "relative", top: "26px" }}
                    />
                  </div>
                }
              >
                <Menu.Item
                  style={{ borderBottom: "none" }}
                  className="flex-shrink-0"
                >
                  <NavLink to="/portal">
                    <img
                      className="py-1 pl-0 sm:pl-2 md:pl-16"
                      src={LogoColor}
                      style={{ height: "fit-content" }}
                      alt="ThePiqueLab Learning Centre Logo"
                    />
                  </NavLink>
                </Menu.Item>
                {headers.map((header) => {
                  if (header.title === "SHOP") {
                    return (
                      <Menu.Item
                        className="nav-menu"
                        style={{ display: "flex" }}
                      >
                        <a
                          href={header.to}
                          className="semibold"
                          style={{ margin: "auto" }}
                          key={header.title}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          {header.title}{" "}
                          <LinkOutlined className="align-middle" />
                        </a>
                      </Menu.Item>
                    );
                  }
                  return (
                    <Menu.Item
                      key={header.title}
                      className={`nav-menu ${header.disabled && "hidden"} `}
                      style={{ display: "flex", color: "#717171" }}
                      disabled={header.disabled || false}
                    >
                      <NavLink
                        className="semibold"
                        to={header.to}
                        style={{ margin: "auto" }}
                        activeClassName="text-primary"
                      >
                        {header.title}
                      </NavLink>
                    </Menu.Item>
                  );
                })}
              </Menu>
              <Menu className="flex-shrink-0 flex w-32 sm:w-40">
                <Menu.SubMenu
                  popupOffset={[-80, -183]}
                  popupClassName="border-popup"
                  style={{
                    margin: "auto",
                  }}
                  className="header-avatar-container pr-0 sm:pr-8 avatar-shadow"
                  title={
                    <>
                      <Skeleton
                        avatar
                        paragraph={false}
                        title={false}
                        loading={!avatarLoaded}
                        className="header-avatar"
                        active
                      />
                      <img
                        src={dbUser.avatarUrl}
                        className={`h-12 w-12 overflow-hidden rounded-full border-2 border-solid border-white ${
                          !avatarLoaded ? "hidden" : ""
                        }`}
                        alt="avatar"
                        onLoad={() => {
                          setAvatarLoaded(true);
                        }}
                      />
                    </>
                  }
                >
                  <Menu.ItemGroup
                    className="pt-2"
                    title={
                      <div>
                        <p>Welcome!</p>
                        <p className="text-black text-base">{dbUser.name}</p>
                      </div>
                    }
                  ></Menu.ItemGroup>
                  <hr />
                  <Menu.Item className="nav-menu">
                    <NavLink
                      className="semibold"
                      to="/portal/account-settings"
                      activeClassName="text-primary"
                    >
                      <img src={edit} className="inline" alt="edit" />{" "}
                      <p className="inline">EDIT PROFILE</p>
                    </NavLink>
                  </Menu.Item>
                  {authType === "auth0" && (
                    <Menu.Item className="nav-menu">
                      <Button
                        type="link"
                        className="semibold p-0"
                        style={{ color: "inherit" }}
                        onClick={() => {
                          changePassword(dbUser.email);
                        }}
                      >
                        <img
                          src={lock}
                          className="inline pr-2 pb-2"
                          alt="sign out"
                        />{" "}
                        <p className="inline">CHANGE PASSWORD</p>
                      </Button>
                    </Menu.Item>
                  )}
                  <Menu.Item className="nav-menu">
                    <Button
                      type="link"
                      className="semibold p-0"
                      style={{ color: "inherit" }}
                      onClick={() => {
                        logout({ returnTo: window.location.origin });
                      }}
                    >
                      <img
                        src={signout}
                        className="inline pr-2 pb-2"
                        alt="sign out"
                      />{" "}
                      <p className="inline">SIGN OUT</p>
                    </Button>
                  </Menu.Item>
                </Menu.SubMenu>
              </Menu>
            </Col>
          </Row>
        </Layout.Header>

        <Layout.Content
          className="px-8 pt-4 md:px-16 lg:px-32"
          style={{ minHeight: "auto", maxWidth: "1440px" }}
        >
          <Switch>
            <Route path="/portal/articles">
              <Articles />
            </Route>
            <Route path="/portal/videos">
              <Videos />
            </Route>
            <Route path="/portal/programmes">
              <Programmes />
            </Route>
            <Route path="/portal/notes">
              <Notes />
            </Route>
            <Route path="/portal/account-settings">
              <AccountSettings />
            </Route>
            <Route path="/portal">
              <MainPage />
            </Route>
          </Switch>
        </Layout.Content>
        <Layout.Footer
          className="text-center"
          style={{ backgroundColor: "#F6F6F7" }}
        >
          <p>
            <a href="https://www.instagram.com/thepiquelab_" target="_blank">
              <InstagramOutlined className="bg-primary rounded-full p-1 text-white m-2" />
            </a>
            <a href="https://www.facebook.com/ThePiqueLab/" target="_blank">
              <FacebookFilled className="bg-primary rounded-full p-1 text-white m-2" />
            </a>
            <a
              href="https://www.youtube.com/channel/UCspNmXu3nN0vhqWmEm6Ej_g"
              target="_blank"
            >
              <YoutubeFilled className="bg-primary rounded-full p-1 text-white m-2" />
            </a>
            <a href="https://wa.me/6586211533" target="_blank">
              <WhatsAppOutlined className="bg-primary rounded-full p-1 text-white m-2" />
            </a>
          </p>
          <p>© THE PIQUE LAB LEARNING CENTRE | ALL RIGHTS RESERVED.</p>
        </Layout.Footer>
      </Layout>
    </Layout>
  );
};

export default PortalPage;

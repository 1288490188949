import React, { useState, useEffect } from "react";
import { Button, message, Modal, Space, Row, Checkbox } from "antd";
import moment from "moment";
import { useQuery } from "@apollo/react-hooks";
import axios from "../../../../AxiosClient";
import DownloadForm from "../../../DownloadForm";
import {
  Course,
  AcademicWeek,
  GetClassQuery,
  GetClassQueryVariables,
  GetClass,
} from "../../../../graphql";
import { Download } from "../../../DownloadForm/types";
import WeekSelect from "./WeekSelect";

const format = "DD/MM/YYYY";

interface Props {
  course: Course;
}

const CourseByWeek = ({ course }: Props) => {
  const { data, refetch } = useQuery<GetClassQuery, GetClassQueryVariables>(
    GetClass,
    {
      variables: { id: course.classes[0]._id },
    }
  );

  const [isShowInitModal, setIsShowInitModal] = useState(false);
  const [downloads, setDownloads] = useState<Download[]>([]);
  const [academicWeek, setAcademicWeek] = useState<AcademicWeek>();
  const [isInitiating, setIsInitiating] = useState(false);
  const [isTopicalEnabled, setIsTopicalEnabled] = useState(false);

  useEffect(() => {
    if (data) {
      const currentWeekData = data.class.lessons.find(
        (lesson) => lesson.weekNumber === academicWeek.weekNumber
      );
      console.log(currentWeekData, academicWeek);
      if (currentWeekData && currentWeekData.assignments.length > 0) {
        setIsTopicalEnabled(true);
      } else {
        setIsTopicalEnabled(false);
      }
    }
  }, [data, academicWeek]);

  const init = (courseId: string) => {
    setIsInitiating(true);
    axios
      .post(`/course/init`, {
        courseId,
        startDate: moment(academicWeek.startDate).format(format),
        endDate: moment(academicWeek.endDate).format(format),
        downloadIds: downloads.map((d) => d._id),
        format,
      })
      .then((res) => res.data)
      .then((text) => {
        if (text === true) {
          message.success("init success!");
          setIsShowInitModal(false);
        } else {
          message.error(text);
        }
      })
      .catch((error) => {
        message.error(`init failed ${error.message}`);
      })
      .finally(() => {
        setIsInitiating(false);
      });
  };

  const handleInit = (courseId: string, courseName: string) => {
    Modal.confirm({
      content: (
        <div>
          This change will affect
          <span style={{ fontWeight: 700, textDecoration: "underline" }}>
            all {courseName}’s classes and lessons in this week
          </span>
          , and override any classspecific changes. Are you sure?
        </div>
      ),
      onOk() {
        init(courseId);
      },
    });
  };

  const handleTopicalEnableChange = (val: boolean) => {
    setIsInitiating(true);

    console.log(data);
    console.log(val);

    axios
      .post(`/course/init`, {
        courseId: course._id,
        startDate: moment(academicWeek.startDate).format(format),
        endDate: moment(academicWeek.endDate).format(format),
        assignmentNames: val
          ? [`${course.name} Week ${academicWeek.weekNumber}`]
          : [],
        format,
      })
      .then((res) => res.data)
      .then((text) => {
        if (text === true) {
          if (val) {
            message.success("Test Submission enabled!");
          } else {
            message.success("Test Submission disabled!");
          }
          setIsTopicalEnabled(val);
        } else {
          message.error(text);
        }
      })
      .catch((error) => {
        message.error(`set Test Submission failed! ${error.message}`);
      })
      .finally(() => {
        setIsInitiating(false);
        refetch();
      });
  };

  return (
    <>
      <WeekSelect
        weeks={course.academicWeeks}
        onChange={(week) => setAcademicWeek(week)}
        currentWeekNumber={course.currentAcademicWeek}
      />
      <Space direction="vertical" style={{ width: "100%" }}>
        <Row justify="center">
          {/* TODO: Topical test submission is just a checkbox now */}
          <Checkbox
            checked={isTopicalEnabled}
            onChange={(e) => {
              handleTopicalEnableChange(e.target.checked);
            }}
          >
            Enable Topical Test Submission?
          </Checkbox>
        </Row>
        <Row justify="center">
          <Button onClick={() => setIsShowInitModal(true)}>
            Set downloads for all classes
          </Button>
        </Row>
      </Space>
      <Modal
        visible={isShowInitModal}
        onCancel={() => setIsShowInitModal(false)}
        destroyOnClose
        closable={false}
        maskClosable={false}
        okText="Override"
        onOk={() => handleInit(course._id, course.name)}
        okButtonProps={{ loading: isInitiating }}
      >
        <DownloadForm
          onChange={(val) => setDownloads(val)}
          courseId={course._id}
        />
      </Modal>
    </>
  );
};

export default CourseByWeek;

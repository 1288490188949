import React, { useState } from "react";
import { Card, Skeleton } from "antd";
import { useHistory, useLocation } from "react-router-dom";

interface Props {
  img: string;
  url?: string;
  localUrl?: string;
  children?: any;
  style?: any;
  className?: any;
}

const CustomCard = ({
  img,
  url,
  children,
  style,
  localUrl,
  className,
}: Props) => {
  const [loading, setLoading] = useState(true);
  const history = useHistory();
  const location = useLocation();
  const navigate = (to: string) => {
    window.open(to, "_blank");
  };

  const historyNavigate = (to: string) => {
    history.push(to);
  };
  return (
    <Card
      hoverable
      className={`min-w-xs mx-4 my-4 h-auto rounded-lg ${className}`}
      style={{...style,boxShadow:"0px 4px 18px rgba(0, 0, 0, 0.04)"}}
      cover={
        <>
          <Skeleton
            avatar={{ shape: "square" }}
            paragraph={false}
            title={false}
            className="card-skeleton"
            active
            loading={loading}
          />
          <img
            alt="example"
            src={img}
            className={`${loading && "hidden"}`}
            style={{borderTopRightRadius:".5rem",borderTopLeftRadius:".5rem"}}
            onLoad={() => {
              setLoading(false);
            }}
          />
        </>
      }
      onClick={() => {
        if (url !== undefined) {
          navigate(url);
        } else {
          historyNavigate(localUrl);
        }
      }}
    >
      {children}
    </Card>
  );
};

export default CustomCard;

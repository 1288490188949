import React, { useState, useEffect } from 'react'
import { Layout, Typography, Row } from 'antd'
import { Switch, Route } from 'react-router-dom'
import axios from '../../../AxiosClient'
import source from '../../../AxiosCancel'
import VideoPages from './VideoPages'
import VideoCards from './VideoCards'
import Loading from '../../common/Loading'

const getVideos = async (): Promise<any> => {
  const response = await axios.post('/portal/videos', {
    cancelToken: source.token,
  })
  return response.data
}

const Videos: React.FC = () => {
  const [examAnalysis, setExamAnalysis] = useState()
  const [videos, setVideos] = useState()
  const [allVideos, setAllVideos] = useState()
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    if (loading) {
      getVideos().then((data) => {
        setVideos(
          data.filter((video) => {
            return video.type === 'Videos'
          }),
        )
        setExamAnalysis(
          data.filter((video) => {
            return video.type === 'Exam Analysis Series'
          }),
        )
        setAllVideos(data)
        setLoading(false)
        return data
      })
    }
  }, [loading])
  useEffect(() => {
    return () => {
      source.cancel()
    }
  })
  if (loading) {
    return <Loading />
  }
  return (
    <Switch>
      <Route path="/portal/videos">
        <Route exact path="/portal/videos">
          <Layout className="h-full sm:px-5 md:px-10">
            <Layout.Content>
              <Typography.Title level={3} className="py-4 pt-2 semibold">
                Exam Analysis Series
              </Typography.Title>
              <VideoCards data={examAnalysis} />
              <Row className="py-10">
              <hr className="w-full"/>
              </Row>
              <VideoCards data={videos} />
            </Layout.Content>
          </Layout>
        </Route>
        <VideoPages pages={allVideos} />  
      </Route>
    </Switch>
  )
}

export default Videos

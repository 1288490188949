import React from "react";
import { Typography, Row, List } from "antd";
import { Link } from "react-router-dom";
import { RightCircleOutlined } from "@ant-design/icons";
import { Class } from "../../../../graphql";
// import ClassListActions from "./ClassListActions";

interface Props {
  classes: Class[];
  courseId: string;
  weekNumber: number;
}

const ClassList = ({ classes, courseId, weekNumber }: Props) => {
  return (
    <>
      <Row justify="space-between">
        <Typography.Title level={3}>Classes</Typography.Title>
        {/* <ClassListActions courseId={courseId} /> */}
      </Row>
      <Row>
        <List
          bordered
          style={{ width: "100%" }}
          dataSource={classes}
          renderItem={(item) => (
            <List.Item
              actions={[
                <Link
                  to={`/admin/course/${courseId}/class/${item._id}/${weekNumber}`}
                  key={item._id}
                >
                  <RightCircleOutlined key="iconRight" />
                </Link>,
              ]}
            >
              <span>{item.code}</span>
            </List.Item>
          )}
        />
      </Row>
    </>
  );
};

export default ClassList;

import React from "react";
import { Row, Col } from "antd";
import { Download } from "../../graphql";

import DownloadItem from "./DownloadItem";
import EmptyDataBox from "./EmptyDataBox";

// import

interface Props {
  downloads: Download[];
}

const Downloads: React.FC<Props> = (props: Props) => {
  const { downloads } = props;

  return (
    <div>
      {downloads.length > 0 ? (
        <Row gutter={10}>
          {downloads.map((download) => (
            <Col key={download._id}>
              <DownloadItem
                name={download.name}
                fileId={download.file.fileId}
              />
            </Col>
          ))}
        </Row>
      ) : (
        <EmptyDataBox
          boxTitle=''
          boxContext={["Come back soon!", "Class materials are not ready yet."]}
          boxPic='marked'
        />
      )}
    </div>
  );
};

export default Downloads;

import React from "react";
import { NavLink } from "react-router-dom";
import { Menu } from "antd";
import { Level, Category } from "./types";

interface Props {
  categories?: Category[];
  level?: Level;
}

const Categorylinks = ({ categories, level }: Props) => {
  if (categories) {
    return (
      <Menu
        mode="horizontal"
        className="borderless"
        style={{ width: "fit-content" }}
      >
        <Menu.Item className="menu-filled-sm align-middle h-12">
          <NavLink
            to={`/portal/articles/${level.name}/all`}
            className="px-4 text-base"
            activeClassName="bg-primary text-white rounded"
            style={{ padding: ".22rem 0rem" }}
            activeStyle={{ color: "white !important" }}
          >
            All Articles
          </NavLink>
        </Menu.Item>
        {categories.map((category) => {
          if (category.articles.length) {
            return (
              <Menu.Item className="menu-filled-sm align-middle h-12">
                <NavLink
                  key={category.name}
                  to={`/portal/articles/${level.name}/${category.name}`}
                  className="px-4 sm:mr-8 text-base"
                  activeClassName="bg-primary text-white rounded"
                  style={{ color: "#999999", padding: ".22rem 0rem" }}
                  activeStyle={{ color: "white !important" }}
                >
                  {category.name}
                </NavLink>
              </Menu.Item>
            );
          }

          return "";
        })}
      </Menu>
    );
  }
  return <></>;
};

export default Categorylinks;

import { message } from "antd";
import axiosClient from "../AxiosClient";

const download = (url: string) => {
  const i = document.createElement("iframe");
  i.src = url;
  i.hidden = true;
  document.getElementById("download").appendChild(i);
};

export const downloadOne = (fileId: string) => {
  axiosClient.get(`assignment/downloadlink/${fileId}`).then((res) => {
    message.success("Download Success.");
    // window.open(`${res.data}`, "_blank");
    download(res.data);
  });
};

export const downloadMany = (fileIds: string[]) => {
  fileIds.forEach((fileId) => {
    axiosClient.get(`assignment/downloadlink/${fileId}`).then((res) => {
      message.success("Download Success.");
      // window.open(`${res.data}`, "_blank");
      download(res.data);
    });
  });
};

import React from "react";
import styles from "./ZoomInfo.module.scss";

import Done from "../../../assets/lesson_ongoing/IllustDone.svg";

const Finished = () => {
  return (
    <div className={`${styles.wrapper} ${styles.finishedWrapper}`}>
      <img src={Done} alt='illust' className={styles.IllustBox} />
      <div>
        <div className={styles.comingTips}>
          This lesson is
          <br className='md:hidden' /> finished.
        </div>
        <div className={styles.remindTips}>
          Great Job! Don&apos;t forget to upload your assignment and check your
          marked tests!
        </div>
      </div>
    </div>
  );
};

export default Finished;

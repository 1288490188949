import React, { useState } from 'react'
import { NavLink } from 'react-router-dom'
import { Layout, Row, Typography, Card, Col, Skeleton, Button } from 'antd'
import { BulbOutlined, DownloadOutlined } from '@ant-design/icons'
import CustomCard from '../../common/CustomCard'
import MiniShowcase from './MiniShowcase'
import lightbulb from "../../../assets/img/icons/lightbulb.svg"
import download from "../../../assets/img/icons/Download.svg"

interface Props {
  items: any
  header: string
  to: string
}

const Showcase = ({ items, header, to }: Props) => {
  const [avatarLoaded, setAvatarLoaded] = useState(false)
  const primaryItem = items[0]
  const {
    ID,
    name,
    img,
    text,
    url,
    author,
    readtime,
    title,
    video,
    excerpt,
    thumbNail,
  } = primaryItem
  const portalPath = `https://thepiquelab.com/portal/wp-content/uploads/`

  const otherItems = items.filter((item, key) => {
    return key !== 0
  })
  const cover = video ? video.thumbnail : img || portalPath + thumbNail
  const cardTitle = name || title
  const cardDescription = video ? video.description : text || excerpt
  return (
    <Layout className="pb-16 pt-4">
      <Row>
        <Typography.Title level={4} className="semibold">
          <img src={lightbulb} className="inline align-middle" alt="lightbulb"/> {" "}
          <p className="inline text-2xl align-middle">{header}</p>
        </Typography.Title>
      </Row>
      <Row>
        <Col md={12} className="flex">
          <CustomCard
            url={ID ? JSON.parse(url)[0] : url}
            localUrl={video && "/portal/videos/"+video.title.replace(/(<|<\/)\s*[^>]*>/g,"")}
            img={cover}
            className="card-body-fill flex flex-col flex-1 showcase"
          >
            <div className="flex-1">
              <div className="mb-2">
                <b className="semibold text-2xl">{cardTitle}</b>
              </div>
              <div className="text-base">
                {cardDescription
                  .replace('<p>', '')
                  .replace('</p>', '')
                  .replace('<span>', '')
                  .replace('</span>', '')}
              </div>
            </div>
            {author && (
              <Card.Meta
                className="pt-4"
                title={author.name}
                avatar={
                  <>
                    <Skeleton
                      avatar
                      paragraph={false}
                      title={false}
                      loading={!avatarLoaded}
                      className="absolute"
                      active
                    />
                    <img
                      src={author.image}
                      className="h-8 w-8 overflow-hidden rounded-full inline"
                      alt="avatar"
                      onLoad={() => {
                        setAvatarLoaded(true)
                      }}
                    />
                  </>
                }
                description={readtime && <p>{readtime} min read</p>}
              />
            )}
            { !author && !video && (
              <Card.Meta
                className="pt-4"
                description={
                  <>
                    <img src={download} alt="download" className="inline"/>{' '}
                    <div className="inline align-middle text-primary text-lg">
                      Download File
                    </div>
                  </>
                }
              />
            )}
          </CustomCard>
        </Col>
        <Col md={12}>
          <MiniShowcase items={otherItems}/>
        </Col>
      </Row>
      <Row className="pt-12">
        <Button type="link" style={{ margin: 'auto'}}>
          <NavLink
            className="semibold m-auto text-center px-32 py-4 border-primary border-2 rounded text-primary mont-bold"
            to={to}
            style={{borderColor: "#F36B7F"}}
            onClick={()=>{
              window.scrollTo(0, 0);
            }}
          >
            SEE MORE
          </NavLink>
        </Button>
      </Row>
    </Layout>
  )
}

export default Showcase

import React from "react";
import { Layout } from "antd";
import { Route } from "react-router-dom";
import { VideoRecord } from "./types";

import MultipleVideos from "./MultipleVideos";
import Video from "./Video";

interface Props {
  pages: VideoRecord[];
}

const VideoPages = ({ pages }: Props) => {
  if (pages) {
    return (
      <>
        {pages.map((item) => {
          const isMany = item.videos !== undefined;
          return (
            <Route
              path={`/portal/videos/${item.title}`}
              key={item.title}
            >
              <Layout className="h-full px-8 sm:px-10 lg:px-20">
                <Layout.Content>
                  {isMany && <MultipleVideos page={item} />}
                  {!isMany && <Video page={item} />}
                </Layout.Content>
              </Layout>
            </Route>
          );
        })}
      </>
    );
  }
  return <></>;
};

export default VideoPages;

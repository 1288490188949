import React from "react";

import { Row, Col, Button, Typography, message, Card, Tooltip } from "antd";
import { DownloadOutlined } from "@ant-design/icons";
import axiosClient from "../../../AxiosClient";

import styles from "./DownloadItem.module.scss";
import fileImg from "../../../assets/lesson_ongoing/File.png";

interface DownloadItemProps {
  name: string;
  fileId: string;
}

const DownloadItem = (props: DownloadItemProps) => {
  const { name, fileId } = props;
  const handleDownload = () => {
    axiosClient.get(`assignment/downloadlink/${fileId}`).then((res) => {
      message.success("Download Success.");
      window.open(`${res.data}`, "_blank");
    });
  };
  return (
    <div className={styles.download__card}>
      <img src={fileImg} alt='' />
      <p className={styles.download__name}>{name}</p>
      <Button
        className={styles.downloads__button_color}
        onClick={() => handleDownload()}>
        <DownloadOutlined />
        Download
      </Button>
    </div>
  );
};

export default DownloadItem;

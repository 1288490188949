import React from "react";
import "./App.css";
import { ApolloProvider } from "@apollo/react-common";
import { BrowserRouter } from "react-router-dom";
import createApolloClient from "./ApolloClient";
import { useAuth0 } from "./Auth0Wrapper";
import PermissionWrapper from "./PermissionWrapper";

const App: React.FC = () => {
  const { token } = useAuth0();
  const apolloClient = createApolloClient(token);

  return (
    <div className="App">
      <ApolloProvider client={apolloClient}>
        <BrowserRouter>
          <PermissionWrapper />
        </BrowserRouter>
      </ApolloProvider>
    </div>
  );
};

export default App;

import React, { useState } from "react";
import {
  Typography,
  List,
  Tag,
  message,
  Modal,
  Card,
  Badge,
  Avatar,
} from "antd";
import {
  UserSwitchOutlined,
  CloudDownloadOutlined,
  CloudUploadOutlined,
  UserOutlined,
  UserDeleteOutlined,
  UserAddOutlined,
  MinusCircleOutlined,
} from "@ant-design/icons";
import { useMediaQuery } from "react-responsive";
import { useMutation } from "@apollo/react-hooks";
import {
  Transfer,
  GetALesson,
  StudentWithStateNoFromLessonFragment,
  AssignmentFieldFragment,
} from "../../../graphql";
import TransferStudent from "./TransferStudent";
import style from "./student.module.scss";
import { UploadList, DownloadSubmits } from "../Assignments";
import WithDraw from "./WithDraw";

interface Props {
  students: StudentWithStateNoFromLessonFragment[];
  assignments: AssignmentFieldFragment[];
  lessonId: string;
  showTransfer?: boolean;
}

const StudentList = (props: Props) => {
  const { students, lessonId, assignments, showTransfer } = props;
  const [visible, setVisible] = useState(false);
  const [transferStudentMutation] = useMutation(Transfer, {
    refetchQueries: [
      {
        query: GetALesson,
        variables: {
          id: lessonId,
        },
      },
    ],
  });
  const [studentId, setStudentId] = useState("");
  const [modalAction, setModalAction] = useState("");
  const [modalTitle, setModalTitle] = useState("Action");

  const studentTag = (state: string) => {
    switch (state) {
      case "Normal":
        return null;
      case "Skipped":
        return <Tag color="#f50">{state}</Tag>;
      case "Make-up":
        return <Tag color="#2db7f5">{state}</Tag>;
      case "Withdraw":
        return <Tag color="#87d068">{state}</Tag>;
      default:
        break;
    }
  };

  const showModal = () => {
    setVisible(true);
  };

  const handleCancel = () => {
    setVisible(false);
  };

  const handleTransfer = (Id: string) => {
    setModalAction("transfer");
    setModalTitle("Transfer or Withdraw");
    showModal();
    setStudentId(Id);
  };

  const getSubmitsCount = (studentId: string) => {
    let size = 0;
    assignments.forEach((a) => {
      size += a.submits.filter((s) => s.submitBy._id === studentId).length;
    });
    return size;
  };

  const onSaveTransfer = (toLessonId: string) => {
    transferStudentMutation({
      variables: {
        toLessonId,
        fromLessonId: lessonId,
        studentId,
      },
    }).then(({ data }) => {
      if (data.transferStudent) {
        message.success("Transfer student success");
      } else {
        message.error("Transfer failed");
      }

      setVisible(false);
    });
  };

  const handleDownload = (Id: string) => {
    setModalAction("download");
    setModalTitle("Download submits");
    setStudentId(Id);
    showModal();
  };

  const handleUpload = (Id: string) => {
    setModalAction("upload");
    setModalTitle("Upload Marked");
    setStudentId(Id);
    showModal();
  };

  const Desktop = ({ children }) => {
    const isDesktop = useMediaQuery({ minWidth: 1025 });
    return isDesktop ? children : null;
  };

  const Ipad = ({ children }) => {
    const isIpad = useMediaQuery({ minWidth: 768, maxWidth: 1024 });
    return isIpad ? children : null;
  };

  const StudentTagIcon = (state: string) => {
    switch (state) {
      case "Normal":
        return null;
      case "Skipped":
        return <UserDeleteOutlined />;
      case "Make-up":
        return <UserAddOutlined />;
      case "Withdraw":
        return <MinusCircleOutlined />;
      default:
        break;
    }
  };

  return (
    <div className={style.studentList__list_margin}>
      <Typography.Title level={4}>Students</Typography.Title>
      <List
        bordered
        dataSource={students}
        renderItem={(item) => (
          <List.Item>
            <List.Item.Meta
              className="flex flex-1 flex-row justify-center items-center"
              avatar={
                <Avatar
                  className="flex flex-1 items-center justify-center"
                  icon={<UserOutlined />}
                />
              }
              title={<div className="justify-center">{item.student.name}</div>}
              description={
                <>
                  <Desktop>{studentTag(item.state)}</Desktop>
                  <Ipad>{StudentTagIcon(item.state)}</Ipad>
                </>
              }
            />

            {assignments.length > 0 && (
              <>
                <Badge
                  count={getSubmitsCount(item.student._id)}
                  offset={[-20, 5]}
                >
                  <CloudDownloadOutlined
                    onClick={() => handleDownload(item.student._id)}
                    className={style.studentList__studentTransfer_size}
                  />
                </Badge>
                <CloudUploadOutlined
                  onClick={() => handleUpload(item.student._id)}
                  className={style.studentList__studentTransfer_size}
                />
              </>
            )}

            {showTransfer && (
              <UserSwitchOutlined
                onClick={() => handleTransfer(item.student._id)}
                className={style.studentList__studentTransfer_size}
              />
            )}
          </List.Item>
        )}
      />

      <Modal
        visible={visible}
        onCancel={handleCancel}
        footer={null}
        title={modalTitle}
      >
        {modalAction === "transfer" && (
          <Card>
            <TransferStudent
              studentId={studentId}
              onSave={(val) => onSaveTransfer(val)}
            />
            <WithDraw studentId={studentId} lessonId={lessonId} />
          </Card>
        )}
        {modalAction === "upload" && (
          <UploadList
            assignments={assignments}
            studentId={studentId}
            onSave={() => setVisible(false)}
          />
        )}
        {modalAction === "download" && (
          <DownloadSubmits
            assignments={assignments}
            studentId={studentId}
            onSave={() => setVisible(false)}
          />
        )}
      </Modal>
    </div>
  );
};

export default StudentList;

import React, { useState, useEffect } from "react";
import {
  Collapse,
  Row,
  Col,
  Modal,
  message,
  Input,
  Checkbox,
  Space,
} from "antd";
import moment from "moment";
import { useMutation } from "@apollo/react-hooks";
import {
  SetAssignment,
  QueryClasses,
  GetCourseOfTeacherLessonFieldsFragment,
} from "../../graphql";
import { StudentList } from "../admin/Student";

import DownloadUpload from "./DownloadUpload";

interface MCourse {
  _id: string;
  name: string;
  level: string;
  startDate: string;
  endDate: string;
  currentAcademicWeek: number;
}

interface Props {
  lessons: GetCourseOfTeacherLessonFieldsFragment[];
  course: MCourse;
}

const Teacher: React.FC<Props> = (props: Props) => {
  const { lessons, course } = props;
  const [activeKey, setActiveKey] = useState<string | string[]>("");
  const [studentNum, setStudentNum] = useState(0);
  const [attend, setAttend] = useState([]);
  const [makeUp, setMakeUpArr] = useState([]);
  const [skip, setSkipArr] = useState([]);
  const [_, setWithDrawArr] = useState([]);

  const [isShowInitModal, setIsShowInitModal] = useState(false);
  const [testName, setTestName] = useState<string>();
  const [isInitiating, setIsInitiating] = useState(false);
  const [isShowAllLesson, setIsShowAllLesson] = useState(true);

  const [setSubmission] = useMutation(SetAssignment, {
    refetchQueries: [{ query: QueryClasses }],
  });
  useEffect(() => {
    if (lessons) {
      lessons.forEach((lesson) => {
        const now = new Date().getTime();
        const momentObj = moment(now).toDate();
        const isNow = moment(momentObj).isBetween(
          lesson.startDateTime,
          lesson.endDateTime
        );
        if (isNow) {
          setActiveKey(lesson._id);
        }

        const makeUpArr = [];
        const skipArr = [];
        const withDrawArr = [];
        const normalArr = [];

        if (lesson.studentsWithState.length > 0) {
          setStudentNum(lesson.studentsWithState.length);
          lesson.studentsWithState.forEach((student) => {
            switch (student.state) {
              case "Make-up":
                makeUpArr.push(student);
                break;
              case "Skipped":
                skipArr.push(student);
                break;
              case "Withdraw":
                withDrawArr.push(student);
                break;
              case "Normal":
                normalArr.push(student);
                break;
              default:
                normalArr.push(student);
                break;
            }
          });

          setAttend([...normalArr]);
          setMakeUpArr([...makeUpArr]);
          setSkipArr([...skipArr]);
          setWithDrawArr([...withDrawArr]);
        }
      });
    }
  }, [lessons]);

  // const PanelExtra = (lesson: Lesson) => <span>{lesson.teacher.name}</span>;

  const Attending = () =>
    attend.length > 0 && <span>Attending: {studentNum}</span>;
  const Skipped = () =>
    skip.length > 0 && <span> | Skip: {skip.length} |</span>;
  const MakeUp = () =>
    makeUp.length > 0 && <span> Make-up: {makeUp.length}</span>;

  const PanelExtra = (lesson: GetCourseOfTeacherLessonFieldsFragment) => (
    <Row justify="space-between">
      <Col span={18}>{`Week ${lesson.weekNumber} (${moment(
        lesson.startDateTime
      ).format("Do MMM")})`}</Col>
      <Col span={6}>
        <Attending />
        <Skipped />
        <MakeUp />
      </Col>
    </Row>
  );

  const handleTestNameInput = (e: React.FormEvent<HTMLInputElement>) => {
    e.preventDefault();
    setTestName(e.currentTarget.value);
  };

  const handleOk = (lessonId: string) => {
    setIsInitiating(true);

    setSubmission({
      variables: {
        input: {
          name: testName,
          lesson: lessonId,
        },
      },
    })
      .then(() => {
        message.success("Set up success");
        setIsShowInitModal(false);
      })
      .finally(() => {
        setIsInitiating(false);
      });
  };

  const handleLessons = () => {
    if (isShowAllLesson) {
      const { currentAcademicWeek } = course;
      const recentLessons = lessons.filter(
        (l) =>
          l.weekNumber >= currentAcademicWeek - 2 &&
          l.weekNumber <= currentAcademicWeek + 2
      );
      return recentLessons;
    }
    return lessons;
  };

  return (
    <div>
      <Space>
        <Checkbox
          checked={isShowAllLesson}
          onChange={(e) => setIsShowAllLesson(e.target.checked)}
        >
          Show recent lessons only
        </Checkbox>
      </Space>
      <Collapse
        accordion
        activeKey={activeKey}
        onChange={(active) => setActiveKey(active)}
      >
        {handleLessons().map((lesson) => (
          <Collapse.Panel key={lesson._id} header={PanelExtra(lesson)}>
            <StudentList
              students={lesson.studentsWithState}
              assignments={lesson.assignments}
              lessonId={lesson._id}
            />
          </Collapse.Panel>
        ))}
      </Collapse>
    </div>
  );
};

export default Teacher;

import React, { useState, useEffect } from "react";
import { Select, Row, Col, Button, Spin, Typography } from "antd";
import moment from "moment";
import { useQuery } from "@apollo/react-hooks";
import style from "./student.module.scss";
import {
  GetCourseOfStudent,
  GetCourseOfStudentQuery,
  GetCourseOfStudentQueryVariables,
} from "../../../graphql";

import Loading from "../../common/Loading";

interface Props {
  onSave: (val: any) => void;
  studentId: string;
}

interface LessonOption {
  _id: string;
  weekNumber: number;
  startDateTime: string;
  endDateTime: string;
}

interface ClassOption {
  code: string;
  _id: string;
  lessons: LessonOption[];
}

const TransferStudent = (props: Props) => {
  const { onSave, studentId } = props;
  const { data, loading } = useQuery<
    GetCourseOfStudentQuery,
    GetCourseOfStudentQueryVariables
  >(GetCourseOfStudent, { variables: { studentId } });

  const [allClasses, setAllClasses] = useState<ClassOption[]>();
  const [nLessons, setNLessons] = useState<LessonOption[]>([]);
  const [selectClass, setSelectClass] = useState<ClassOption>();
  const [selectedLesson, setSelectedLesson] = useState();

  useEffect(() => {
    if (data) {
      const courses = data.coursesOfStudent;
      const classes: ClassOption[] = courses.flatMap((course) => {
        return course.classes;
      });
      console.log(classes);

      setAllClasses(() => classes);
      if (classes.length > 0) {
        setSelectClass(() => classes[0]);
        const { lessons } = classes[0];
        setNLessons(() => lessons);
      }
    }
  }, [data]);

  const handleClassChange = (val) => {
    const res = allClasses.find((mClass) => mClass._id === val);
    setNLessons([...res.lessons]);
  };

  const handleLessonChange = (val) => {
    setSelectedLesson(val);
  };
  const handleSave = (): void => {
    onSave(selectedLesson);
  };

  if (loading || !allClasses || !nLessons) return <Loading />;

  return (
    <>
      <Typography.Title level={4}>Transfer Student</Typography.Title>
      <Select onChange={handleClassChange} value={selectClass._id}>
        {allClasses.map((mClass) => (
          <Select.Option key={mClass._id} value={mClass._id}>
            {mClass.code}
          </Select.Option>
        ))}
      </Select>
      <Select
        value={selectedLesson}
        onChange={handleLessonChange}
        style={{ minWidth: "152px" }}
      >
        {nLessons.map((lesson) => (
          <Select.Option key={lesson._id} value={lesson._id}>
            {`Week ${lesson.weekNumber} (${moment(lesson.startDateTime).format(
              "Do MMM"
            )})`}
          </Select.Option>
        ))}
      </Select>
      <Row justify="end" className={style.transfer__button_marginTop}>
        <Col span={4}>
          <Button onClick={handleSave}>Transfer</Button>
        </Col>
      </Row>
    </>
  );
};

export default TransferStudent;

import React from "react";
import { Button } from "antd";
import { Submit } from "../../../graphql";

import SubmissionBox from "./SubmissionBox";
import Download from "../DownloadItem";

import { downloadOne, downloadMany } from "../../../utils/downloadUtil";

import styles from "./SubmissionBox.module.scss";
import markedPic from "../../../assets/lesson_ongoing/markSub.svg";

interface MarkedSubmissionProps {
  MARKED_DATA: Submit;
}

const MarkedSubmission = (props: MarkedSubmissionProps) => {
  const { MARKED_DATA } = props;
  const handleDownloadAll = () => {
    const fileIdArr = [];
    MARKED_DATA.markedFiles.map((markedSubItem) => {
      fileIdArr.push(markedSubItem.fileId);
    });
    downloadMany(fileIdArr);
  };
  return (
    <SubmissionBox boxTitle="Marked Submissions">
      {MARKED_DATA &&
      MARKED_DATA.markedFiles &&
      MARKED_DATA.markedFiles.length > 0 ? (
        <div className={styles.relativeBox}>
          <div className="mb-16 w-full flex flex-wrap items-start justify-evenly">
            {MARKED_DATA.markedFiles.map((markedSubItem) => {
              return (
                <Download
                  key={markedSubItem.fileId}
                  name={markedSubItem.name}
                  fileId={markedSubItem.fileId}
                />
              );
            })}
          </div>
          <Button
            type="primary"
            className={styles.UploadBtn}
            onClick={() => handleDownloadAll()}
          >
            Download all
          </Button>
        </div>
      ) : (
        <div className={styles.markedBox}>
          <img
            src={markedPic}
            alt="marked submissions"
            className={styles.markedPicBox}
          />
          <div className="mt-6">
            <div className={styles.markedTips}>Come back soon!</div>
            <div className={styles.markedTips}>
              Your results are not quite ready yet.
            </div>
          </div>
        </div>
      )}
    </SubmissionBox>
  );
};

export default MarkedSubmission;

import React, { useState, useEffect } from "react";
import { LeftCircleOutlined, RightCircleOutlined } from "@ant-design/icons";
import { Row } from "antd";
import moment from "moment";
import { AcademicWeek } from "../../../../graphql";

interface WeekSelectProps {
  onChange?(value: AcademicWeek): void;
  weeks: AcademicWeek[];
  currentWeekNumber?: number;
}

const WeekSelect: React.FC<WeekSelectProps> = (props: WeekSelectProps) => {
  const { onChange, weeks, currentWeekNumber } = props;
  const [current, setCurrent] = useState<number>(0);
  useEffect(() => {
    if (currentWeekNumber !== undefined) {
      setCurrent(currentWeekNumber - 1);
    }
  }, [currentWeekNumber]);

  const next = () => {
    if (current === weeks.length - 1) {
      setCurrent(0);
    } else {
      setCurrent((prevState) => prevState + 1);
    }
  };

  const prev = () => {
    if (current === 0) {
      setCurrent(weeks.length - 1);
    } else {
      setCurrent((prevState) => prevState - 1);
    }
  };

  useEffect(() => {
    if (onChange) {
      onChange(weeks[current]);
    }
  }, [current]);

  return (
    <div
      className="flex flex-row justify-around items-center"
      style={{ height: "5rem" }}
    >
      <LeftCircleOutlined onClick={() => prev()} />
      <div
        className={`font-semibold flex flex-row items-center ${
          current + 1 === currentWeekNumber ? "text-gray-700" : "text-gray-600"
        }`}
      >
        <div className="pr-2">Week {weeks[current].weekNumber}</div>
        <div>
          (
          {`${moment(weeks[current].startDate).format("DD/MM/YYYY")} - ${moment(
            weeks[current].endDate
          ).format("DD/MM/YYYY")}`}
          )
        </div>
      </div>
      <RightCircleOutlined onClick={() => next()} />
    </div>
  );
};

export default WeekSelect;

import React from "react";
import { useQuery } from "@apollo/react-hooks";
import { GetAnnoucement, GetAnnoucementQuery } from "../../graphql";

import style from "./Announcement.module.scss";

const Announcement = () => {
  const { data } = useQuery<GetAnnoucementQuery>(GetAnnoucement);
  return (
    <div className="w-full bg-secondary rounded-b-lg text-white font-semibold shadow overflow-hidden">
      {data && data.annnouncement && (
        <div className={`px-4 py-4 ${style.scroll_text}`}>
          {data.annnouncement.content}
        </div>
      )}
    </div>
  );
};

export default Announcement;

import React from 'react'
import { Layout, Row, Col, Checkbox } from 'antd'
import Sider from "./Sider"

interface Props {
  children?: React.ReactNode;
}

interface Level {
  label:string;
  value:string;
}

interface LevelGroup {
  name:string;
  levels:Level[]
}

const levels:LevelGroup[] = [
  {
    name:"PRIMARY",
    levels:[
      {
        label:"Primary 1 (2020)",
        value:"P1 2020"
      },
      {
        label:"Primary 2 (2020)",
        value:"P2 2020"
      },
      {
        label:"Primary 3 (2020)",
        value:"P3 2020"
      },
      {
        label:"Primary 4 (2020)",
        value:"P4 2020"
      },
      {
        label:"Primary 5 (2020)",
        value:"P5 2020"
      },
      {
        label:"Primary 6 (2020)",
        value:"P6 2020"
      },
    ]
  },
  {
    name:"SECONDARY",
    levels:[
      {
        label:"Secondary 1 (2020)",
        value:"S1 2020"
      },
      {
        label:"Secondary 2 (2020)",
        value:"S2 2020"
      },
      {
        label:"Secondary 3 (2020)",
        value:"S3 2020"
      },
      {
        label:"Secondary 4 (2020)",
        value:"S4 2020"
      },
    ]
  }
]

const Level: React.FC<Level> = ({label,value}:Level) =>{ 
  return(<Checkbox className="ml-0 py-2 text-base" value={value}>{label}</Checkbox>)
}

const LevelGroup: React.FC<LevelGroup> = (level:LevelGroup) => {
  const {name, levels}= level;
  const firstHalf = levels.length>1?levels.slice(0,levels.length/2):levels;
  const secondHalf = levels.length>1?levels.slice(levels.length/2,levels.length):[];
  return (<>
    <p className="text-base pb-4 pt-3">{name} LEVEL</p>
    <Row>
      <Col xs={12} className="flex flex-col">
        {firstHalf.map(Level)}
      </Col>
      <Col xs={12} className="flex flex-col">
        {secondHalf.map(Level)}
      </Col>
    </Row>
  </>)
}

const LevelOptions: React.FC<Props> = ({children}:Props) => {
  
  return (<>{levels.map(LevelGroup)}</>)
}

export default LevelOptions

import React from "react";
import { Layout } from "antd";
import { MenuBar } from "../components/common/MenuBar";
import { Courses } from "../components/admin/Courses";
import Annoucement from "../components/admin/Annnouncement";

const AdminPage = () => {
  return (
    <div style={{ height: "100vh" }}>
      <Layout style={{ height: "100%" }}>
        <Layout.Header>
          <MenuBar />
        </Layout.Header>
        <Layout.Content className="w-full mx-auto md:w-10/12">
          <Annoucement />
          <Courses />
        </Layout.Content>
      </Layout>
    </div>
  );
};

export default AdminPage;

import React, { useState, useEffect } from "react";
import { Row, Col, Badge } from "antd";
import moment from "moment";
import {
  UserOutlined,
  UserDeleteOutlined,
  UserAddOutlined,
} from "@ant-design/icons";
import { useMediaQuery } from "react-responsive";
import { GetCourseClassLessonFieldsFragment } from "../../../graphql";

interface Props {
  lesson: GetCourseClassLessonFieldsFragment;
}
const LessonPanelHeader = ({ lesson }: Props) => {
  const [studentNum, setStudentNum] = useState(0);
  const [attend, setAttend] = useState([]);
  const [makeUp, setMakeUpArr] = useState([]);
  const [skip, setSkipArr] = useState([]);
  const [withdrawn, setWithdrawn] = useState([]);
  const isDesktop = useMediaQuery({ minWidth: 1024 });

  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/no-use-before-define
    const { n, a, m, s, w } = calculateAttendance(lesson);
    setStudentNum(n);
    setAttend(a);
    setMakeUpArr(m);
    setSkipArr(s);
    setWithdrawn(w);
  }, [lesson]);

  const Attending = () =>
    attend.length > 0 && <span>Attending: {studentNum}</span>;
  const Skipped = () =>
    skip.length > 0 && <span> | Skip: {skip.length} |</span>;
  const MakeUp = () =>
    makeUp.length > 0 && <span> Make-up: {makeUp.length}</span>;

  const DesktopPanelExtra = (l: GetCourseClassLessonFieldsFragment) => (
    <Row justify="space-between">
      <Col span={18}>{`Week ${l.weekNumber} (${moment(l.startDateTime).format(
        "Do MMM"
      )})`}</Col>
      <Col span={6}>
        <Attending />
        <Skipped />
        <MakeUp />
      </Col>
    </Row>
  );

  const IpadPanelExtra = (l: GetCourseClassLessonFieldsFragment) => (
    <Row justify="space-between">
      <Col span={18}>{`Week ${l.weekNumber} (${moment(l.startDateTime).format(
        "Do MMM"
      )})`}</Col>
      <Col>
        {attend.length > 0 && (
          <Badge count={studentNum}>
            <UserOutlined />
          </Badge>
        )}

        {skip.length > 0 && (
          <Badge count={skip.length}>
            <UserDeleteOutlined />
          </Badge>
        )}
        {makeUp.length > 0 && (
          <Badge count={makeUp.length}>
            <UserAddOutlined />
          </Badge>
        )}
      </Col>
    </Row>
  );

  return <>{isDesktop ? DesktopPanelExtra(lesson) : IpadPanelExtra(lesson)}</>;
};

export default LessonPanelHeader;

const calculateAttendance = (lesson: GetCourseClassLessonFieldsFragment) => {
  const makeUpArr = [];
  const skipArr = [];
  const withDrawArr = [];
  const normalArr = [];

  lesson.studentsWithState.map((student) => {
    switch (student.state) {
      case "Make-up":
        makeUpArr.push(student);
        break;
      case "Skipped":
        skipArr.push(student);
        break;
      case "Withdraw":
        withDrawArr.push(student);
        break;
      case "Normal":
        normalArr.push(student);
        break;
      default:
        normalArr.push(student);
        break;
    }
  });

  return {
    n: lesson.studentsWithState.length,
    a: [...normalArr],
    m: [...makeUpArr],
    s: [...skipArr],
    w: [...withDrawArr],
  };
};

import { ErrorHandler } from "apollo-link-error";
import ApolloClient, { InMemoryCache } from "apollo-boost";

const errorHandler: ErrorHandler = ({
  operation,
  graphQLErrors,
  networkError,
}) => {
  if (graphQLErrors) {
    graphQLErrors.forEach(({ message }) => {
      console.error(`${operation}: ${message}`);
    });
  }
  if (networkError) {
    console.error(`${networkError}`);
  }
};

let client = null;

const createApolloClient = (token: string): ApolloClient<any> => {
  if (client) {
    return client;
  }
  client = new ApolloClient({
    uri: process.env.REACT_APP_GRAPHQL_ENDPOINT,
    cache: new InMemoryCache({ resultCaching: true }),
    onError: errorHandler,
    request: (operation) => {
      operation.setContext((context: Record<string, any>) => ({
        headers: {
          ...context.headers,
          Authorization: `Bearer ${token}`,
        },
      }));
    },
  });
  return client;
};

export default createApolloClient;

import React from "react";
import { Row, Typography } from "antd";
import { useQuery } from "@apollo/react-hooks";
import { Switch, Route } from "react-router-dom";
import {
  GetCourses,
  GetCoursesQuery,
  GetCourseOfStudentQueryVariables,
} from "../../../graphql";
// import CoursesAction from "./CoursesActions";
import Loading from "../../common/Loading";

import CourseList from "./courseList/CourseList";
import { Classes } from "../Classes";

const Courses = () => {
  const { loading, data } = useQuery<
    GetCoursesQuery,
    GetCourseOfStudentQueryVariables
  >(GetCourses);

  if (loading) return <Loading />;

  return (
    <Switch>
      <Route exact path={["/admin", "/admin/course/:courseId"]}>
        <Row justify="space-between" align="middle" style={{ height: "5rem" }}>
          <Typography.Title level={3}>Courses</Typography.Title>
          {/* <CoursesAction /> */}
        </Row>
        <Row>
          <CourseList courses={data.courses} />
        </Row>
      </Route>
      <Route exact path="/admin/course/:courseId/class/:classId/:weekNumber">
        <Classes />
      </Route>
    </Switch>
  );
};

export default Courses;

import React from "react";
import { Spin } from "antd";

interface LoadingProps {
  text?: string;
}

const Loading = (props: LoadingProps) => {
  const { text } = props;
  return (
    <Spin size="large" tip={text || "Loading"} className="text-primary">
      <div style={{ height: "100vh" }} />
    </Spin>
  );
};

export default Loading;

import React, { Component } from "react";
import { message, Upload, Button } from "antd";
import { UploadOutlined } from "@ant-design/icons";
import axiosClient from "../../AxiosClient";

interface Props {
  teacher?: {
    submitId: string;
  };
  student?: {
    id: string;
    assignmentId: string;
  };
  onSave(): void;
  permission: "student" | "teacher";
}
interface State {
  fileList: any[];
  uploading: boolean;
}

class CommonUpload extends Component<Props, State> {
  constructor(props) {
    super(props);
    this.state = {
      fileList: [],
      uploading: false,
    };
  }

  handleRemove = (file) => {
    this.setState((state) => {
      const index = state.fileList.indexOf(file);
      const newFileList = state.fileList.slice();
      newFileList.splice(index, 1);
      return {
        fileList: newFileList,
      };
    });
  };

  handleBeforeUpload = (file) => {
    this.setState((state) => ({
      fileList: [...state.fileList, file],
    }));
    return false;
  };

  postData = (path: string, params: string, studentId?: string) => {
    const { onSave } = this.props;
    const { fileList } = this.state;

    const formData = new FormData();
    fileList.forEach((file) => {
      formData.append("files[]", file);
    });

    this.setState({
      uploading: true,
    });

    if (studentId) {
      axiosClient
        .post(`/assignment/${path}/${params}/${studentId}`, formData)
        .then(() => {
          this.setState({
            fileList: [],
            uploading: false,
          });

          // message.success("upload success.");
          onSave();
        })
        .catch((err) => {
          message.error(err);
        });
    } else {
      axiosClient
        .post(`/assignment/${path}/${params}`, formData)
        .then(() => {
          this.setState({
            fileList: [],
            uploading: false,
          });

          // message.success("upload success.");
          onSave();
        })
        .catch((err) => {
          message.error(err);
        });
    }
  };

  handleUpload = () => {
    const { permission, student, teacher } = this.props;

    if (permission === "student" && student.assignmentId) {
      this.postData("submit", student.assignmentId, student.id);
    } else if (permission === "teacher" && teacher.submitId) {
      this.postData("mark", teacher.submitId);
    }
  };

  render() {
    const { uploading, fileList } = this.state;
    return (
      <div className="relative">
        <Upload
          multiple
          accept="image/*,.pdf"
          onRemove={this.handleRemove}
          beforeUpload={this.handleBeforeUpload}
          fileList={fileList}
        >
          <Button
            type="primary"
            className="flex flex-1 flex-row items-center justify-center"
          >
            <UploadOutlined />
            Select Files
          </Button>
        </Upload>
        <Button
          type="primary"
          onClick={this.handleUpload}
          disabled={fileList.length === 0}
          loading={uploading}
          className="absolute top-0 right-0"
        >
          {uploading ? "Uploading" : "Start Upload"}
        </Button>
      </div>
    );
  }
}

export default CommonUpload;

import React, { useState, useEffect } from "react";
import { Button, List } from "antd";
import Modal from "antd/lib/modal/Modal";
import CommonUpload from "../../common/CommonUpload";
import { AssignmentFieldFragment } from "../../../graphql";

interface Props {
  assignments: AssignmentFieldFragment[];
  studentId: string;
  onSave(): void;
}

const UploadList = (props: Props) => {
  const { assignments, studentId, onSave } = props;
  const [submitId, setSubmitId] = useState("");
  const [visible, setVisible] = useState(false);

  const [studentSubmit, setStudentSubmit] = useState<AssignmentFieldFragment[]>(
    []
  );

  useEffect(() => {
    const mAssignments = Array.from(assignments, (assignment) => {
      const mSubmits = assignment.submits.find(
        (submit) => submit.submitBy._id === studentId
      );
      const result = {
        _id: assignment._id,
        name: assignment.name,
        submits: [{ ...mSubmits }],
      };
      return result;
    });
    if (mAssignments.length > 0) {
      setStudentSubmit(mAssignments);
    }
  }, [assignments, studentId]);

  const showModal = () => {
    setVisible(true);
  };

  const handleCancel = () => {
    setVisible(false);
  };

  return (
    <>
      <List
        bordered
        itemLayout="horizontal"
        dataSource={studentSubmit}
        renderItem={(assignment) =>
          assignment.submits.map(
            (submit): JSX.Element => {
              let ele = <span />;
              if (submit._id) {
                ele = (
                  <List.Item
                    key={submit._id}
                    actions={[
                      <Button
                        key="upload"
                        type="primary"
                        onClick={() => {
                          setSubmitId(submit._id);
                          showModal();
                        }}
                      >
                        {submit.markedFiles.length > 0 ? "Override" : "Upload"}
                      </Button>,
                    ]}
                  >
                    <h3>{assignment.name}</h3>
                  </List.Item>
                );
              }

              return ele;
            }
          )
        }
      />
      <Modal
        title="Upload"
        visible={visible}
        onCancel={handleCancel}
        footer={null}
      >
        <CommonUpload
          permission="teacher"
          teacher={{ submitId }}
          onSave={() => {
            setVisible(false);
            onSave();
          }}
        />
      </Modal>
    </>
  );
};

export default UploadList;

import React from "react";
import { useParams } from "react-router-dom";
import { Button } from "antd";

import moment from "moment";
import { Assignments } from ".";
import Downloads from "./Downloads";
import Announcement from "../announcement";
import { Upcoming, Finished } from "./ZoomInfo";

import { downloadMany } from "../../utils/downloadUtil";

import styles from "./Student.module.scss";

interface StudentContentProps {
  lesson: any;
}

const StudentContent = (props: StudentContentProps) => {
  const { lesson } = props;
  const { studentId } = useParams();

  const handleDownloadAll = () => {
    const fileIdArr = [];
    lesson.downloads.forEach((download) => {
      fileIdArr.push(download.file.fileId);
    });
    downloadMany(fileIdArr);
  };

  return (
    <div className={styles.studentContent}>
      <Announcement />
      {moment(lesson.endDateTime).isBefore(moment()) ? (
        <Finished />
      ) : (
        <Upcoming />
      )}
      <div className={`${styles.headers_mg} flex flex-1 flex-row items-center`}>
        <div>Class Materials</div>
        {lesson.downloads.length > 0 ? (
          <Button
            type="primary"
            className="px-6 rounded bg-secondary ml-8 text-base font-bold"
            onClick={() => handleDownloadAll()}
          >
            Download all
          </Button>
        ) : null}
      </div>
      <Downloads downloads={lesson.downloads} />
      <div className="w-full border-b border-gray-200 my-8" />
      {lesson.assignments.length > 0 ? (
        <div>
          <div className={styles.headers_mg}>Assignments</div>
          <Assignments
            assignments={lesson.assignments}
            studentId={studentId}
            lessonStart={lesson.startDateTime}
          />
        </div>
      ) : null}
    </div>
  );
};

export default StudentContent;

import React, { Component, ChangeEvent } from "react";
import { Upload, Button, message, Row, Col, Input } from "antd";
import { UploadOutlined } from "@ant-design/icons";
import axiosClient from "../../../AxiosClient";

interface Props {
  lessonId: string;
  onSave(): void;
}
interface State {
  fileList: any[];
  uploading: boolean;
  fileName: string;
}

class UploadAnswer extends Component<Props, State> {
  constructor(props) {
    super(props);
    this.state = {
      fileList: [],
      uploading: false,
      fileName: "",
    };
  }

  handleRemove = (file) => {
    this.setState((state) => {
      const index = state.fileList.indexOf(file);
      const newFileList = state.fileList.slice();
      newFileList.splice(index, 1);
      return {
        fileList: newFileList,
      };
    });
  };

  handleBeforeUpload = (file) => {
    this.setState((state) => ({
      fileList: [...state.fileList, file],
    }));
    return false;
  };

  handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();
    const res = e.currentTarget.value;
    this.setState({
      fileName: res,
    });
  };

  postData = () => {
    const { onSave, lessonId } = this.props;
    const { fileList, fileName } = this.state;
    const formData = new FormData();
    fileList.forEach((file) => {
      formData.append("files[]", file);
    });

    this.setState({
      uploading: true,
    });

    if (fileName) {
      axiosClient
        .post(`/assignment/answer/${lessonId}/${fileName}`, formData)
        .then((res) => {
          this.setState({
            fileList: [],
            uploading: false,
          });

          message.success("upload success.");
          onSave();
        })
        .catch((err) => {
          message.error(err);
        });
    } else {
      message.error("Please Input File Name");
      this.setState({
        fileList: [],
        uploading: false,
      });
    }
  };

  render() {
    const { uploading, fileList } = this.state;
    return (
      <div>
        <Input
          id="answerName"
          onChange={this.handleChange}
          style={{ marginBottom: 20 }}
        />
        <Row justify="end">
          <Col>
            <Upload
              multiple
              accept="image/*,.pdf"
              onRemove={this.handleRemove}
              beforeUpload={this.handleBeforeUpload}
              fileList={fileList}
            >
              <Button type="primary">
                <UploadOutlined />
                Select Files
              </Button>
            </Upload>
          </Col>

          <Col>
            <Button
              type="primary"
              onClick={this.postData}
              disabled={fileList.length === 0}
              loading={uploading}
            >
              {uploading ? "Uploading" : "Start Upload"}
            </Button>
          </Col>
        </Row>
      </div>
    );
  }
}

export default UploadAnswer;

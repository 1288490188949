import React, { useState, useEffect, useContext } from "react";
import jwtDecode from "jwt-decode";
import createAuth0Client from "@auth0/auth0-spa-js";
// eslint-disable-next-line import/no-unresolved
import Auth0Client from "@auth0/auth0-spa-js/dist/typings/Auth0Client";
import httpClient from "./AxiosClient";
import Loading from "./components/common/Loading";

export interface ContextValue {
  isAuthenticated?: boolean;
  user?: any;
  dbUser?: any;
  token: string;
  loading?: boolean;
  popupOpen?: boolean;
  updateToken?: any;
  loginWithPopup(
    options?: PopupLoginOptions,
    config?: PopupConfigOptions
  ): Promise<void>;
  getIdTokenClaims(options?: getIdTokenClaimsOptions): Promise<IdToken>;
  loginWithRedirect(options?: RedirectLoginOptions): Promise<void>;
  getTokenSilently(options?: GetTokenSilentlyOptions): Promise<any>;
  auth0Client: Auth0Client;
  getTokenWithPopup(
    options?: GetTokenWithPopupOptions,
    config?: PopupConfigOptions
  ): Promise<string>;
  logout(options?: LogoutOptions): void;
}

interface Auth0ProviderProps {
  onRedirectCallback?: (appState: any) => void;
  children: React.ReactNode;
  clientOptions: Auth0ClientOptions;
}

const DEFAULT_REDIRECT_CALLBACK = (): void =>
  window.history.replaceState({}, document.title, window.location.pathname);

export const Auth0Context = React.createContext<ContextValue>(
  {} as ContextValue
);
export const useAuth0 = (): ContextValue => useContext(Auth0Context);
export const Auth0Provider = ({
  children,
  onRedirectCallback = DEFAULT_REDIRECT_CALLBACK,
  clientOptions
}: Auth0ProviderProps): JSX.Element => {
  const [isAuthenticated, setIsAuthenticated] = useState<boolean>();
  const [user, setUser] = useState<any>();
  const [dbUser, setDbUser] = useState<any>();
  const [auth0Client, setAuth0] = useState<Auth0Client>();
  const [loading, setLoading] = useState(true);
  const [popupOpen, setPopupOpen] = useState(false);
  const [token, setToken] = useState("");

  useEffect(() => {
    const initAuth0 = async (): Promise<void> => {
      const auth0FromHook = await createAuth0Client(clientOptions);
      setAuth0(auth0FromHook);

      if (
        window.location.search.includes("code=") &&
        window.location.search.includes("state=")
      ) {
        try {
          const { appState } = await auth0FromHook.handleRedirectCallback();
          onRedirectCallback(appState);
        } catch (error) {
          console.error(error);
        }
      }

      const isAuthed = await auth0FromHook.isAuthenticated();
      setIsAuthenticated(isAuthed);

      if (isAuthed) {
        setUser(await auth0FromHook.getUser());

        const accessToken = await auth0FromHook.getTokenSilently();
        setToken(accessToken);

        httpClient.defaults.headers = {
          Authorization: `Bearer ${accessToken}`
        };
        const userClaims: any = await auth0FromHook.getIdTokenClaims();

        httpClient
          .post("/user/userinfo", {
            sub: userClaims.sub,
            name: userClaims.name,
            email: userClaims.email,
            avatarUrl: userClaims.picture
          })
          .then((res) => res.data)
          .then((data) => {
            setDbUser(data);
            setLoading(false);
          });
      } else {
        if (window.location.pathname !== "/") {
          const allowed = [
            "/terms-conditions",
            "/privacy-policy",
            "/signup",
            "/"
          ];
          if (!allowed.includes(window.location.pathname)) {
            window.location.replace("/");
          }
        }
        setLoading(false);
      }
    };
    initAuth0();
  }, [clientOptions, onRedirectCallback]);

  if (loading || auth0Client === undefined) {
    return <Loading />;
  }

  const updateToken = async (token, id_token) => {
    setToken(token);
    httpClient.defaults.headers = {
      Authorization: `Bearer ${token}`
    };
    const userClaims: any = jwtDecode(id_token);
    setUser(userClaims);
    await httpClient
      .post("/user/userinfo", {
        sub: userClaims.sub,
        name: userClaims.name,
        email: userClaims.email,
        avatarUrl: userClaims.picture
      })
      .then((res) => res.data)
      .then((data) => {
        setDbUser(data);
      });
  };

  const loginWithPopup = async (
    options?: PopupLoginOptions,
    config?: PopupConfigOptions
  ): Promise<void> => {
    setPopupOpen(true);
    try {
      await auth0Client.loginWithPopup(options, config);
    } catch (error) {
    } finally {
      setPopupOpen(false);
    }

    setUser(await auth0Client.getUser());
    setIsAuthenticated(true);
  };

  const {
    getIdTokenClaims,
    loginWithRedirect,
    getTokenSilently,
    getTokenWithPopup
  } = auth0Client;

  return (
    <Auth0Context.Provider
      value={{
        isAuthenticated,
        user,
        dbUser,
        token,
        loading,
        popupOpen,
        loginWithPopup,
        getIdTokenClaims,
        loginWithRedirect,
        getTokenSilently,
        getTokenWithPopup,
        updateToken,
        auth0Client,
        logout: (options?: LogoutOptions) => {
          setLoading(true);
          auth0Client.logout(options);
        }
      }}
    >
      {children}
    </Auth0Context.Provider>
  );
};

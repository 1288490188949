import React from "react";
import { Col, Card } from "antd";
import { DownloadOutlined } from "@ant-design/icons";
import { Note } from "./types";
import CustomCard from "../../common/CustomCard";
import download from "../../../assets/img/icons/Download.svg";

interface Props {
  note?: Note;
}

const NoteCard = ({ note }: Props) => {
  if (note) {
    const { ID, title, excerpt, thumbNail, url } = note;
    const path = `https://thepiquelab.com/portal/wp-content/uploads/`;
    return (
      <Col xs={24} md={12} xl={8} className="flex">
        <CustomCard
          url={JSON.parse(url)[0]}
          img={path + thumbNail}
          key={ID}
          className="card-body-fill flex flex-col flex-1"
        >
          <div className="flex-1">
            <div className="mb-2 h-18">
              <b className="semibold text-lg">{title}</b>
            </div>
            <div className="text-base">
              {excerpt.replace("<p>", "").replace("</p>", "").substr(0, 100) +
                "..."}
            </div>
          </div>
          <Card.Meta
            className="pt-4"
            description={
              <>
                <div className="inline align-middle text-primary">
                  <img src={download} alt="download" className="inline" />{" "}
                  <p className="mont-bold inline text-base"> DOWNLOAD FILE</p>
                </div>
              </>
            }
          />
        </CustomCard>
      </Col>
    );
  }
  return <></>;
};

export default NoteCard;

import React from "react";
import Logo from "../../../assets/img/logoWhite.svg";
import Avatar from "./Avatar";
import { StudentFieldFragment } from "../../../graphql";
import { useAuth0 } from "../../../Auth0Wrapper";

interface AccountSelectProps {
  students: StudentFieldFragment[];
  onSelect(studentId: string): void;
}

const AccountSelect = (props: AccountSelectProps) => {
  const { onSelect, students } = props;
  const { logout } = useAuth0();

  const handleSelect = (_id: string) => {
    if (onSelect) onSelect(_id);
  };

  return (
    <div style={{ backgroundColor: "#F46C80", height: "100vh" }}>
      <div
        style={{ width: "105px" }}
        className="mx-auto pt-8 pb-24 md:ml-48 md:pt-20"
      >
        <img className="object-fill w-full h-auto" src={Logo} alt="logo" />
      </div>
      <div className="w-full md:w-1/2 md:mx-auto font-bold text-2xl text-white text-center pb-12">
        Who&apos;s using?
      </div>
      <div className="flex flex-row justify-around mx-auto w-full md:w-1/2">
        {students.map((child) => (
          <Avatar
            height={150}
            width={150}
            name={child.name}
            gender={child.gender}
            key={child._id}
            showName
            onClick={() => handleSelect(child._id)}
          />
        ))}
      </div>
      <div className="text-xl w-full text-center fixed bottom-0">
        <button
          type="button"
          className="text-white font-bold py-2 px-4 border rounded-lg mb-4"
          onClick={() => logout({ returnTo: window.location.origin })}
        >
          Log Out
        </button>
      </div>
    </div>
  );
};

export default AccountSelect;

import React, { useState, useEffect } from "react";
import moment from "moment";

const Clock = () => {
  const [date, setDate] = useState(new Date());

  useEffect(() => {
    function tick() {
      setDate(new Date());
    }
    const timer = setInterval(tick, 1000);
    return function clearTimer() {
      clearInterval(timer);
    };
  });

  return (
    <div className="flex flex-col justify-start items-center font-semibold">
      <div style={{ fontSize: "25px", lineHeight: "31px" }}>
        {moment(date).format("LT")}
      </div>
      <div style={{ fontSize: "10px", lineHeight: "12px" }}>
        {moment(date).format("dddd, Do MMMM")}
      </div>
    </div>
  );
};
export default Clock;

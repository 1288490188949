import React from "react";
import { VideoChild } from "./types";
import { NavLink } from "react-router-dom";
import playCircle from "./playCircle.png";

interface Props {
  data?: VideoChild[];
  handleSetVideo?: any;
  mainTitle: string;
  addToLength?: number;
}

const Video = ({ data, handleSetVideo, mainTitle, addToLength = 0 }: Props) => {
  return (
    <>
      {data.map((video, index) => {
        return (
          <NavLink
            type="button"
            key={video.title}
            to={`/portal/videos/${mainTitle.replace(/[{()}]/g, "")}/${
              video.title
            }`}
            onClick={() => {
              window.scrollTo(0, 0);
            }}
            className="my-4 mx-3 inline-block"
          >
            <div>
              <div
                style={{
                  height: "0px",
                  position: "relative",
                  top: "27px",
                  left: "55px",
                  overflow: "visible",
                }}
              >
                <img src={playCircle} alt="playButton" style={{}} />
              </div>
              <img
                src={video.thumbnail}
                className="h-24 rounded"
                alt={video.title}
              />
            </div>
            <div className="text-base">Question {addToLength + index + 1}</div>
          </NavLink>
        );
      })}
    </>
  );
};

export default Video;

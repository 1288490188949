import React, { useState } from "react";
import { Row, Col, Card, Skeleton } from "antd";
import { Article } from "./types";
import CustomCard from "../../common/CustomCard";

interface Props {
  articles?: Article[];
}

const ArticleCards = ({ articles }: Props) => {
  const [avatarLoaded, setAvatarLoaded] = useState(false);

  if (articles) {
    return (
      <Row>
        {articles.map((article) => {
          const { name, text, url, img, author, readtime } = article;
          return (
            <Col xs={24} md={12} lg={12} xl={8} key={name} className="flex">
              <CustomCard
                url={url}
                img={img}
                className="card-body-fill flex flex-col"
              >
                <div className="flex-1">
                  <div className="mb-2 h-11">
                    <b className="semibold text-base">{name}</b>
                  </div>
                  <div className="pt-4">{text.replace("<p>", "").replace("</p>", "")}</div>
                </div>
                {author && (
                  <Card.Meta
                    className="pt-4 flex-0 author"
                    title={author.name}
                    style={{color:"rgba(0, 0, 0, 0.6)"}}
                    avatar={
                      <>
                        <Skeleton
                          avatar
                          paragraph={false}
                          title={false}
                          loading={!avatarLoaded}
                          className="absolute"
                          active
                        />
                        <img
                          src={author.image}
                          className="h-8 w-8 overflow-hidden rounded-full inline"
                          alt="avatar"
                          onLoad={() => {
                            setAvatarLoaded(true);
                          }}
                        />
                      </>
                    }
                    description={readtime && <p style={{color:"#B5B5B5"}}>{readtime} min read</p>}
                  />
                )}
              </CustomCard>
            </Col>
          );
        })}
      </Row>
    );
  }
  return <></>;
};

export default ArticleCards;
